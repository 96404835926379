import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Progress } from 'reactstrap'
import prettyBytes from 'pretty-bytes'
import moment from 'moment'
import React, { useState } from 'react'
import axios from 'axios'
import pubsub from 'pubsub-js'
import { saveAs } from 'file-saver'
import { getAwsDocuments, getAwsImage } from '../../../utils/awsPaths'
var mimetype2fa = require('mimetype-to-fontawesome')({ prefix: 'fa-' })

function File({ file, setFiles, setLoader }) {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const downloadFile = (file) => {
    try {
      saveAs(getAwsImage(file.file_path), file.filename)
    } catch (e) {
      console.log('error', e)
      pubsub.publish('showNotification', {
        message: 'Gabim gjatë shkarkimit të file',
        type: 'error'
      })
    }
  }

  const deleteFile = (fileId) => {
    setLoader(true)
    axios
      .delete(getAwsDocuments(fileId))
      .then((data) => {
        setFiles((prevState) => prevState.filter((file) => file._id !== fileId))
        pubsub.publish('showNotification', {
          message: 'File u fshi me sukses.',
          type: 'success'
        })
      })
      .catch((err) => {
        console.log('error', err)
        pubsub.publish('showNotification', {
          message: 'Ka ndohurdhur nje gabim',
          type: 'error'
        })
      })
      .finally(() => setLoader(false))
  }
  return (
    <Col lg='4'>
      <div className='card' data-filter-group='audio'>
        <div className='card-body text-center'>
          {file._id && (
            <div className='d-flex'>
              <div className='ml-auto'>
                <Dropdown
                  isOpen={dropdownOpen}
                  toggle={() => {
                    setDropdownOpen(!dropdownOpen)
                  }}
                >
                  <DropdownToggle className='btn-xs' color='link'>
                    <em className='fa fa-ellipsis-v fa-lg text-muted'></em>
                  </DropdownToggle>
                  <DropdownMenu className='dropdown-menu-right-forced animated fadeInLeft'>
                    <DropdownItem className='cursor-pointer' onClick={() => deleteFile(file._id)}>
                      <span>Fshij Fallin</span>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          )}

          <a
            onClick={(e) => {
              e.preventDefault()
              if (file.uploading) {
                return
              } else {
                downloadFile(file)
              }
            }}
            href='#'
            className='px-4'
          >
            <em className={`fa-3x far ${mimetype2fa(file.mime_type)} text-info`}></em>
          </a>
          <p>
            <small className='text-dark'>{file.filename}</small>
          </p>
          {file.uploading ? (
            <Progress animated color='success' value={file.upload_progress * 100}>
              {(file.upload_progress * 100).toFixed(0)}%
            </Progress>
          ) : (
            <div className='d-flex m-0 text-muted'>
              <small>{prettyBytes(parseInt(file.file_size))}</small>
              <small className='ml-auto'>{moment(file.created_date).fromNow()}</small>
            </div>
          )}
        </div>
      </div>
    </Col>
  )
}

export default File
