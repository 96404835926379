import { CircularProgress, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import ContentWrapper from '../../../../components/Layout/ContentWrapper'
import Mozo_CSV from '../components/csv/downloadCSV'
import MobileZoneForm from '../components/form/form'
import Filters from '../components/filters/filters'
import MozoOrder_Table from '../components/table/table'
import { useState } from 'react'
import { fetchMyMozoOrders } from '../API/endpoints'

const My_Mozo_Orders = () => {
  const [queryParams, setQueryParams] = useState({ page: 0, limit: 10, search: '' })

  const myOrdersEndpoint = `mobilezoneOrders/getMyOrder?page=${queryParams.page}&limit=${queryParams.limit}&search=${queryParams.search}`

  const myMozoOrders = useQuery({
    queryKey: ['myMozoOrder', queryParams.page, queryParams.limit, queryParams.search],
    queryFn: () => fetchMyMozoOrders(myOrdersEndpoint),
    staleTime: 50000
  })
  return (
    <ContentWrapper>
      <div className='content-heading'>
        <p>My Mobilezone Orders</p>
      </div>
      <Mozo_CSV title='Add Order' getMine={'yes'} />
      <MobileZoneForm />
      <Filters queryParams={queryParams} updateQueryParam={setQueryParams} departments={[]} filterDepartemnt={false} />
      {myMozoOrders.isLoading && <CircularProgress />}
      {myMozoOrders.error && <Typography color='error'>Error loading data</Typography>}
      {myMozoOrders.data && (
        <MozoOrder_Table
          data={myMozoOrders.data.data}
          total={myMozoOrders.data.total}
          setQueryParams={setQueryParams}
          queryParams={queryParams}
        />
      )}
    </ContentWrapper>
  )
}

export default My_Mozo_Orders
