import axios from 'axios'
import moment from 'moment'
import MUIDataTable from 'mui-datatables'
import React from 'react'
import { Line as LineChart } from 'react-chartjs-2'
import 'react-datetime/css/react-datetime.css'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody, Col, Input, Row, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import Swal from 'sweetalert2'
import ContentWrapper from '../Layout/ContentWrapper'
import { albanianMonths } from '../ManageWages/enums/AlbanianMonths'
import MyWorkingHours from '../../modules/DailyWorkingHours/components/My_Working_Hours/My_Working_Hours'
import DateTimeBoostrap from '../../components/Forms/DateTimeBootstrap'

class Wages extends React.Component {
  _isMounted = false

  constructor() {
    super()
    this.state = {
      data: [],
      filteredDate: [],
      selectedDate: new Date(),
      base_salary: [],
      bruto_salary: [],
      salary_net: [],
      incentive: [],
      compensation: [],
      tax: [],
      bonus: [],
      trust_employer: [],
      trust_employee: [],
      error_message: '',
      isUserID: false,
      userName: '',
      userLastName: '',
      innerWidth: window.innerWidth,
      activeTab: 'myWages'
    }

    this._dateFunctionFiltered = this._dateFunctionFiltered.bind(this)
    this._onDateChange = this._onDateChange.bind(this)
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  updateWindowDimensions() {
    this.setState({ innerWidth: window.innerWidth })
  }

  async _dateFunctionFiltered() {
    const userId = this.props.match.params.userId
    try {
      const res = await axios.get(`salaries/${userId ? userId : 'me'}/`, {
        params: {
          year: moment(this.state.selectedDate).format('YYYY')
        }
      })
      if (res.data.success) {
        if (res.data.data.length > 0) {
          var base_salary = Array(12).fill(0)
          var salary_net = Array(12).fill(0)
          var bruto_salary = Array(12).fill(0)
          var incentive = Array(12).fill(0)
          var compensation = Array(12).fill(0)
          var tax = Array(12).fill(0)
          var bonus = Array(12).fill(0)
          var trust_employee = Array(12).fill(0)
          var trust_employer = Array(12).fill(0)

          res.data.data.forEach((elem) => {
            base_salary[elem.month - 1] = elem.base_salary
            salary_net[elem.month - 1] = elem.salary_net
            bruto_salary[elem.month - 1] = elem.bruto_salary
            incentive[elem.month - 1] = elem.incentive
            compensation[elem.month - 1] = elem.compensation
            tax[elem.month - 1] = elem.tax
            bonus[elem.month - 1] = elem.bonus
            trust_employee[elem.month - 1] = elem.trust_employee
            trust_employer[elem.month - 1] = elem.trust_employer
          })
          if (this._isMounted) {
            this.setState({
              data: res.data.data,
              base_salary: base_salary,
              salary_net: salary_net,
              bruto_salary: bruto_salary,
              incentive: incentive,
              compensation: compensation,
              tax: tax,
              bonus: bonus,
              trust_employee: trust_employee,
              trust_employer: trust_employer
            })
          }
        } else {
          if (this._isMounted) {
            this.setState({
              data: res.data.data,
              base_salary: [],
              salary_net: [],
              bruto_salary: [],
              incentive: [],
              compensation: [],
              tax: [],
              bonus: [],
              trust_employee: [],
              trust_employer: []
            })
          }
        }
      }
    } catch (e) {
      if (this._isMounted) {
        this.setState({ error_message: 'Error fetching data from the server' })
      }
    }
  }
  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  }

  async componentDidMount() {
    this._isMounted = true
    const userId = this.props.match.params.userId
    if (userId && this._isMounted) {
      await this.setState({ isUserID: true })
    }
    await this._dateFunctionFiltered()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    this._isMounted = false
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  async _onDateChange(date) {
    if (typeof date == 'object') {
      await this.setState({ selectedDate: date })
      this._dateFunctionFiltered()
    }
  }

  _onDateBlur = (date) => {
    this.setState({ selectedDate: moment(date, 'YYYY') })
  }

  _exportSalary = (salaryId, month, year) => {
    const monthFormat = String(month).padStart(2, '0')
    if (new Date(`${year}-${monthFormat}-01`) < new Date('2023-04-01')) {
      return Swal.fire({
        title: 'Kjo pagë për momentin nuk mund të shkarkohet!'
      })
    }
    Swal.fire({
      title: 'Ju lutem prisni...',
      html: 'Duke gjeneruar dokumentin',
      didOpen: () => {
        Swal.showLoading()
      }
    })
    axios
      .post(`salaries/${salaryId}`, null, { responseType: 'blob' })
      .then((response) => {
        if (response.headers['content-type'] == 'application/pdf') {
          saveAs(response.data, `Paga_${albanianMonths[month - 1]}_${year}.pdf`)
          Swal.close()
          Swal.fire({
            title: 'Te dhenat u shkarkuan me sukses.',
            html: 'Dokumenti eshte gjeneruar me sukses'
          })
        } else {
          Swal.fire({
            title: 'Gabim gjatë shkarkimit të dokumentit',
            html: 'Gabim gjatë shkarkimit të dokumentit'
          })
        }
      })
      .catch((error) => {
        console.log('error', error)
        Swal.fire({
          title: 'Gabim gjatë shkarkimit të dokumentit',
          html: 'Gabim gjatë shkarkimit të dokumentit'
        })
      })
  }

  render() {
    const userName = this.props.location.state !== undefined && this.props.location.state.userName ? this.props.location.state.userName : ''
    const lastName = this.props.location.state !== undefined && this.props.location.state.lastName ? this.props.location.state.lastName : ''
    const columns = ['Muaji', 'Viti', 'Paga Bruto', 'Paga Neto', 'Trusti Punëdhënesi', 'Trusti Punëtori', 'Taksat', 'Shkarko']
    const options = {
      filter: false,
      sort: false,
      download: false,
      responsive: 'scroll',
      filterType: 'dropdown',
      selectableRows: 'none',
      search: false,
      viewColumns: true,
      textLabels: {
        body: {
          noMatch: 'Nuk ka të dhëna'
        }
      },
      elevation: 0
    }

    const data = this.state.data.map((elem) => {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
      })

      return [
        albanianMonths[elem.month - 1],
        elem.year,
        formatter.format(elem.bruto_salary),
        formatter.format(elem.salary_net),
        formatter.format(elem.trust_employer),
        formatter.format(elem.trust_employee),
        formatter.format(elem.tax),
        <Button
          outline
          className='mb-1'
          color='warning'
          onClick={() => {
            this._exportSalary(elem._id, elem.month, elem.year)
          }}
          type='button'
        >
          <span>Shkarko</span>
        </Button>
      ]
    })

    const Line = {
      data: {
        labels: ['Janar', 'Shkurt', 'Mars', 'Prill', 'Maj', 'Qershor', 'Korrik', 'Gusht', 'Shtator', 'Tetor', 'Nëntor', 'Dhjetor'],
        datasets: [
          {
            label: 'Paga Bazë',
            backgroundColor: 'rgba(14,52,64,0.2)',
            borderColor: 'rgba(114,102,186,1)',
            pointBorderColor: '#fff',
            data: this.state.base_salary
          },
          {
            label: 'Paga Bruto',
            backgroundColor: 'rgba(114,102,186,0.2)',
            borderColor: 'rgba(35,183,229,1)',
            pointBorderColor: '#fff',
            data: this.state.bruto_salary
          },
          {
            label: 'Paga Neto',
            backgroundColor: 'rgba(255,127,80 ,0.2)',
            borderColor: 'rgba(255,127,80 ,1 )',
            pointBorderColor: '#fff',
            data: this.state.salary_net
          },
          {
            label: 'Incentiva',
            backgroundColor: 'rgba(46,139,87 ,0.2)',
            borderColor: 'rgba(46,139,87 ,1 )',
            pointBorderColor: '#fff',
            data: this.state.incentive
          },
          {
            label: 'Kompenzimi',
            backgroundColor: 'rgba(80,50,50 ,0.2)',
            borderColor: 'rgba(80,50,50,1 )',
            pointBorderColor: '#fff',
            data: this.state.compensation
          },
          {
            label: 'Totali tax',
            backgroundColor: 'rgba(255,215,0,0.2)',
            borderColor: 'rgba(255,215,0,1)',
            pointBorderColor: '#fff',
            data: this.state.tax
          },
          {
            label: 'Totali trust punëdhënësi',
            backgroundColor: 'rgba(46,139,87,0.2)',
            borderColor: 'rgba(46,139,87,1)',
            pointBorderColor: '#fff',
            data: this.state.trust_employee
          },
          {
            label: 'Totali trust punëtori',
            backgroundColor: 'rgba(13, 204, 97,0.2)',
            borderColor: 'rgba(13, 204, 97,1)',
            pointBorderColor: '#fff',
            data: this.state.trust_employer
          },
          {
            label: 'Totali bonus',
            backgroundColor: 'rgba(255, 137, 15,0.2)',
            borderColor: 'rgba(255, 137, 15,1)',
            pointBorderColor: '#fff',
            data: this.state.bonus
          }
        ]
      },
      options: {
        legend: {
          display: true
        }
      }
    }

    return (
      <ContentWrapper id='wages-container'>
        {this.state.isUserID ? (
          <div className='content-heading d-flex justify-content-between'>
            <p>
              <span>Paga:</span>
              <span>
                <Link to={`/editUsers/${this.props.match.params.userId}`}>{' ' + userName + ' ' + lastName}</Link>
              </span>
              <span />
            </p>
            <div>
              <Button onClick={() => this.props.history.goBack()} color='info' className='btn-labeled'>
                <span className='btn-label'>
                  <i className='fa fa-arrow-left' />
                </span>
                Prapa
              </Button>
            </div>
          </div>
        ) : (
          <div className='d-flex justify-content-between'>
            <p style={{ fontSize: 23, color: 'gray' }}>Pagat e mia</p>
            <div>
              <Button onClick={() => this.props.history.goBack()} color='info' className='btn-labeled'>
                <span className='btn-label'>
                  <i className='fa fa-arrow-left' />
                </span>
                Prapa
              </Button>
            </div>
          </div>
        )}
        <Row>
          <Col lg={12} style={{ overflow: 'scroll' }}>
            {/* START panel tab */}
            <div className='card card-transparent'>
              <Nav tabs justified>
                <NavItem>
                  <NavLink
                    className={this.state.activeTab === 'myWages' ? 'active' : ''}
                    onClick={() => {
                      this.toggleTab('myWages')
                    }}
                  >
                    Pagat e muajit
                  </NavLink>
                </NavItem>
                {/* {level == 1 && ( */}
                <>
                  {/* <NavItem> */}
                  {/* <NavLink
                      className={this.state.activeTab === 'import' ? 'active' : ''}
                      onClick={() => {
                        this.toggleTab('import')
                      }}
                    >
                      Importo paga
                    </NavLink> */}
                  {/* </NavItem> */}
                  <NavItem>
                    <NavLink
                      className={this.state.activeTab === 'dailyHours' ? 'active' : ''}
                      onClick={() => {
                        this.toggleTab('dailyHours')
                      }}
                    >
                      Orët e muajit
                    </NavLink>
                  </NavItem>
                </>
                {/* )} */}
              </Nav>
              <TabContent activeTab={this.state.activeTab} className='bg-white p-0'>
                <TabPane tabId='myWages'>
                  {/* {this.props.userStore.userLevel == 1 && ( */}
                  {/* <Row style={{ padding: 20 }}>
                    <Col>
                      <Row>
                        <Col>
                          <div className='form-group row mb float-left pl-3'>
                            <label className='col-form-label mb'>Zgjedh muajin:</label>
                            <Col md={5}>
                              <DateTimeBoostrap
                                key='selectDate'
                                value={this.state.selectedDate}
                                onChange={this._onDateChange}
                                locale='de'
                                utc={true}
                                closeOnSelect
                                dateFormat='MM-YYYY'
                                initialViewMode='years'
                                onBlur={this._onDateBlur}
                                data-validate='["validDate"]'
                                timeFormat={false}
                                inputProps={{
                                  'data-validate': '["validDate"]',
                                  className: 'form-control',
                                  name: 'data',
                                  autoComplete: 'off'
                                }}
                              />
                            </Col>
                          </div>
                        </Col>
                      </Row>
                      <div style={{ overflow: 'auto' }}>
                        <Card
                          body
                          style={{
                            width: this.state.innerWidth > 1024 ? this.state.innerWidth - 400 : 800
                          }}
                        >
                          <LineChart data={Line.data} options={Line.options} width={800} height={250} />
                        </Card>
                      </div>
                    </Col>
                  </Row>
                  {/* )} */}
                  {/* <Row className={this.state.isLoading ? 'whirl standard' : null} style={{ padding: 20 }}>
                    <Col>
                      <Card className={`shadow-none bg-white manageWagesTable`}>
                        <CardBody>
                          <div className='input-group'>
                            <input
                              name='searchBox'
                              value={this.state.searchText}
                              onChange={this._getDataForTable}
                              className='form-control'
                              type='text'
                              placeholder='Kerko perdoruesit...'
                            />
                            <span className='input-group-btn'>
                              <button disabled className='btn btn-secondary' type='button'>
                                <em className='fa fa-search' />
                              </button>
                            </span>
                          </div>
                        </CardBody>
                        <MUIDataTable
                          key={Math.random()}
                          ref={(ref) => (this.tableRef = ref)}
                          title={'Pagat'}
                          columns={columns}
                          options={options}
                          data={data}
                        />
                      </Card>
                    </Col>
                  </Row> */}
                  <Row>
                    <Col>
                      <div style={{ overflow: 'auto' }}>
                        <Card
                          body
                          style={{
                            width: this.state.innerWidth > 1024 ? this.state.innerWidth - 280 : 800
                          }}
                        >
                          <LineChart data={Line.data} options={Line.options} width={800} height={250} />
                        </Card>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Card className='shadow bg-white'>
                        <Col className='pl-3 pt-3' lg={12}>
                          <div className='form-group row mb float-left pl-3'>
                            <label className='col-form-label mb'>Zgjedh vitin:</label>
                            <Col md={5}>
                              <DateTimeBoostrap
                                closeOnSelect
                                key='selectDate'
                                value={this.state.selectedDate}
                                onChange={this._onDateChange}
                                locale='de'
                                utc={true}
                                dateFormat='YYYY'
                                onBlur={this._onDateBlur}
                                data-validate='["validDate"]'
                                timeFormat={false}
                                inputProps={{
                                  'data-validate': '["validDate"]',
                                  className: 'form-control',
                                  name: 'data',
                                  autoComplete: 'off'
                                }}
                              />
                            </Col>
                          </div>
                        </Col>
                        <MUIDataTable columns={columns} options={options} data={data} />
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId='dailyHours'>
                  <MyWorkingHours userId={this.props.match.params.userId} />
                  {/* <Tab_Import_Hours /> */}
                </TabPane>
              </TabContent>
            </div>
            {/* END panel tab */}
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <div style={{ overflow: 'auto' }}>
              <Card
                body
                style={{
                  width: this.state.innerWidth > 1024 ? this.state.innerWidth - 280 : 800
                }}
              >
                <LineChart data={Line.data} options={Line.options} width={800} height={250} />
              </Card>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card className='shadow bg-white'>
              <Col className='pl-3 pt-3' lg={12}>
                <div className='form-group row mb float-left pl-3'>
                  <label className='col-form-label mb'>Zgjedh vitin:</label>
                  <Col md={5}>
                    <DateTimeBoostrap
                      closeOnSelect
                      key='selectDate'
                      value={this.state.selectedDate}
                      onChange={this._onDateChange}
                      locale='de'
                      utc={true}
                      dateFormat='YYYY'
                      onBlur={this._onDateBlur}
                      data-validate='["validDate"]'
                      timeFormat={false}
                      inputProps={{
                        'data-validate': '["validDate"]',
                        className: 'form-control',
                        name: 'data',
                        autoComplete: 'off'
                      }}
                    />
                  </Col>
                </div>
              </Col>
              <MUIDataTable columns={columns} options={options} data={data} />
            </Card>
          </Col>
        </Row> */}
      </ContentWrapper>
    )
  }
}

export default Wages
