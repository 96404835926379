import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Text, Tooltip, XAxis, YAxis } from 'recharts'

const ApplicantsChart = ({ applicantHistory }) => {
  const CustomizedAxisTick = (props) => {
    const { x, y, payload } = props
    return (
      <g transform={`translate(${x} ${y})`}>
        <Text angle={-45} dy={5} fontSize={'12px'} textAnchor='end' fill='#666'>
          {payload.value}
        </Text>
      </g>
    )
  }

  return (
    <div className='card card-default shadow' style={{ width: '100%' }}>
      <ResponsiveContainer height={320}>
        <AreaChart
          height={320}
          margin={{
            top: 30,
            right: 30,
            bottom: 50,
            left: 0
          }}
          data={applicantHistory}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis interval={0} allowDataOverflow={true} dataKey='muaji' angle={-45} tick={<CustomizedAxisTick />} />
          <YAxis />
          <Tooltip />
          <Area type='monotone' dataKey='totali' stroke='#c19191' fill='#e1ab93' />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}

export default ApplicantsChart
