import axios from 'axios'
import pubsub from 'pubsub-js'
import React from 'react'
import { Button, Card, CardBody, CardFooter, Col, Input, Row } from 'reactstrap'
import { DeleteDialogBox } from '../../Common/'
import FormValidator from '../../Forms/FormValidator.js'
import ContentWrapper from '../../Layout/ContentWrapper'
class EditCities extends React.Component {
  constructor() {
    super()
    this.state = {
      formCities: {
        city: ''
      },
      isEdit: false
    }
  }

  componentDidMount() {
    const idCity = this.props.match.params.idCity
    if (idCity) {
      this.setState({ isEdit: true })
      axios
        .get(`cities/${idCity}`)
        .then((response) => {
          if (response.data.success) {
            this.setState({
              formCities: { city: response.data.data.name }
            })
          }
        })
        .catch((error) => {})
    }
  }

  deleteCity() {
    const ID = this.props.match.params.idCity
    const goBack = () => this.props.history.goBack()
    const path = `cities/${ID}`
    const name = 'Qytetin'
    DeleteDialogBox({ path, name, goBack })
  }

  validateOnChange = (event) => {
    const input = event.target
    const form = input.form
    const value = input.type === 'checkbox' ? input.checked : input.value
    const result = FormValidator.validate(input)

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    })
  }

  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    )
  }

  onSubmit = (e) => {
    e.preventDefault()
    const idCity = this.props.match.params.idCity
    const form = e.target
    const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT'].includes(i.nodeName))

    const { errors, hasError } = FormValidator.bulkValidate(inputs)
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      }
    })

    if (!hasError) {
      const postData = {
        name: this.state.formCities.city
      }
      if (idCity) {
        axios
          .put(`cities/${idCity}`, postData)
          .then((res) => {
            if (res.data.success) {
              this.props.history.push('/cities')
              pubsub.publish('showNotification', {
                message: 'Të dhënat janë ndryshuar!',
                type: 'success'
              })
            } else {
              pubsub.publish('showNotification', {
                message: 'Gabim gjatë ndryshimit të të dhënave',
                type: 'error'
              })
            }
          })

          .catch((err) => {
            pubsub.publish('showNotification', {
              message: 'Gabim gjatë ruajtjes së të dhënave',
              type: 'error'
            })
          })
      } else {
        axios
          .post('cities', postData)
          .then((res) => {
            if (res.data.success) {
              this.props.history.push('/cities')
              pubsub.publish('showNotification', {
                message: 'Të dhënat janë ruajtur!',
                type: 'success'
              })
            } else {
              pubsub.publish('showNotification', {
                message: res.data.message,
                type: 'error'
              })
            }
          })
          .catch((err) => {})
      }
    }
  }

  render() {
    return (
      <ContentWrapper>
        {this.state.isEdit ? (
          <div className='d-flex justify-content-between'>
            <p style={{ fontSize: 23, color: 'gray' }}>Modifiko Qytetin</p>
            <div>
              <Button onClick={() => this.props.history.goBack()} color='info' className='btn-labeled'>
                <span className='btn-label'>
                  <i className='fa fa-arrow-left' />
                </span>{' '}
                Prapa
              </Button>
            </div>
          </div>
        ) : (
          <div className='d-flex justify-content-between'>
            <p style={{ fontSize: 23, color: 'gray' }}>Shto Qytetin</p>
            <div>
              <Button onClick={() => this.props.history.goBack()} color='info' className='btn-labeled'>
                <span className='btn-label'>
                  <i className='fa fa-arrow-left' />
                </span>{' '}
                Prapa
              </Button>
            </div>
          </div>
        )}
        <Row>
          <div className='col-md-12'>
            <form onSubmit={this.onSubmit} name='formCities'>
              {/* START card */}
              <Card className='card-default'>
                <CardBody>
                  <fieldset>
                    <div className='form-group row align-items-center'>
                      <label className='col-md-2 col-form-label'>Emri i qytetit :</label>
                      <Col md={6}>
                        <Input
                          key='city'
                          type='text'
                          name='city'
                          invalid={this.hasError('formCities', 'city', 'required')}
                          onChange={this.validateOnChange}
                          data-validate='["required"]'
                          value={this.state.formCities.city}
                        />
                        <span className='invalid-feedback'>Fusha është e nevojshme</span>
                      </Col>
                      <Col md={4} />
                    </div>
                  </fieldset>
                </CardBody>

                {this.state.isEdit ? (
                  <CardFooter className='text-right'>
                    <button style={{ marginRight: 10 }} type='submit' className='btn btn-info'>
                      Ruaj / Modifiko
                    </button>
                    <Button color='danger' onClick={this.deleteCity.bind(this)}>
                      Fshij
                    </Button>
                  </CardFooter>
                ) : (
                  <CardFooter className='text-right'>
                    <button type='submit' className='btn btn-info'>
                      Ruaj
                    </button>
                  </CardFooter>
                )}
              </Card>
              {/* END card */}
            </form>
          </div>
        </Row>
      </ContentWrapper>
    )
  }
}

export default EditCities
