import { inject } from 'mobx-react'
import React, { useState, useEffect } from 'react'
import ContentWrapper from '../../components/Layout/ContentWrapper'
import ApplicantComponent from './pages/Applicant'
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query'
import axios from 'axios'

export const useDataQuery = (key, endpoint, dependencies = null) => {
  return useQuery({
    queryKey: [key, endpoint],
    queryFn: async () => {
      const response = await axios.get(endpoint)
      return response.data.data
    },
    ...(dependencies && { refetchOnReconnect: true })
  })
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      keepPreviousData: true,
      staleTime: 120 * 1000
    }
  }
})

const Applicant = inject('userStore')((props) => {
  const [user, setUser] = useState(null)

  useEffect(() => {
    if (props.userStore.user_id) {
      setUser({
        user_id: props.userStore.user_id,
        userLevel: props.userStore.userLevel
      })
    }
  }, [props.userStore.user_id, props.userStore.userLevel])

  return (
    <ContentWrapper>
      <QueryClientProvider client={queryClient}>
        {user && <ApplicantComponent user={user} id={props.match.params.applicantId} />}
      </QueryClientProvider>
    </ContentWrapper>
  )
})

export default Applicant
