export const columns = (type) => [
  { name: 'name', label: 'Emri & Mbiemri', options: { sort: false } },
  { name: 'email', label: 'Email', options: { sort: false } },
  type === 'haveBadge' ? { name: 'speeex_badge', label: 'Bexhi Aktual', options: { sort: false } } : '',
  { name: 'identity_card', label: 'Nr Personal', options: { sort: false } },
  { name: 'project', label: 'Projekti', options: { sort: false } },
  { name: 'department', label: 'Departmenti', options: { sort: false } },
  { name: 'office_location', label: 'Lokacioni i punës', options: { sort: false } },
  { name: 'more', label: 'More', options: { sort: false } }
]
