import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import React, { useState } from 'react'
import TextField from '@mui/material/TextField'

const DatePickerFilter = ({ date, setDate }) => {
  const [open, setOpen] = useState(false)

  const currentDate = date || null

  return (
    <DatePicker
      label={'Zgjidh Periudhën'}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      inputFormat='MM/yyyy'
      views={['year', 'month']}
      value={currentDate}
      onChange={(e) => setDate(e)}
      renderInput={(params) => (
        <TextField
          fullWidth
          size='small'
          sx={{
            '.MuiInputBase-input': {
              padding: '11px 14px !important'
            }
          }}
          {...params}
          //   error={error}
          //   helperText={helperText}
          onClick={() => setOpen(true)}
        />
      )}
      PopperProps={{
        placement: 'bottom-start',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 8]
            }
          }
        ]
      }}
    />
  )
}

export default DatePickerFilter
