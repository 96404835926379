import React, { useState, useEffect } from 'react'
import QRCode from 'qrcode'
import TwoFactorAuthForm from './TwoFactorAuthForm'

const TwoFactorAuthSetup = ({ uri, onSubmit, onBackToLogin, onError }) => {
  const [qrCode, setQrCode] = useState(null)

  useEffect(() => {
    if (uri) {
      QRCode.toDataURL(uri, (err, url) => {
        if (err) {
          console.error(err)
          onError('Failed to generate QR code')
        } else {
          setQrCode(url)
        }
      })
    }
  }, [uri, onError])

  return (
    <div>
      <h5 className='mb-3 text-center'>Aktivizoni Autentifikimin me Dy Faktorë (2FA)</h5>
      <p className='text-center'>
        Për të rritur sigurinë e llogarisë tuaj, ju lutemi skanoni këtë kod QR me një aplikacion si <strong>Google Authenticator</strong>.
        Pas skanimit, futni kodin e gjeneruar nga aplikacioni në fushën e mëposhtme.
      </p>
      {qrCode && (
        <div className='d-flex justify-content-center align-item-center'>
          <img src={qrCode} alt='QR Code' className='mt-3 mb-4' />
        </div>
      )}
      <TwoFactorAuthForm onSubmit={onSubmit} onBackToLogin={onBackToLogin} />
    </div>
  )
}

export default TwoFactorAuthSetup
