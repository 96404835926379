import axios from 'axios'
import pubsub from 'pubsub-js'
import React from 'react'
import { Button, Card, CardBody, CardFooter, Col, Input, Row } from 'reactstrap'
import { DeleteDialogBox } from '../../Common/'
import FormValidator from '../../Forms/FormValidator.js'
import ContentWrapper from '../../Layout/ContentWrapper'

class EditLanguage extends React.Component {
  constructor() {
    super()
    this.state = {
      formLang: {
        nameLang: '',
        codeLang: '',
        descLang: ''
      },
      isEdit: false
    }
  }

  deleteLang() {
    const ID = this.props.match.params.idLanguage
    const goBack = () => this.props.history.goBack()
    const path = `languages/${ID}`
    const name = 'Gjuhen'
    DeleteDialogBox({ path, name, goBack })
  }

  async componentDidMount() {
    const idLanguage = this.props.match.params.idLanguage
    if (idLanguage) {
      try {
        const res = await axios.get('languages/' + idLanguage, {})
        if (res.data.success) {
          this.setState({
            isEdit: true,
            formLang: {
              nameLang: res.data.data.name,
              codeLang: res.data.data.code,
              descLang: res.data.data.description
            }
          })
        }
      } catch (error) {}
    }
  }

  validateOnChange = (event) => {
    const input = event.target
    if (input.name == 'codeLang') {
      input.value = input.value.toUpperCase()
      if (input.value.length > 3) {
        return
      }
    }
    const form = input.form

    const value = input.type === 'checkbox' ? input.checked : input.value

    const result = FormValidator.validate(input)

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    })
  }

  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    )
  }

  onSubmit = (e) => {
    e.preventDefault()
    const idLanguage = this.props.match.params.idLanguage
    const form = e.target
    const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT'].includes(i.nodeName))

    const { errors, hasError } = FormValidator.bulkValidate(inputs)
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      }
    })
    if (!hasError) {
      const postData = {
        name: this.state.formLang.nameLang,
        code: this.state.formLang.codeLang
      }

      if (!this.state.formLang.descLang == '') {
        postData.description = this.state.formLang.descLang
      }

      if (idLanguage) {
        axios
          .put(`languages/${idLanguage}`, postData)
          .then((res) => {
            if (res.data.success) {
              this.props.history.push('/language')
              pubsub.publish('showNotification', {
                message: 'Të dhënat janë ndryshuar!',
                type: 'success'
              })
            } else {
              pubsub.publish('showNotification', {
                message: 'Gabim gjatë ndryshimit të të dhënave',
                type: 'error'
              })
            }
          })

          .catch((err) => {
            pubsub.publish('showNotification', {
              message: 'Gabim gjatë ruajtjes së të dhënave',
              type: 'error'
            })
          })
      } else {
        axios
          .post('languages', postData)
          .then((res) => {
            if (res.data.success) {
              this.props.history.push('/language')
              pubsub.publish('showNotification', {
                message: 'Të dhënat janë ruajtur!',
                type: 'success'
              })
            } else {
              pubsub.publish('showNotification', {
                message: res.data.message,
                type: 'error'
              })
            }
          })
          .catch((err) => {})
      }
    }
  }

  render() {
    const id = this.props.match.params.idLanguage
    return (
      <ContentWrapper>
        {this.state.isEdit ? (
          <div className='d-flex justify-content-between'>
            <p style={{ fontSize: 23, color: 'gray' }}>Modifiko gjuhën</p>
            <div>
              <Button onClick={() => this.props.history.goBack()} color='info' className='btn-labeled'>
                <span className='btn-label'>
                  <i className='fa fa-arrow-left' />
                </span>{' '}
                Prapa
              </Button>
            </div>
          </div>
        ) : (
          <div className='d-flex justify-content-between'>
            <p style={{ fontSize: 23, color: 'gray' }}>Shto gjuhë</p>
            <div>
              <Button onClick={() => this.props.history.goBack()} color='info' className='btn-labeled'>
                <span className='btn-label'>
                  <i className='fa fa-arrow-left' />
                </span>{' '}
                Prapa
              </Button>
            </div>
          </div>
        )}
        <Row>
          <div className='col-md-12'>
            <form onSubmit={this.onSubmit} name='formLang'>
              {/* START card */}
              <Card className='card-default'>
                <CardBody>
                  <fieldset>
                    <div className='form-group row align-items-center'>
                      <label className='col-md-2 col-form-label'>Emri i gjuhës :</label>
                      <Col md={6}>
                        <Input
                          key='nameLang'
                          type='text'
                          name='nameLang'
                          invalid={this.hasError('formLang', 'nameLang', 'required')}
                          onChange={this.validateOnChange}
                          data-validate='["required"]'
                          value={this.state.formLang.nameLang}
                        />
                        <span className='invalid-feedback'>Fusha është e nevojshme</span>
                      </Col>
                      <Col md={4} />
                    </div>
                  </fieldset>

                  <fieldset>
                    <div className='form-group row align-items-center'>
                      <label className='col-md-2 col-form-label'>Kodi i gjuhës :</label>
                      <Col md={6}>
                        <Input
                          key='codeLang'
                          type='text'
                          name='codeLang'
                          invalid={this.hasError('formLang', 'codeLang', 'required')}
                          onChange={this.validateOnChange}
                          data-validate='["required"]'
                          value={this.state.formLang.codeLang}
                        />
                        <span className='invalid-feedback'>Fusha është e nevojshme</span>
                      </Col>
                      <Col md={4} />
                    </div>
                  </fieldset>

                  <fieldset>
                    <div className='form-group row align-items-center'>
                      <label className='col-md-2 col-form-label'>Përshkrimi i gjuhës :</label>
                      <Col md={6}>
                        <Input
                          key='descLang'
                          type='text'
                          name='descLang'
                          onChange={this.validateOnChange}
                          value={this.state.formLang.descLang}
                        />
                        <span className='invalid-feedback'>Fusha është e nevojshme</span>
                      </Col>
                      <Col md={4} />
                    </div>
                  </fieldset>
                </CardBody>

                {this.state.isEdit ? (
                  <CardFooter className='text-right'>
                    <button style={{ marginRight: 10 }} type='submit' className='btn btn-info'>
                      Ruaj / Modifiko
                    </button>
                    <Button color='danger' onClick={this.deleteLang.bind(this)}>
                      Fshij
                    </Button>
                  </CardFooter>
                ) : (
                  <CardFooter className='text-right'>
                    <button type='submit' className='btn btn-info'>
                      Ruaj
                    </button>
                  </CardFooter>
                )}
              </Card>
              {/* END card */}
            </form>
          </div>
        </Row>
      </ContentWrapper>
    )
  }
}

export default EditLanguage
