import AccessTimeIcon from '@material-ui/icons/AccessTime'
import CompareArrowsIcon from '@material-ui/icons/CompareArrows'
import FlagIcon from '@material-ui/icons/Flag'
import ScheduleIcon from '@material-ui/icons/Schedule'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { Box, Card, CardContent, Grid, Typography, Tooltip } from '@mui/material'

const TotalHoursCard = ({
  userId,
  targetHours,
  expectedHours,
  actualHours,
  differenceHours,
  differenceBounusHours,
  leavesHours,
  lastUpdatedDate,
  haveData
}) => {
  const username = localStorage.getItem('name')

  const stats = [
    {
      label: 'Targeti i orëve të punës efektive',
      value: targetHours != null ? `${targetHours.toFixed(2)}h` : '--',
      icon: <FlagIcon color='primary' fontSize='large' />,
      tooltip: 'Totali i orëve të mundshme për muajin e zgjedhur'
    },
    {
      label: 'Orët deri tani',
      value: expectedHours != null ? `${expectedHours.toFixed(2)}h` : '--',
      icon: <ScheduleIcon color='secondary' fontSize='large' />,
      tooltip: 'Totali i orëve të cilat do të duhej të arriten deri me datën e përditësimit'
    },
    {
      label: 'Orët e arritura',
      value: actualHours != null ? `${actualHours.toFixed(2)}h` : '--',
      icon: <AccessTimeIcon color='success' fontSize='large' />,
      category: 'Pushime me pagesë',
      categoryValue: `${leavesHours?.toFixed(2)}h`,
      tooltip: 'Orët e arritura duke i mbledhur orët e punës nga sistemi si dhe pushimet me pagesë'
    },
    {
      label: 'Diferenca bazë',
      value: differenceHours != null ? `${differenceHours.toFixed(2)}h` : '--',
      icon: <CompareArrowsIcon color={differenceHours != null && differenceHours < 0 ? 'error' : 'info'} fontSize='large' />,
      tooltip:
        'Diferenca bazë: Dallimi i oreve te arritura ndaj totalit te oreve qe do te duhej te arriten deri me daten e perditesimit. \n Diferenca për bonuse: Dallimi i orëve të arritura ndaj totalit të orëve që do të duhej të arriten deri me daten e perditësimit duke zbritur pushimet mjekësore',
      label2: 'Diferenca për bonuse',
      value2: `${differenceBounusHours?.toFixed(2)}h`
    }
  ]

  return (
    <Card sx={{ position: 'relative' }}>
      <CardContent>
        {userId ? (
          <Typography variant='h6'>Orët e përdoruesit</Typography>
        ) : (
          <Typography variant='h6'>Përshëndetje, {username}!</Typography>
        )}

        <Grid container spacing={2} mt={5} alignItems='stretch'>
          {stats.map((stat, index) => (
            <Grid key={index} item xs={12} sm={12} md={6} lg={6} xl={3}>
              <Box
                sx={{
                  position: 'relative', // set relative positioning for absolute tooltip placement
                  width: '100%',
                  height: '150px',
                  p: 2,
                  border: '1px solid #ddd',
                  borderRadius: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  overflow: 'hidden'
                }}
              >
                {/* Added Tooltip */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    zIndex: 10
                  }}
                >
                  <Tooltip title={stat.tooltip} arrow>
                    <InfoOutlinedIcon
                      sx={{
                        fontSize: '1.5rem',
                        color: '#888',
                        cursor: 'pointer'
                      }}
                    />
                  </Tooltip>
                </Box>

                <Box display='flex' alignItems='center' mb={2}>
                  <Box
                    sx={{
                      fontSize: '1rem',
                      mr: 1,
                      color: stat.color
                    }}
                  >
                    {stat.icon}
                  </Box>
                </Box>
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                  <Typography variant='body1' sx={{ fontSize: '1rem' }}>
                    {stat.label}
                  </Typography>
                  <Typography variant='h5' sx={{ fontSize: '1.4rem' }}>
                    {stat.value}
                  </Typography>
                </Box>
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                  <Typography variant='body1' sx={{ marginLeft: '10px', fontSize: '0.8rem' }}>
                    {stat.category}
                  </Typography>
                  <Typography variant='h5' sx={{ fontSize: '1.1rem' }}>
                    {stat.categoryValue}
                  </Typography>
                </Box>
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                  <Typography variant='body1' sx={{ fontSize: '1rem' }}>
                    {stat.label2}
                  </Typography>
                  <Typography variant='h5' sx={{ fontSize: '1.4rem' }}>
                    {stat.value2}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </CardContent>
      {haveData && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, p: 2 }}>
          Përditësimi i fundit: {new Date(lastUpdatedDate).toLocaleDateString('en-GB')}
        </Box>
      )}
    </Card>
  )
}

export default TotalHoursCard
