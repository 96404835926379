import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import axios from 'axios'
import { saveAs } from 'file-saver'
import moment from 'moment'
import { useState } from 'react'
import { Button } from 'reactstrap'
import Swal from 'sweetalert2'

const useStyles = makeStyles((theme) => ({
  textfield: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.23)'
    }
  }
}))
const DatePickers = () => {
  const classes = useStyles()
  const [dateFrom, setDateFrom] = useState(null)
  const [dateTo, setDateTo] = useState(null)

  const downloadOrders = () => {
    const queryDateFrom = dateFrom ? moment(dateFrom).hour(2).minutes(1) : ''
    const queryDateTo = dateTo ? moment(dateTo).hour(24).minutes(59) : ''
    const query = '?from=' + queryDateFrom.valueOf() + '&to=' + queryDateTo.valueOf()

    Swal.fire({
      title: 'Ju lutem prisni...',
      html: 'Duke shkarkuar të dhënat për shitjet',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading()
      }
    })
    axios
      .post(`/orders/exportOrdersByDate${query}`, '', {
        responseType: 'blob'
      })
      .then((response) => {
        if (response.status === 204) {
          Swal.fire('', response.statusText, 'info')
          return
        }
        if (response.data) {
          if (response.headers['content-type'] == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            saveAs(response.data, 'Orders.csv')
          }
        }
        Swal.close()
      })
      .catch((error) => {
        Swal.close()
        Swal.fire('', 'Ndodhi nje gabim!', 'error')
      })
  }

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'end'
          }}
        >
          <h4
            style={{
              margin: 'auto 20px',
              height: 'fit-content'
            }}
          >
            Shkarko shitjet per datat specifike:
          </h4>
          <div style={{ marginRight: '10px' }}>
            <DatePicker
              clearable
              openTo='year'
              label='Prej datës'
              inputFormat='dd/MM/yyyy'
              views={['year', 'month', 'day']}
              value={dateFrom}
              maxDate={Date.now()}
              onChange={(newValue) => {
                setDateFrom(newValue)
              }}
              renderInput={(params) => <TextField {...params} variant='outlined' size='small' className={classes.textfield} />}
            />
          </div>{' '}
          <div style={{ marginRight: '40px' }}>
            <DatePicker
              clearable
              openTo='year'
              label='Deri më'
              inputFormat='dd/MM/yyyy'
              views={['year', 'month', 'day']}
              value={dateTo}
              minDate={dateFrom}
              maxDate={Date.now()}
              onChange={(newValue) => {
                setDateTo(newValue)
              }}
              renderInput={(params) => <TextField {...params} variant='outlined' size='small' className={classes.textfield} />}
            />
          </div>
          <Button onClick={() => downloadOrders()}>Shkarko</Button>
        </div>
      </LocalizationProvider>
    </div>
  )
}

export default DatePickers
