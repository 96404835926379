import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { APPLICANTS_API } from '../../utils/consts'
import ApplicantsComponent from './pages/Applicants'
import formatDataForTable from './utils/formatDataForTable'
import getQuery from './utils/getQuery'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      keepPreviousData: true,
      staleTime: 120 * 1000
    }
  }
})

export const useGetApplicants = (query) => {
  return useQuery({
    queryKey: ['applicants', query],
    queryFn: async () => {
      const queryText = getQuery(query)
      const { data } = await axios.get(`${APPLICANTS_API}/applicants?${queryText}`)
      if (data) {
        const _records = data.records
        const _applicants = data.result.map((item) => formatDataForTable(item))
        const _statistics = data.statistics
        return { applicants: _applicants, records: _records, statistics: _statistics }
      }
    }
  })
}

export const useApplicantHistory = (query) => {
  return useQuery({
    queryKey: ['applicantHistory', query.status],
    queryFn: async () => {
      const response = await axios.get(`${APPLICANTS_API}/applicants/applicantHistory?status=${query.status}`)
      return response.data
    }
  })
}

export const downloadCsv = (query, searchTxt) => {
  const queryText = getQuery(query, searchTxt)
  axios
    .get(`${APPLICANTS_API}/applicants/csv/applicants?${queryText}`, {
      responseType: 'blob'
    })
    .then((response) => {
      if (response.headers['content-type'] == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        saveAs(response.data, 'Aplikantet.csv')
      }
    })
    .catch((error) => {
      console.log('error', error)
    })
}

const Applicants = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ApplicantsComponent />
    </QueryClientProvider>
  )
}

export default Applicants
