import React from 'react'
import TwoFactorAuthSetup from './TwoFactorAuthSetup'
import TwoFactorAuthForm from './TwoFactorAuthForm'

const TwoFactorAuth = ({ setup2Fa, viaEmail, uri, onSubmit, onBackToLogin, onError }) => (
  <div>
    {setup2Fa ? (
      <TwoFactorAuthSetup uri={uri} onSubmit={onSubmit} onError={onError} onBackToLogin={onBackToLogin} />
    ) : (
      <TwoFactorAuthForm onSubmit={onSubmit} isVerification viaEmail={viaEmail} onBackToLogin={onBackToLogin} />
    )}
  </div>
)

export default TwoFactorAuth
