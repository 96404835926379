import { Box, Grid, TextField, Button } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import Locale from 'date-fns/locale/sq'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDebounce } from '../../../utils/searchDebouncer'

const TableHeader = ({ selectedFilters, setSelectedFilters }) => {
  const [inputValue, setInputValue] = useState('')
  const debouncedSearchText = useDebounce(inputValue)

  useEffect(() => {
    setSelectedFilters((prev) => ({ ...prev, searchText: debouncedSearchText, page: 0 }))
  }, [debouncedSearchText])

  const handleDateChange = (date, key) => {
    if (date && date instanceof Date) {
      setSelectedFilters((prev) => ({
        ...prev,
        page: 0,
        [key]: moment(date).format('YYYY-MM-DD')
      }))
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={Locale}>
      <Grid container gap={3} sx={{ fieldset: { border: '1px solid' } }}>
        <Grid item>
          <Box sx={{ display: 'flex', justifyContent: 'start' }}>
            <TextField
              label='Kërko përdoruesin'
              size='small'
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
            />
          </Box>
        </Grid>
        <Grid container item width='fit-content' gap={1}>
          <Grid item>
            <DatePicker
              label='Prej:'
              openTo='year'
              inputFormat='dd/MM/yyyy'
              views={['year', 'month', 'day']}
              value={selectedFilters.fromDateValue}
              onAccept={(date) => handleDateChange(date, 'fromDateValue')}
              renderInput={(params) => <TextField size='small' {...params} sx={{ width: '150px' }} />}
              onChange={() => {}}
            />
          </Grid>
          <Grid item>
            <DatePicker
              label='Deri:'
              openTo='year'
              inputFormat='dd/MM/yyyy'
              views={['year', 'month', 'day']}
              value={selectedFilters.toDateValue}
              onAccept={(date) => handleDateChange(date, 'toDateValue')}
              renderInput={(params) => <TextField size='small' {...params} sx={{ width: '150px' }} />}
              onChange={() => {}}
            />
          </Grid>
        </Grid>
      </Grid>
    </LocalizationProvider>
  )
}

export default TableHeader
