import React from 'react'
import { Box, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  textfield: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.23)'
    }
  }
}))

const SearchFilter = ({ query, setQuery }) => {
  const classes = useStyles()

  return (
    <TextField
      variant='outlined'
      label='Kërko aplikuesin...'
      size='small'
      fullWidth
      className={classes.textfield}
      value={query.name}
      onChange={(e) => {
        setQuery((prev) => ({ ...prev, name: e.target.value }))
      }}
    />
  )
}

export default SearchFilter
