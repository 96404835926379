import axios from 'axios'
import pubsub from 'pubsub-js'
import React from 'react'
import { Button, Card, CardBody, CardFooter, Col, Input, Row } from 'reactstrap'
import { DeleteDialogBox } from '../../Common/'
import FormValidator from '../../Forms/FormValidator.js'
import ContentWrapper from '../../Layout/ContentWrapper'

class EditProjects extends React.Component {
  constructor() {
    super()
    this.state = {
      isEdit: false,
      formProj: {
        nameProj: '',
        descProj: ''
      }
    }
  }

  validateOnChange = (event) => {
    const input = event.target
    const form = input.form
    const value = input.type === 'checkbox' ? input.checked : input.value
    const result = FormValidator.validate(input)

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    })
  }

  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    )
  }

  onSubmit = (e) => {
    e.preventDefault()
    const idProjects = this.props.match.params.idProjects
    const form = e.target
    const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT'].includes(i.nodeName))

    const { errors, hasError } = FormValidator.bulkValidate(inputs)
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      }
    })
    if (!hasError) {
      const postData = {
        name: this.state.formProj.nameProj,
        description: this.state.formProj.descProj
      }
      if (idProjects) {
        axios
          .put(`projects/${idProjects}`, postData)
          .then((res) => {
            if (res.data.success) {
              this.props.history.push('/projects')
              pubsub.publish('showNotification', {
                message: 'Të dhënat janë ndryshuar!',
                type: 'success'
              })
            } else {
              pubsub.publish('showNotification', {
                message: 'Gabim gjatë ndryshimit të të dhenave',
                type: 'error'
              })
            }
          })

          .catch((err) => {
            pubsub.publish('showNotification', {
              message: 'Gabim gjatë ruajtjes së të dhenave',
              type: 'error'
            })
          })
      } else {
        axios
          .post('projects', postData)
          .then((res) => {
            if (res.data.success) {
              this.props.history.push('/projects')
              pubsub.publish('showNotification', {
                message: 'Të dhënat janë ruajtur!',
                type: 'success'
              })
            } else {
              pubsub.publish('showNotification', {
                message: res.data.message,
                type: 'error'
              })
            }
          })
          .catch((err) => {})
      }
    }
  }

  componentDidMount() {
    const idProjects = this.props.match.params.idProjects
    if (idProjects) {
      axios
        .get(`projects/${idProjects}`)
        .then((response) => {
          if (response.data.success) {
            this.setState({
              isEdit: true,
              formProj: {
                nameProj: response.data.data.name,
                descProj: response.data.data.description
              }
            })
          }
        })
        .catch((error) => {})
    }
  }

  deleteProject() {
    const ID = this.props.match.params.idProjects
    const goBack = () => this.props.history.goBack()
    const path = `projects/${ID}`
    const name = 'Projektin'
    DeleteDialogBox({ path, name, goBack })
  }

  render() {
    return (
      <ContentWrapper>
        {this.state.isEdit ? (
          <div className='d-flex justify-content-between'>
            <p style={{ fontSize: 23, color: 'gray' }}>Modifiko Projektin</p>
            <div>
              <Button onClick={() => this.props.history.goBack()} color='info' className='btn-labeled'>
                <span className='btn-label'>
                  <i className='fa fa-arrow-left' />
                </span>{' '}
                Prapa
              </Button>
            </div>
          </div>
        ) : (
          <div className='d-flex justify-content-between'>
            <p style={{ fontSize: 23, color: 'gray' }}>Shto Projekt</p>
            <div>
              <Button onClick={() => this.props.history.goBack()} color='info' className='btn-labeled'>
                <span className='btn-label'>
                  <i className='fa fa-arrow-left' />
                </span>{' '}
                Prapa
              </Button>
            </div>
          </div>
        )}
        <Row>
          <div className='col-md-12'>
            <form onSubmit={this.onSubmit} name='formProj'>
              {/* START card */}
              <Card className='card-default'>
                <CardBody>
                  <legend className='mb-4' />
                  <fieldset>
                    <div className='form-group row align-items-center'>
                      <label className='col-md-2 col-form-label'>Emri i Projektit :</label>
                      <Col md={6}>
                        <Input
                          key='nameProj'
                          type='text'
                          name='nameProj'
                          invalid={this.hasError('formProj', 'nameProj', 'required')}
                          onChange={this.validateOnChange}
                          data-validate='["required"]'
                          value={this.state.formProj.nameProj}
                        />
                        <span className='invalid-feedback'>Fusha është e nevojshme</span>
                      </Col>
                      <Col md={4} />
                    </div>
                  </fieldset>

                  <fieldset>
                    <div className='form-group row align-items-center'>
                      <label className='col-md-2 col-form-label'>Përshkrimi i Projektit :</label>
                      <Col md={6}>
                        <Input
                          key='descProj'
                          type='text'
                          name='descProj'
                          onChange={this.validateOnChange}
                          value={this.state.formProj.descProj}
                        />
                        <span className='invalid-feedback'>Fusha është e nevojshme</span>
                      </Col>
                      <Col md={4} />
                    </div>
                  </fieldset>
                </CardBody>
                {this.state.isEdit ? (
                  <CardFooter className='text-right'>
                    <button style={{ marginRight: 10 }} type='submit' className='btn btn-info'>
                      Ruaj / Modifiko
                    </button>
                    <Button color='danger' onClick={this.deleteProject.bind(this)}>
                      Fshij
                    </Button>
                  </CardFooter>
                ) : (
                  <CardFooter className='text-right'>
                    <button type='submit' className='btn btn-info'>
                      Ruaj
                    </button>
                  </CardFooter>
                )}
              </Card>
              {/* END card */}
            </form>
          </div>
        </Row>
      </ContentWrapper>
    )
  }
}

export default EditProjects
