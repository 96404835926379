import { Box, Button as MUIButton, makeStyles, MenuItem, TextField, Typography } from '@material-ui/core'
import { FormControl, InputLabel, Select } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import axios from 'axios'
import moment from 'moment'
import MUIDataTable from 'mui-datatables'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Button, Badge } from 'reactstrap'
import PersonalBusinessTrip from './PersonalBusinessTrip'
import pubsub from 'pubsub-js'
import { useLocation } from 'react-router'
import { saveAs } from 'file-saver'
import Swal from 'sweetalert2'

const useStyles = makeStyles((theme) => ({
  textfield: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.23)'
    },
    width: '200px'
  }
}))

const BusinessTripComp = ({ userID, loggedInUser }) => {
  const location = useLocation()
  const classes = useStyles()
  const [dateFrom, setDateFrom] = useState(null)
  const [dateFromInput, setDateFromInput] = useState(null)
  const [dateTo, setDateTo] = useState(null)
  const [dateToInput, setDateToInput] = useState(null)
  const [name, setName] = useState(null)
  const [status, setStatus] = useState('all')
  const [records, setRecords] = useState()
  const [pagination, setPagination] = useState({ limit: 10, page: 0 })
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  const [detailsModal, setDetailsModal] = useState(false)
  const [detailsDateFrom, setDetailsDateFrom] = useState(null)
  const [detailsDateTo, setDetailsDateTo] = useState(null)
  const [detailsDescription, setDetailsDescription] = useState('')
  const [detailsStatus, setDetailsStatus] = useState()
  const [detailsHRDescription, setDetailsHRDescription] = useState('')
  const [userName, setUserName] = useState('')

  const [newHrDescription, setNewHRDescription] = useState('')
  const [newStatus, setNewStatus] = useState()
  const [idToUpdate, setIdToUpdate] = useState('')

  const statuses = [
    { text: 'Refuzuar', color: 'danger' },
    { text: 'Në pritje', color: 'info' },
    { text: 'Aprovuar', color: 'success' }
  ]

  const getAllBusinessTrip = async () => {
    let query = `page=${pagination.page}&limit=${pagination.limit}`
    if (dateFrom) {
      query = query + `&date_from=${dateFrom.getTime()}`
    }
    if (dateTo) {
      query = query + `&date_to=${dateTo.getTime()}`
    }
    if (status !== 'all') {
      query = query + `&status=${status}`
    }
    if (name) {
      query = query + `&name=${name}`
    }

    axios
      .get(`businesstrip?${query}`)
      .then((data) => {
        if (data.data.status) {
          setRecords(data.data.records)
          const _data = data.data.data.map((item) => {
            return [
              `${item.user.first_name} ${item.user.last_name}`,
              moment(item.date_from).format('LL'),
              moment(item.date_to).format('LL'),
              <Badge
                style={{
                  padding: '10px',
                  fontSize: '14px',
                  borderRadius: '5px'
                }}
                color={statuses[item.status].color}
              >
                {statuses[item.status].text}
              </Badge>,
              <Button
                variant='contained'
                color='success'
                onClick={() => {
                  setDetailsModal(true)
                  axios
                    .get(`businesstrip/one`, {
                      params: { id: item._id }
                    })
                    .then((data) => {
                      if (data.data.status) {
                        setIdToUpdate(data.data.data._id)
                        setUserName(`${data.data.data.user.first_name} ${data.data.data.user.last_name}`)
                        setDetailsStatus(parseInt(data.data.data.status))
                        setDetailsDateFrom(data.data.data.date_from)
                        setDetailsDateTo(data.data.data.date_to)
                        setDetailsDescription(data.data.data.user_description)
                        setDetailsHRDescription(data.data.data.hr_description)
                      }
                    })
                }}
              >
                Më shumë
              </Button>
            ]
          })
          setData(_data)
        }
      })
      .catch((e) => console.log('error', e))
      .finally(() => {
        setLoading(false)
      })
  }

  const updateOneBusinessTrip = async () => {
    if (!newHrDescription || !newStatus) {
      pubsub.publish('showNotification', {
        message: 'Të gjitha fushat janë të nevojshme',
        type: 'error'
      })
    }
    const data = await axios.patch(`businesstrip?id=${idToUpdate}`, {
      hr_description: newHrDescription,
      status: newStatus
    })
    if (data.data.status) {
      pubsub.publish('showNotification', {
        message: 'Business Trip është modifikuar me sukses!',
        type: 'success'
      })
      getAllBusinessTrip()
      setNewStatus()
      setNewHRDescription()
      setIdToUpdate()
      setDetailsModal(false)
    } else {
      pubsub.publish('showNotification', {
        message: data.data.message || `Something went wrong!`,
        type: 'error'
      })
    }
  }

  const downloadCsv = () => {
    let query = `page=${pagination.page}&limit=${pagination.limit}`
    if (dateFrom) {
      query = query + `&date_from=${dateFrom.getTime()}`
    }
    if (dateTo) {
      query = query + `&date_to=${dateTo.getTime()}`
    }
    if (status !== 'all') {
      query = query + `&status=${status}`
    }
    if (name) {
      query = query + `&name=${name}`
    }
    Swal.fire({
      title: 'Ju lutem prisni...',
      html: 'Duke shkarkuar të dhënat për Business Trip.',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading()
      }
    })
    axios
      .get(`businesstrip/csv?${query}`, {
        responseType: 'blob'
      })
      .then((response) => {
        if (response.headers['content-type'] == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          saveAs(response.data, `Business-Trips (${moment().format('DD-MM-YYYY, HH:mm')}).csv`)
        }
        Swal.close()
      })
      .catch((error) => {
        console.log('error', error)
        Swal.close()
        Swal.fire('', 'Ndodhi nje gabim!', 'error')
      })
  }

  useEffect(() => {
    if (location.state?.data) {
      setDetailsModal(true)
      axios
        .get(`businesstrip/one`, {
          params: { id: location.state?.data }
        })
        .then((data) => {
          if (data.data.status) {
            setIdToUpdate(data.data.data._id)
            setUserName(`${data.data.data.user.first_name} ${data.data.data.user.last_name}`)
            setDetailsStatus(parseInt(data.data.data.status))
            setDetailsDateFrom(data.data.data.date_from)
            setDetailsDateTo(data.data.data.date_to)
            setDetailsDescription(data.data.data.user_description)
            setDetailsHRDescription(data.data.data.hr_description)
          }
        })
    }
  }, [location.state])

  useEffect(() => {
    getAllBusinessTrip()
  }, [status, dateFrom, dateTo, name, pagination.limit, pagination.page])

  const columns = [
    {
      name: 'Emri Mbiemri',
      label: 'Emri Mbiemri',
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'Prej datës',
      label: 'Prej datës',
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'Deri më datën',
      label: 'Deri më datën',
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'Statusi',
      label: 'Statusi',
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'Më shumë',
      label: 'Më shumë',
      options: {
        filter: false,
        sort: false,
        download: false
      }
    }
  ]

  const options = {
    download: false,
    print: false,
    filter: false,
    filterType: 'dropdown',
    selectableRows: 'none',
    responsive: 'scroll',
    elevation: 0,
    maxHeight: 'none',
    search: false,
    pagination: true,
    serverSide: true,
    count: records,
    rowsPerPageOptions: [10, 50, 100, 200],
    viewColumns: false,
    textLabels: {
      body: {
        noMatch: loading ? 'Loading...' : 'Nuk ka të dhëna'
      }
    },
    onTableChange: (action, a) => {
      switch (action) {
        case 'changeRowsPerPage':
          a.page = 0
          break
        default:
      }
    },
    onChangeRowsPerPage: (e) => {
      setPagination({ limit: e, page: 0 })
    },
    onChangePage: (e) => {
      setPagination((prev) => {
        return { ...prev, page: e }
      })
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box>
        {(loggedInUser.userLevel === 1 || loggedInUser.userLevel === 5 || loggedInUser.userLevel === 6) && (
          <Box sx={{ mb: 5 }}>
            <Typography variant='h6'>Kërkesat e përdoruesëve për Business Trip</Typography>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                my: 1
              }}
            >
              <DatePicker
                clearable
                openTo='year'
                views={['year', 'month', 'day']}
                componentsProps={{
                  actionBar: {
                    actions: ['clear']
                  }
                }}
                label='Prej datës'
                inputFormat='dd/MM/yyyy'
                value={dateFromInput}
                onChange={(newValue) => {
                  setDateFromInput(newValue)
                }}
                onAccept={(newValue) => {
                  setDateFrom(newValue)
                }}
                renderInput={(params) => <TextField {...params} variant='outlined' size='small' className={classes.textfield} />}
              />
              <DatePicker
                clearable
                openTo='year'
                views={['year', 'month', 'day']}
                componentsProps={{
                  actionBar: {
                    actions: ['clear']
                  }
                }}
                label='Deri më datën'
                inputFormat='dd/MM/yyyy'
                value={dateToInput}
                onChange={(newValue) => {
                  setDateToInput(newValue)
                }}
                onAccept={(newValue) => {
                  setDateTo(newValue)
                }}
                renderInput={(params) => (
                  <TextField {...params} variant='outlined' size='small' className={classes.textfield} style={{ margin: '0 20px' }} />
                )}
              />
              <FormControl size='small' sx={{ maxHeight: '40px' }}>
                <InputLabel>Statusi</InputLabel>
                <Select value={status} label='Statusi' onChange={(e) => setStatus(e.target.value)} className={classes.textfield}>
                  <MenuItem value='all'>
                    <em>Të gjitha</em>
                  </MenuItem>
                  <MenuItem value='0'>Refuzuar</MenuItem>
                  <MenuItem value='1'>Në pritje</MenuItem>
                  <MenuItem value='2'>Aprovuar</MenuItem>
                </Select>
              </FormControl>
              <TextField
                id='name-basic'
                label='Emri'
                variant='outlined'
                size='small'
                style={{ marginLeft: '20px' }}
                className={classes.textfield}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Box sx={{ ml: 'auto' }}>
                <MUIButton
                  variant='contained'
                  onClick={() => {
                    downloadCsv()
                  }}
                >
                  Shkarko në CSV
                </MUIButton>
              </Box>
            </Box>
            <MUIDataTable columns={columns} options={options} data={data} />
            {detailsModal && (
              <Modal
                show={detailsModal}
                onHide={() => {
                  setDetailsModal(false)
                  setUserName('')
                  setDetailsDateFrom(null)
                  setDetailsDateTo(null)
                  setDetailsDescription('')
                  setDetailsHRDescription('')
                  // setDetailsStatus();
                }}
                className='modal-1'
              >
                <Modal.Header closeButton>
                  <Modal.Title>Aplikimi i {userName} për Business Trip</Modal.Title>
                </Modal.Header>
                <Modal.Body
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap'
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '20px'
                    }}
                  >
                    <Typography>Statusi Aktual</Typography>
                    <Badge
                      style={{
                        padding: '10px',
                        fontSize: '18px',
                        fontWeight: '400',
                        borderRadius: '10px',
                        marginLeft: '10px',
                        padding: '8px 15px'
                      }}
                      color={statuses[detailsStatus !== undefined ? detailsStatus : 1].color}
                    >
                      {statuses[detailsStatus !== undefined ? detailsStatus : 1].text}
                    </Badge>
                  </Box>
                  <DatePicker
                    clearable
                    openTo='year'
                    views={['year', 'month', 'day']}
                    componentsProps={{
                      actionBar: {
                        actions: ['clear']
                      }
                    }}
                    label='Prej datës'
                    inputFormat='dd/MM/yyyy'
                    disabled
                    value={detailsDateFrom}
                    renderInput={(params) => <TextField {...params} variant='outlined' size='small' className={classes.textfield} />}
                  />
                  <DatePicker
                    clearable
                    openTo='year'
                    views={['year', 'month', 'day']}
                    componentsProps={{
                      actionBar: {
                        actions: ['clear']
                      }
                    }}
                    label='Deri më datën'
                    inputFormat='dd/MM/yyyy'
                    disabled
                    value={detailsDateTo}
                    renderInput={(params) => <TextField {...params} variant='outlined' size='small' className={classes.textfield} />}
                  />
                  <TextField
                    id='description-basic'
                    label='Përshkrimi'
                    variant='outlined'
                    // size="small"
                    fullWidth
                    className={classes.textfield}
                    disabled
                    value={detailsDescription}
                    style={{ width: '100%', margin: '20px 0' }}
                  />
                  <TextField
                    id='description-basic'
                    label='Përshkrimi i HR'
                    variant='outlined'
                    // size="small"
                    fullWidth
                    multiline
                    minRows={3}
                    placeholder='Përshkrimi i HR'
                    focused={detailsStatus != 0 && detailsStatus != 2}
                    className={classes.textfield}
                    disabled={detailsStatus === 0 || detailsStatus === 2}
                    value={detailsHRDescription ? detailsHRDescription : newHrDescription}
                    onChange={(e) => setNewHRDescription(e.target.value)}
                    style={{ width: '100%' }}
                  />
                  <FormControl focused={detailsStatus != 0 && detailsStatus != 2} fullWidth style={{ margin: '20px 0' }}>
                    <InputLabel>Statusi i ardhshëm</InputLabel>
                    <Select
                      fullWidth
                      value={detailsStatus === 0 || detailsStatus == 2 ? detailsStatus : newStatus}
                      disabled={detailsStatus === 0 || detailsStatus === 2}
                      label='Statusi i ardhshëm'
                      onChange={(e) => setNewStatus(e.target.value)}
                      className={classes.textfield}
                    >
                      <MenuItem value='0'>Refuzim</MenuItem>
                      <MenuItem value='2'>Aprovim</MenuItem>
                    </Select>
                  </FormControl>
                  {detailsStatus != 0 && detailsStatus != 2 && (
                    <MUIButton variant='contained' onClick={() => updateOneBusinessTrip()}>
                      Modifiko
                    </MUIButton>
                  )}
                </Modal.Body>
              </Modal>
            )}
          </Box>
        )}

        <PersonalBusinessTrip userID={userID} loggedInUser={loggedInUser} />
      </Box>
    </LocalizationProvider>
  )
}

export default BusinessTripComp
