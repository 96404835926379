import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from 'reactstrap'

const HikoFestQuestion = () => {
  const [showModal, setShowModal] = useState(false)
  const [showTransportModal, setShowTransportModal] = useState(false) // State for the second modal

  useEffect(() => {
    const checkDailyQuestion = async () => {
      try {
        const response = await axios.get('/hajkoFest/daily-question')
        setShowModal(response.data.showQuestion)
      } catch (error) {
        console.error('Error checking daily question', error)
      }
    }

    checkDailyQuestion()
  }, [])

  const handleResponse = async (userResponse) => {
    if (userResponse) {
      // Close the initial modal before opening the transport modal
      setShowModal(false)
      setShowTransportModal(true)
    } else {
      try {
        await axios.post('/hajkoFest/answer-question', {
          response: userResponse
        })
        setShowModal(false) // Close the initial modal after successful submission
      } catch (error) {
        console.error('Error submitting response', error)
      }
    }
  }

  const handleTransportSubmit = async (method) => {
    try {
      await axios.post('/hajkoFest/answer-question', {
        response: true,
        transport: method
      })
      setShowTransportModal(false) // Close the transport modal after submission
    } catch (error) {
      console.error('Error submitting transport response', error)
    }
  }

  return (
    <Row>
      {/* Initial Modal for HAJKO Fest Question */}
      <Modal className='modal-lg modal-dialog-centered' isOpen={!!showModal}>
        <ModalHeader>HAJKO FEEEST X Edition:</ModalHeader>
        <ModalBody style={{ display: 'flex', paddingBottom: '1px' }}>
          <h4>A do të merrni pjesë në HAJKO FEEEST?</h4>
        </ModalBody>
        <ModalFooter
          style={{
            justifyContent: 'center',
            borderTop: 'none',
            paddingBottom: '24px',
            paddingTop: '24px'
          }}
        >
          <div className='col-lg-6'>
            <Row className='justify-content-center align-self-center'>
              <Col xl={6} style={{ paddingLeft: '22px', paddingRight: '15px' }}>
                <Button
                  color='success'
                  className='btn-md btn-block btn-success'
                  onClick={() => handleResponse(true)}
                  style={{ marginBottom: '20px' }}
                >
                  <span
                    style={{
                      display: 'inline',
                      verticalAlign: 'middle'
                    }}
                  >
                    <i className='fa fa-check' /> Po
                  </span>
                </Button>
              </Col>
              <Col xl={6} style={{ paddingLeft: '22px', paddingRight: '15px' }}>
                <Button color='info' className='btn-md btn-block btn-info' onClick={() => handleResponse(false)}>
                  <span
                    style={{
                      display: 'inline',
                      verticalAlign: 'middle'
                    }}
                  >
                    <i className='fa fa-check' /> Jo
                  </span>
                </Button>
              </Col>
            </Row>
          </div>
        </ModalFooter>
      </Modal>

      {/* Second Modal for Transport Method Selection */}
      <Modal className='modal-lg modal-dialog-centered' isOpen={!!showTransportModal}>
        <ModalHeader>Transport Method:</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup tag='fieldset'>
              <legend>Si do të vini në HAJKO Fest?</legend>
              <FormGroup check>
                <Label check style={{ fontSize: '18px' }}>
                  <Input type='radio' name='transport' value='bus' onChange={() => handleTransportSubmit('bus')} /> Me autobus
                </Label>
              </FormGroup>
              <FormGroup check style={{ fontSize: '18px' }}>
                <Label check>
                  <Input type='radio' name='transport' value='car' onChange={() => handleTransportSubmit('car')} /> Me veturë
                </Label>
              </FormGroup>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
    </Row>
  )
}

export default HikoFestQuestion
