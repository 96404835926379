import LocationFilter from './LocationFilter'
import CustomDatePicker from './CustomDatePicker'
import SearchFilter from './SearchFilter'
import { Grid } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

const Filters = ({ setQuery, query }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid
        container
        spacing={3}
        sx={{
          fieldset: {
            borderBottom: '1px solid rgba(0,0,0,0.23)'
          }
        }}
      >
        <Grid item xs={12} lg={3}>
          <SearchFilter query={query} setQuery={setQuery} />
        </Grid>
        <Grid item xs={12} lg={3}>
          <LocationFilter query={query} setQuery={setQuery} />
        </Grid>
        <Grid item xs={12} lg={3}>
          <CustomDatePicker
            label='Prej datës'
            value={query.dateFrom}
            onChange={(newValue) => {
              setQuery((prev) => ({ ...prev, dateFrom: newValue }))
            }}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <CustomDatePicker
            label='Deri më'
            value={query.dateTo}
            onChange={(newValue) => {
              setQuery((prev) => ({ ...prev, dateTo: newValue }))
            }}
            minDate={query.dateFrom}
            maxDate={Date.now()}
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  )
}

export default Filters
