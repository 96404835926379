import axios from 'axios'
import pubsub from 'pubsub-js'
import { useEffect, useState } from 'react'

function VacancyList({ getUser, userId, reloadList }) {
  const [data, setData] = useState([])

  useEffect(() => {
    getData()
  }, [reloadList])

  const getData = () => {
    axios
      .get(`leaves/getExtra/${userId}`)
      .then((_data) => {
        setData(_data.data.data)
      })
      .catch((e) => {
        pubsub.publish('showNotification', {
          message: 'Gaim gjatë procesimit',
          type: 'error'
        })
      })
  }

  const deleteItem = (item) => {
    axios
      .delete(`leaves/deleteExtra/${item._id}`)
      .then((_data) => {
        getData()
        getUser(userId)
        pubsub.publish('showNotification', {
          message: 'Është larguar me sukses',
          type: 'success'
        })
      })
      .catch((e) => {
        pubsub.publish('showNotification', {
          message: 'Gaim gjatë procesimit',
          type: 'error'
        })
      })
  }

  return data.length ? (
    <>
      <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='address'>
        Ditët shtesë
      </label>
      <div className='col-xl-10 col-md-9 col-8'>
        <div className='vacancy-list'>
          <div className='vacancy-list-item'>
            <div className='vacancy-description'>Pershkrimi</div>
            <div className='vacancy-days'>Dite</div>
            <div className='vacancy-action'></div>
          </div>
          {data.map((item, index) => {
            return (
              <div className='vacancy-list-item' key={index}>
                <div className='vacancy-description'>{item.description}</div>
                <div className='vacancy-days'>{item.days}</div>
                <div className='vacancy-action'>
                  <button onClick={() => deleteItem(item)} className='btn btn-secondary' type='button'>
                    <em className='fa fa-trash-alt' />
                  </button>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  ) : (
    ''
  )
}

export default VacancyList
