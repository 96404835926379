import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'mobx-react'
import userStore from './stores/user.store'
import App from './App'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
const queryClient = new QueryClient()

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Provider userStore={userStore}>
      <App />
    </Provider>
  </QueryClientProvider>,
  document.getElementById('app')
)
