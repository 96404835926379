import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Box, CardActions, CardContent, Collapse, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useState } from 'react'

const ExpandMore = styled((props) => {
  const { expand, ...other } = props
  return <IconButton {...other} size='small' />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}))

const StaffingHistory = ({ history, title }) => {
  const [expanded, setExpanded] = useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }
  return (
    <Box>
      <CardActions sx={{ padding: '0px' }} disableSpacing>
        <strong>{title} </strong>
        <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label='show more'>
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <CardContent sx={{ padding: '0 15px', ':last-child': { paddingBottom: 0 } }}>
          {history.length > 0 ? (
            history.map((detail, index) => (
              <Box style={{ marginTop: '10px' }} key={index}>
                {detail.map((detail1, subIndex) => (
                  <Box key={subIndex}>
                    <strong style={{ marginRight: '10px' }}>{detail1.title}</strong>
                    {detail1.value}
                  </Box>
                ))}
                <hr />
              </Box>
            ))
          ) : (
            <span>Nuk ka te dhena</span>
          )}
        </CardContent>
      </Collapse>
    </Box>
  )
}
export default StaffingHistory
