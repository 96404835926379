import MUIDataTable from 'mui-datatables'
import TableHeader from './components/TableHeader'
import { leavesTableData, leavesTableSelectedUserData } from './utils/tableData'
import { leavesTableColumns, leavesTableOptions, leavesTableSelectedUserColumns } from './utils/tableUtils'

const LeavesDataTable = ({ thisObject }) => {
  const tableColumns = thisObject?.state?.selectedUserID
    ? leavesTableSelectedUserColumns(thisObject?.state)
    : leavesTableColumns(thisObject?.state)

  const newData = thisObject?.state?.dataTableData.map((oneLeave) =>
    thisObject?.state?.selectedUserID ? leavesTableSelectedUserData(oneLeave) : leavesTableData(oneLeave)
  )

  const tableOptions = leavesTableOptions(thisObject)

  return (
    <div className={thisObject?.state?.dataTableDataLoading ? 'whirl standard' : null}>
      <MUIDataTable columns={tableColumns} options={tableOptions} data={newData} title={<TableHeader thisObject={thisObject} />} />
    </div>
  )
}

export default LeavesDataTable
