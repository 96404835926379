import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  OutlinedInput,
  Checkbox,
  ListItemText
} from '@mui/material'
import { makeStyles } from '@material-ui/core'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import New_Staffing_Request_Modal from '../components/New_Staffing_Request_Modal/New_Staffing_Request_Modal'
import StaffingRequestDetails from '../components/StaffingRequestDetails/index'
import ContentWrapper from '../../../components/Layout/ContentWrapper'
import React from 'react'
import { ExpandMore } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  textfield: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.23)'
    },
    width: '200px'
  }
}))

const Staffing_Request = () => {
  const token = localStorage.getItem('token')
  const locations = ['Home Office', 'Prishtinë', 'Prishtinë HQ', 'Vushtrri', 'Ferizaj', 'Prizren']
  const request_types = ['Backfill', 'Ramp-Up']
  const classes = useStyles()
  const decodedToken = token ? jwtDecode(token) : null
  const hasAccessToAdd = [1, 2, 5, 6].includes(decodedToken?.user_level)
  const hasAccessToRecruit = [1, 5, 6].includes(decodedToken?.user_level)
  const limitCount = 8

  const [showModalForm, setShowModalForm] = useState(false)
  const [stats, setStats] = useState([])

  const [staffingRequests, setStaffingRequests] = useState({
    completed: [],
    uncompleted: []
  })

  const [fieldsData, setFieldsData] = useState({
    department: [],
    project: [],
    language: []
  })

  const [limit, setLimit] = useState({
    completedLimit: limitCount,
    completedCount: 0,
    uncompletedLimit: limitCount,
    uncompletedCount: 0
  })

  const [filterOptions, setFilterOptions] = useState({
    department: '',
    location: [],
    language: '',
    request_type: ''
  })

  const [selectedProjectId, setSelectedProjectId] = useState()

  const getLanguages = async () => {
    const res = await axios.get(`languages`)
    if (res.data.success) {
      setFieldsData((prev) => ({ ...prev, language: res.data.data }))
    } else {
      toast.error('Ndodhi një gabim tek gjuhët!')
    }
  }

  const getProjects = async () => {
    try {
      const res = await axios.get('/projects')
      if (res.data.success) {
        setFieldsData((prev) => ({ ...prev, project: res.data.data }))
      }
    } catch (error) {
      console.log(error, 'error')
    }
  }

  const getStats = async () => {
    try {
      const res = await axios.get('/staffingRequest/stats')
      if (res.data.success) {
        setStats(res.data.data)
      }
    } catch (error) {
      console.log(error, 'error')
    }
  }

  const getStaffingRequests = async (isCompleted, currentLimit) => {
    const staffingRequestField = isCompleted ? 'completed' : 'uncompleted'
    const countField = isCompleted ? 'completedCount' : 'uncompletedCount'

    try {
      const response = await axios.get(`/staffingRequest`, {
        params: { ...filterOptions, completed: isCompleted ? 'true' : 'false', limit: currentLimit }
      })
      if (response.data && response.data.data) {
        setStaffingRequests((prev) => ({ ...prev, [staffingRequestField]: response.data.data }))
        setLimit((prev) => ({ ...prev, [countField]: response.data.totalCount }))
      }
    } catch (error) {
      console.error('Failed to fetch staffing requests:', error)
      toast.error('Error fetching data')
    }
  }

  const handleFilterOptions = (key, value) => {
    if (key === 'department' && value === '') {
      setFilterOptions((prev) => ({ ...prev, [key]: value, project: '' }))
    } else {
      setFilterOptions((prev) => ({ ...prev, [key]: value }))
    }
  }

  const handleProjectChange = async (projectId) => {
    setSelectedProjectId(projectId)
    if (filterOptions.department) {
      setFilterOptions((prev) => ({ ...prev, department: '' }))
    }
    if (projectId) {
      try {
        const res = await axios.get(`/departments?projectId=${projectId}`)
        if (res.data.success) {
          setFieldsData((prev) => ({ ...prev, department: res.data.data }))
        }
      } catch (error) {
        console.error('Error fetching departments:', error)
        toast.error('Failed to fetch departments')
      }
    } else {
      setFieldsData((prev) => ({ ...prev, department: [] }))
    }
  }

  useEffect(() => {
    getProjects()
    getLanguages()
    getStats()
  }, [])

  useEffect(() => {
    setLimit({
      completedLimit: limitCount,
      completedCount: 0,
      uncompletedLimit: limitCount,
      uncompletedCount: 0
    })

    getStaffingRequests(true, limit.completedLimit)
    getStaffingRequests(false, limit.uncompletedLimit)
  }, [filterOptions])

  return (
    <ContentWrapper>
      <div className='content-heading'>
        <div>Kërkesat për personel</div>
      </div>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='h6' sx={{ marginRight: '5px' }}>
            Totali te pakompletuara:
          </Typography>
          {stats.map((oneLang) => {
            return (
              oneLang.total > 0 && (
                <Box key={oneLang.name} sx={{ display: 'flex', alignItems: 'center', marginRight: '5px' }}>
                  <Typography variant='h5'>{oneLang.language}</Typography>
                  <Typography variant='h5'>:</Typography>
                  <Typography variant='h5'>{oneLang.total},</Typography>
                </Box>
              )
            )
          })}
        </Box>

        {hasAccessToAdd && (
          <Button onClick={() => setShowModalForm(true)} variant='contained'>
            + Shto
          </Button>
        )}
      </Box>
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px', py: 3, flexWrap: 'wrap' }}>
          <FormControl size='small' sx={{ maxHeight: '40px' }}>
            <InputLabel>Projekti</InputLabel>
            <Select
              value={filterOptions.project}
              label='Projekti'
              onChange={(e) => handleProjectChange(e.target.value)}
              className={classes.textfield}
            >
              <MenuItem value=''>
                <em>Të gjitha</em>
              </MenuItem>
              {fieldsData.project.map((project, index) => {
                return (
                  <MenuItem key={`${index}-${project._id}`} value={project._id}>
                    {project.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          {!!selectedProjectId && (
            <FormControl size='small' sx={{ maxHeight: '40px' }}>
              <InputLabel>Departamenti</InputLabel>
              <Select
                value={filterOptions.department}
                label='Departamenti'
                onChange={(e) => handleFilterOptions('department', e.target.value)}
                className={classes.textfield}
              >
                <MenuItem value=''>
                  <em>Të gjitha</em>
                </MenuItem>
                {fieldsData.department.map((department, index) => {
                  return (
                    <MenuItem key={`${index}-${department._id}`} value={department._id}>
                      {department.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          )}
          <FormControl sx={{ width: '200px', textAlign: 'left' }}>
            <InputLabel size='small' id='office_location'>
              Lokacioni i punës
            </InputLabel>
            <Select
              size='small'
              multiple
              labelId='office_location'
              className={classes.textfield}
              value={filterOptions.location}
              onChange={(e) => handleFilterOptions('location', e.target.value)}
              input={<OutlinedInput label='Lokacioni i punës' />}
              renderValue={(selected) => locations.filter((item) => selected.includes(item)).join(', ')}
            >
              {locations.map((location) => (
                <MenuItem key={location} value={location}>
                  <Checkbox checked={filterOptions.location.includes(location)} />
                  <ListItemText primary={location} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl size='small' sx={{ maxHeight: '40px' }}>
            <InputLabel>Gjuha</InputLabel>
            <Select
              value={filterOptions.language}
              label='Gjuha'
              onChange={(e) => handleFilterOptions('language', e.target.value)}
              className={classes.textfield}
            >
              <MenuItem value=''>
                <em>Të gjitha</em>
              </MenuItem>
              {fieldsData.language.map((language, index) => {
                return (
                  <MenuItem key={`${index}-${language._id}`} value={language._id}>
                    {language.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <FormControl size='small' sx={{ maxHeight: '40px' }}>
            <InputLabel>Lloji i kërkesës</InputLabel>
            <Select
              value={filterOptions.request_type}
              label='LLoji i kerkeses'
              onChange={(e) => handleFilterOptions('request_type', e.target.value)}
              className={classes.textfield}
            >
              <MenuItem value=''>
                <em>Të gjitha</em>
              </MenuItem>
              {request_types.map((request_type, index) => {
                return (
                  <MenuItem key={`${index}-${request_type}`} value={request_type}>
                    {request_type}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ minHeight: '70vh' }}>
          {staffingRequests && staffingRequests?.uncompleted?.length > 0 ? (
            <Box>
              <Typography variant='h6' sx={{ py: 2 }}>
                Pa kompletuara
              </Typography>
              <Grid container spacing={5}>
                {staffingRequests?.uncompleted?.map((item, index) => (
                  <React.Fragment key={`${index}-${item.id}`}>
                    <StaffingRequestDetails item={item} hasAccessToRecruit={hasAccessToRecruit} getStaffingRequests={getStaffingRequests} />
                  </React.Fragment>
                ))}
              </Grid>
              <Box sx={{ display: 'flex', justifyContent: 'center', my: 3 }}>
                <Button
                  onClick={() => {
                    const newLimit = limit.uncompletedLimit + limitCount
                    setLimit((prev) => ({ ...prev, uncompletedLimit: newLimit }))
                    getStaffingRequests(false, newLimit)
                  }}
                  variant='outlined'
                  startIcon={<ExpandMore />}
                  disabled={limit.uncompletedCount <= limit.uncompletedLimit}
                >
                  Shfaq më shumë
                </Button>
              </Box>
            </Box>
          ) : (
            <Typography variant='h6' sx={{ py: 2, textAlign: 'center' }}>
              Nuk ka të dhëna per kërkesat e pakompletuara
            </Typography>
          )}

          <Divider sx={{ my: 4, borderColor: '#929292;', color: '#929292;', opacity: '0.2' }} />

          {staffingRequests && staffingRequests?.completed?.length > 0 ? (
            <Box sx={{ marginTop: '20px' }}>
              <Typography variant='h6' sx={{ py: 2 }}>
                Te kompletuara
              </Typography>
              <Grid container spacing={5}>
                {staffingRequests?.completed?.map((item, index) => (
                  <React.Fragment key={`${index}-${item.id}`}>
                    <StaffingRequestDetails
                      item={item}
                      hasAccessToRecruit={hasAccessToRecruit}
                      getStaffingRequests={getStaffingRequests}
                      completed
                    />
                  </React.Fragment>
                ))}
              </Grid>
              <Box sx={{ display: 'flex', justifyContent: 'center', my: 3 }}>
                <Button
                  onClick={() => {
                    const newLimit = limit.completedLimit + limitCount
                    setLimit((prev) => ({ ...prev, completedLimit: newLimit }))
                    getStaffingRequests(true, newLimit)
                  }}
                  variant='outlined'
                  startIcon={<ExpandMore />}
                  color='primary'
                  disabled={limit.completedCount <= limit.completedLimit}
                >
                  Shfaq më shumë
                </Button>
              </Box>
            </Box>
          ) : (
            <Typography variant='h6' sx={{ py: 2, textAlign: 'center' }}>
              Nuk ka të dhëna per kërkesat e kompletuara
            </Typography>
          )}
        </Box>
      </Box>

      {showModalForm && (
        <New_Staffing_Request_Modal
          getStaffingRequests={getStaffingRequests}
          closeModal={() => setShowModalForm(false)}
          languages={fieldsData.language}
        />
      )}
    </ContentWrapper>
  )
}

export default Staffing_Request
