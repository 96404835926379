const loginsHeaderText = (text) => <span style={{ fontWeight: 'bold' }}>{text}</span>
const columns = (showBtn) => [
  { name: 'name', label: 'Emri' },
  { name: 'email', label: 'Email' },
  { name: 'identity_card', label: 'Nr Personal' },
  { name: 'department', label: 'Department' },
  { name: 'office_location', label: 'Lokacioni i punes' },
  { name: 'exit_date', label: 'Data e daljes' },
  {
    name: 'partner_login',
    label: loginsHeaderText('Partner Login')
  },
  { name: 'intern_login', label: loginsHeaderText('Intern Login') },
  { name: 'badge', label: loginsHeaderText('Badge') },
  showBtn && { name: 'edit', label: 'Modifiko' }
];

export default columns;