import React from 'react'
import './calendarStyle.css'
import Scheduler, { SchedulerData, ViewTypes, DATE_FORMAT } from 'react-big-scheduler'

// import withDragDropContext from './withDnDContext'
import moment from 'moment'

moment.locale('sq')

export const getColor = (leave) => {
  switch (leave.status) {
    case 'Approved':
      return '#1e983b' //green
    case 'Partially approved':
      return '#FB9234'
    case 'Rejected':
      return '#ec2121'
    case 'Pending':
      return '#f3ca06'
    default:
      return 'gray'
  }
}

class Calendar extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.width != nextProps.width ||
      this.props.holidays != nextProps.holidays ||
      this.props.leaveTypes != nextProps.leaveTypes ||
      this.props.leaves != nextProps.leaves ||
      (nextProps.type &&
        moment(nextProps.dateTo).isValid() &&
        moment(nextProps.dateFrom).isValid() &&
        (this.props.type != nextProps.type || this.props.dateTo != nextProps.dateTo || this.props.dateFrom != nextProps.dateFrom))
    )
  }

  render() {
    return (
      <Scheduler
        movable={this.props.movable}
        creatable={this.props.creatable}
        schedulerData={this.props.schedulerData}
        eventItemClick={this.props.eventItemClick}
        prevClick={this.props.prevClick}
        nextClick={this.props.nextClick}
        onSelectDate={this.props.onSelectDate}
        onViewChange={this.props.onViewChange}
        viewEventClick={this.props.viewEventClick}
        viewEventText={this.props.viewEventText}
        viewEvent2Text={this.props.viewEvent2Text}
        viewEvent2Click={this.props.oviewEvent2Clickps2}
        updateEventStart={this.props.updateEventStart}
        updateEventEnd={this.props.updateEventEnd}
        moveEvent={this.props.moveEvent}
        newEvent={this.props.newEvent}
        toggleExpandFunc={this.props.toggleExpandFunc}
      />
    )
  }
}

export default Calendar
