import { GetApp } from '@material-ui/icons'
import { Button, CardContent, Grid, Typography } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import Locale from 'date-fns/locale/sq'
import { useState } from 'react'
import { downloadMozoOrders } from '../../API/endpoints'
import DatePickerFilter from '../filters/datePicker'

const Mozo_CSV = ({ title, getMine = false }) => {
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())

  const downloadOrdersEndpoint = `mobilezoneOrders/export?startDate=${startDate}&endDate=${endDate}&getMine=${getMine}`

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={Locale}>
      <Grid
        container
        sx={{
          fieldset: {
            borderBottom: '1px solid rgba(0,0,0,0.23)'
          },
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Grid item xs={12} md={2} lg={2}>
          {title && <Typography variant='h5'>{title}</Typography>}
        </Grid>
        <Grid item container xs={12} md={10} lg={10} justifyContent='end'>
          <Grid xs={12} md={4} lg={3}>
            <CardContent>
              <DatePickerFilter date={startDate} setDate={setStartDate} update={'startDate'} label='From' />
            </CardContent>
          </Grid>
          <Grid xs={12} md={4} lg={3}>
            <CardContent>
              <DatePickerFilter date={endDate} setDate={setEndDate} update={'endDate'} label='To' />
            </CardContent>
          </Grid>
          <Grid xs={12} md={2} lg={1}>
            <CardContent sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', height: '100%' }}>
              <Button
                size='small'
                variant='outlined'
                sx={{
                  padding: '3px 9px !important',
                  color: '#1976d2 !important',
                  borderRadius: '4px !important',
                  border: '1px solid #1976d2 !important',
                  transition: 'all .1s ease',
                  '&:hover': {
                    boxShadow: '2px 2px 2px  #ccc !important'
                  }
                }}
                endIcon={<GetApp />}
                onClick={() => downloadMozoOrders(downloadOrdersEndpoint, startDate, endDate)}
              >
                CSV
              </Button>
            </CardContent>
          </Grid>
        </Grid>
      </Grid>
    </LocalizationProvider>
  )
}
export default Mozo_CSV
