import { inject } from 'mobx-react'
import React from 'react'
import ContentWrapper from '../Layout/ContentWrapper'
import Orders from './components/Orders'

@inject('userStore')
class SalesUserOrders extends React.Component {
  constructor(props) {
    super(props)
    if (props.userStore.user_id) {
      this.state = {
        user: {
          user_id: props?.userStore.user_id,
          userLevel: props?.userStore.userLevel
        }
      }
    }
  }

  render() {
    return <ContentWrapper>{this.state.user && <Orders user={this.state.user} type='user' />}</ContentWrapper>
  }
}

export default SalesUserOrders
