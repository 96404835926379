import React from 'react'

const VersionBadge = () => {
  const deployVersion = process.env.REACT_APP_DEPLOY_VERSION || 'vunknown'
  const badgeStyle = {
    position: 'fixed',
    bottom: '0px',
    left: '5px',
    fontSize: '0.6rem',
    opacity: 0.5,
    color: '#555',
    zIndex: 1111111
  }

  return <div style={badgeStyle}>{deployVersion}</div>
}

export default VersionBadge
