import { Col, Input } from 'reactstrap'

const RemovedDays = ({ title, value }) => {
  return (
    <fieldset>
      <div className='form-group row align-items-center'>
        <label className='col-md-2 col-form-label'>{title}:</label>
        <Col md={8}>
          <Input disabled type='number' name='days' value={value} />
          <span className='invalid-feedback'>Fusha është e nevojshme</span>
        </Col>
      </div>
    </fieldset>
  )
}

export default RemovedDays
