import { inject } from 'mobx-react'
import React from 'react'
import ContentWrapper from '../Layout/ContentWrapper'
import BusinessTripComp from './BusinessTripComp'

@inject('userStore')
class BusinessTrip extends React.Component {
  constructor(props) {
    super(props)
    if (props.userStore.user_id) {
      this.state = {
        user: {
          user_id: props?.userStore.user_id,
          userLevel: props?.userStore.userLevel
        },
        userID: this.props.history.location.state?.data
      }
    }
  }

  render() {
    return (
      <ContentWrapper>
        <BusinessTripComp userID={this.state.userID} loggedInUser={this.state.user} />
      </ContentWrapper>
    )
  }
}

export default BusinessTrip
