import axios from 'axios'
import classnames from 'classnames'
import { inject } from 'mobx-react'
import moment from 'moment'
import pubsub from 'pubsub-js'
import React, { Fragment } from 'react'
import 'react-datetime/css/react-datetime.css'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Collapse,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from 'reactstrap'
import Swal from 'sweetalert2'
import DateTimeBoostrap from '../../components/Forms/DateTimeBootstrap'
import FormValidator from '../Forms/FormValidator.js'
import ContentWrapper from '../Layout/ContentWrapper'
import VacancyForm from './Edit/User/Vacancy/VacancyForm'
import VacancyList from './Edit/User/Vacancy/VacancyList'
import UserFolder from './UserFolder'
import UserNotes from './UserNotes'
import Warnings from './Warnings'
import { usersWorkHoursPercentage } from '../../utils/usersWorkHoursPercentage'
import showDownloadNewContractButton from '../../utils/showDownloadNewContractButton'

@inject('userStore')
class EditUsers extends React.Component {
  constructor() {
    super()
    this.state = {
      departmentTransferDate: false,
      vacancyModal: false,
      vacancyType: '',
      isEdit: false,
      activeTab: '1',
      dropdownOpen: false,
      modal: false,
      reportModal: false,
      resignForm: {
        resign_reason: null,
        resign_type: null,
        resign_day: null,
        last_day_work: null,
        leave_days: null,
        leaves_description: null,
        remaining_working_days: null,
        remaining_working_days_description: null,
        comment: null,
        is_active: false
      },
      resignFormError: {
        resign_day: false
      },
      reportForm: {
        last_date: null
      },
      isInBlacklist: null,
      blacklistModal: false,
      blackListForm: {
        blacklistReason: ''
      },
      formUser: {
        emergencyPhone: '',
        salary: '',
        skill_bonus: '',
        work_percentage: '',
        should_include_trust: true,
        investigationCertificate: false,
        healthCertificate: false,
        healthSafetyCertificate: false,
        email: '',
        password: '',
        first_name: '',
        last_name: '',
        alias_name: '',
        speeex_badge: '',
        user_level: '',
        employeer: 'Primar',
        office_location: '',
        is_active: true,
        city: '',
        address: '',
        bank: '',
        gender: '',
        nationality: '',
        phone: '',
        bankAccount: '',
        projectSelected: '',
        departmentSelected: [],
        start_date: null,
        teamLeaderSelected: '',
        managerSelected: '',
        functionSelected: '',
        activitySelected: '',
        workingStatusSelected: '',
        maritalStatusSelected: '',
        identity_card: '',
        other_exit_reason: '',
        personal_email: '',
        citizenship: '',
        trust_account: false,
        residence_permit: false,
        last_year_leaves: 0,
        extra_leaves: 0,
        accumulated_leaves: 0,
        transfers: null,
        is_project_manager: false,
        project_manager_selected: ''
      },
      tempSalary: 0,
      tempFirstCall: '',
      first_call_error: false,
      departmentError: null,
      activities: [],
      working_status: [],
      projects: [],
      departments: [],
      start_date: null,
      teamLeaders: [],
      managers: [],
      project_managers: [],
      languages: [],
      language: [],
      first_call: null,
      birthDate: null,
      funksionet: [],
      getData: [],
      cities: [],
      banks: [],
      getDepartments: [],
      exit_reason: [],
      exit_reason_selected: '',
      work_percentage_selected: '',
      setLanguages: {},
      exit_date: null,
      otherExitReason: false,
      value_exitReason: '',
      value_work_percentage: '',
      departmentRender: [],
      option_exitReason: [
        {
          label: 'Stafi nderpren kontraten',
          options: [
            {
              label: 'Do të shpërngulet jashtë vendit',
              value: 'Do të shpërngulet jashtë vendit'
            },
            {
              label: 'Nuk është i kënaqur me kushtet e punës',
              value: 'Nuk është i kënaqur me kushtet e punës'
            },
            { label: 'Nuk paraqitet në punë', value: 'Nuk paraqitet në punë' },
            { label: 'Për arsye shëndetësore', value: 'Për arsye shëndetësore' },
            { label: 'Avansim Akademik', value: 'Avansim Akademik' },
            { label: 'Tjeter...', value: 'Tjeter...' }
          ]
        },
        {
          label: 'Punedhenesi Speeex nderpren kontraten',
          options: [
            {
              label: 'Stafi largohet me kërkesë të partnerit',
              value: 'Stafi largohet me kërkesë të partnerit'
            },
            { label: 'Manipulim/mashtrim', value: 'Manipulim/mashtrim' },
            {
              label: 'Tejkalim i numrit të vërejtjeve',
              value: 'Tejkalim i numrit të vërejtjeve'
            },
            {
              label: 'Mos respektim i kontratës apo përgjegjësive te përcaktuar',
              value: 'Mos respektim i kontratës apo përgjegjësive te përcaktuar'
            },
            {
              label: 'Pakënaqësi me menaxhmentin',
              value: 'Pakënaqësi me menaxhmentin'
            },
            {
              label: 'Përformancë e dobët',
              value: 'Përformancë e dobët'
            },
            {
              label: 'Nivel i ulët i gjuhës',
              value: 'Nivel i ulët i gjuhës'
            },
            {
              label: 'Mbyllje e projektit',
              value: 'Mbyllje e projektit'
            }
          ]
        }
      ],
      shouldShowNewContractBtn: false
    }

    this.__exitReasonSelected = this._exitReasonSelected.bind(this)
    this.changeLanguage = this.changeLanguage.bind(this)
    this.toggle = this.toggle.bind(this)
    this.vacancyToggle = this.vacancyToggle.bind(this)
    this.toggleSwal = this.toggleSwal.bind(this)
    this.reportToggle = this.reportToggle.bind(this)

    this.blacklistModalToggle = this.blacklistModalToggle.bind(this)
    this.addToBlacklist = this.addToBlacklist.bind(this)

    this.onResignSubmit = this.onResignSubmit.bind(this)
    this.onReportSubmit = this.onReportSubmit.bind(this)
    this.getUser = this.getUser.bind(this)
  }

  componentDidMount(prevProps, prevState) {
    if (this.state.formUser.is_active === true && this.state.getData.is_active === false && prevState.tempFirstCall !== '') {
      this.setState({ tempFirstCall: '' })
    }

    this.axiosCancelSource = axios.CancelToken.source()
    axios
      .get(`resigns/hr/${this.props.match.params.idUser}`, {
        cancelToken: this.axiosCancelSource.token
      })
      .then((res) => {
        this.setState({
          resignForm: {
            ...this.state.resignForm,
            is_active: res.data.data.is_active
          }
        })
      })
      .catch((error) => {})
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    })
    this.setState({
      resignForm: {
        ...this.state.resignForm,
        resign_reason: null,
        resign_type: null,
        resign_day: null,
        last_day_work: null,
        leave_days: null,
        leaves_description: null,
        comment: null
      }
    })
  }

  vacancyToggle() {
    this.setState({
      vacancyModal: !this.state.vacancyModal
    })
  }

  blacklistModalToggle() {
    this.setState({ blacklistModal: !this.state.blacklistModal })
  }

  addToBlacklist = (e) => {
    e.preventDefault()
    const form = e.target
    const inputs = [...form.elements].filter((i) => {
      if (i.name) {
        return ['INPUT', 'SELECT', 'TEXTAREA', 'RANGE'].includes(i.nodeName)
      } else {
        return false
      }
    })

    const { errors, hasError } = FormValidator.bulkValidate(inputs)

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      }
    })

    if (hasError) {
      pubsub.publish('showNotification', {
        message: 'Plotësoni të gjitha fushat!',
        type: 'error'
      })
    }

    if (!hasError) {
      const newToBlackList = {
        userId: this.props.match.params.idUser,
        description: this.state.blackListForm.blacklistReason
      }

      axios
        .post('blacklist/fromHRMProfile', newToBlackList)
        .then(async (res) => {
          if (res.data.status) {
            pubsub.publish('showNotification', {
              message: res.data.error,
              type: 'success'
            })
            await this.getUser(this.props.match.params.idUser)
          } else {
            pubsub.publish('showNotification', {
              message: res.data.error,
              type: 'error'
            })
          }
        })
        .catch((err) => {
          console.log('error', err)
        })

      this.blacklistModalToggle()

      this.setState({
        blackListForm: {
          ...this.state.blackListForm
        }
      })
    }
  }

  _onChangeReportMissingDate = (date) => {
    if (typeof date == 'object') {
      this.setState({
        reportForm: {
          ...this.state.reportForm,
          last_date: date
        }
      })
    }
  }

  _onChangeReportMissingBlur = (date) => {
    this.setState({
      reportForm: {
        ...this.state.reportForm,
        last_date: moment(date, 'DD/MM/YYYY')
      }
    })
  }

  _onChangeResignDay = (date) => {
    if (typeof date == 'object') {
      this.setState({
        resignForm: {
          ...this.state.resignForm,
          resign_day: date
        }
      })
      this.setState({
        resignFormError: {
          resign_day: false
        }
      })
    }
  }

  _onChangeLastDayWork = (date) => {
    if (typeof date == 'object') {
      this.setState({
        resignForm: {
          ...this.state.resignForm,
          last_day_work: date
        }
      })
    }
  }

  reportToggle() {
    this.setState({
      reportModal: !this.state.reportModal
    })
  }

  toggleSwal() {
    Swal.fire({
      title: 'A jeni i sigurt?',
      type: 'warning',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
      showCancelButton: true
    }).then((result) => {
      if (result.value) {
        axios
          .patch(`resigns/hr/${this.props.match.params.idUser}`, {
            cancelToken: this.axiosCancelSource.token
          })
          .then((res) => {
            pubsub.publish('showNotification', {
              message: 'Terhqeja u largua',
              type: 'success'
            })
            this.setState({
              resignForm: {
                ...this.state.resignForm,
                is_active: false
              }
            })
          })
          .catch((error) => {})
      }
    })
  }

  onResignSubmit = (e) => {
    const {
      resign_reason,
      resign_type,
      resign_day,
      is_active,
      last_day_work,
      leave_days,
      leaves_description,
      remaining_working_days,
      remaining_working_days_description,
      comment
    } = this.state.resignForm

    e.preventDefault()
    const form = e.target
    const inputs = [...form.elements].filter((i) => {
      if (i.name) {
        return ['INPUT', 'SELECT', 'TEXTAREA', 'RANGE'].includes(i.nodeName)
      } else {
        return false
      }
    })

    const { errors, hasError } = FormValidator.bulkValidate(inputs)

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      }
    })

    if (!resign_day) {
      this.setState({
        resignFormError: {
          resign_day: true
        }
      })
    }

    if (!hasError && resign_day) {
      const newResign = {
        resign_reason,
        resign_type,
        resign_day,
        last_day_work,
        leave_days,
        leaves_description,
        comment,
        remaining_working_days,
        remaining_working_days_description,
        user_id: this.props.match.params.idUser,
        is_active
      }

      axios
        .post('resigns/hr', newResign)
        .then((res) => {
          if (res.data.success) {
            pubsub.publish('showNotification', {
              message: 'Të dhënat janë ruajtur!',
              type: 'success'
            })
            this.setState({
              resignForm: {
                ...this.state.resignForm,
                resign_reason: null,
                resign_type: null,
                resign_day: null,
                last_day_work: null,
                leave_days: null,
                leaves_description: null,
                comment: null
              }
            })
          } else {
            pubsub.publish('showNotification', {
              message: res.data.message,
              type: 'error'
            })
          }
        })
        .catch((err) => {
          console.log('error', err)
        })

      this.toggle()

      this.setState({
        resignForm: {
          ...this.state.resignForm,
          is_active: !this.state.is_active
        }
      })
    }
  }

  changeLanguage = async (index) => {
    await this.setState({
      languages: this.state.languages.map((item, mindex) =>
        index == mindex
          ? {
              name: item.name,
              checked: !item.checked,
              _id: item._id,
              code: item.code,
              description: item.description,
              level: item.level
            }
          : {
              name: item.name,
              checked: item.checked,
              _id: item._id,
              code: item.code,
              description: item.description,
              level: item.level
            }
      )
    })
  }

  onReportSubmit = (e) => {
    const { last_date } = this.state.reportForm

    e.preventDefault()
    const form = e.target
    const inputs = [...form.elements].filter((i) => {
      if (i.name) {
        return ['INPUT', 'SELECT', 'TEXTAREA', 'RANGE'].includes(i.nodeName)
      } else {
        return false
      }
    })

    const { errors, hasError } = FormValidator.bulkValidate(inputs)

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      }
    })

    if (hasError) {
      pubsub.publish('showNotification', {
        message: 'Plotësoni të gjitha fushat!',
        type: 'error'
      })
    }

    if (!hasError) {
      const newReport = {
        last_date,
        user_id: this.props.match.params.idUser,
        logged_user_id: this.props.userStore.user_id
      }

      axios
        .post('resigns/report', newReport)
        .then((res) => {
          if (res.data.success) {
            this.props.history.push('/resigns')
            pubsub.publish('showNotification', {
              message: 'Të dhënat janë ruajtur!',
              type: 'success'
            })
          } else {
            pubsub.publish('showNotification', {
              message: res.data.message,
              type: 'error'
            })
          }
        })
        .catch((err) => {
          console.log('error', err)
        })

      this.reportToggle()
    }
  }

  validateLanguages = (e) => {
    this.setState({
      setLanguages: {
        ...this.state.setLanguages,
        [e.target.name]: e.target.value
      }
    })

    this.state.languages.map((element) => {
      if (element.name == e.target.name) {
        element['level'] = e.target.value
      }
    })
  }

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  }
  dropdownToggle = () =>
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    })

  _onExitDateChange = (date) => {
    if (typeof date == 'object') {
      this.setState({ exit_date: date })
    }
  }

  _onExitDateBlur = (date) => {
    this.setState({ exit_date: moment(date, 'DD/MM/YYYY') })
  }

  _onBirthDateChange = (date) => {
    if (typeof date == 'object') {
      this.setState({ birthDate: date })
    }
  }

  _onBirthDateBlur = (date) => {
    this.setState({ birthDate: moment(date, 'DD/MM/YYYY') })
  }

  _onStartDateChange = (date) => {
    if (typeof date == 'object') {
      this.setState({ start_date: date })
    }
  }

  _onStartDateBlur = (date) => {
    this.setState({ start_date: moment(date, 'DD/MM/YYYY') })
  }

  _onFirstCallChange = (date) => {
    if (typeof date === 'object') {
      const shouldUseTempFirstCall = this.state.formUser.is_active === true && this.state.getData.is_active === false
      const isUpdating = !!this.props.match.params.idUser

      this.setState({
        first_call: isUpdating && shouldUseTempFirstCall ? this.state.first_call : date,
        tempFirstCall: isUpdating && shouldUseTempFirstCall ? date : this.state.tempFirstCall,
        first_call_error: !date
      })
    }
  }

  _onFirstCallBlur = (date) => {
    this.setState({ first_call: moment(date, 'DD/MM/YYYY') })
  }

  cancelGoBack() {
    this.props.history.goBack()
  }

  handleExitReason = async (data) => {
    await this.setState({
      value_exitReason: data,
      exit_reason_selected: data.value
    })

    if (data.value == 'Tjeter...') {
      this.setState({
        otherExitReason: true
      })
    } else {
      this.setState({
        otherExitReason: false
      })
    }
  }
  _exitReasonSelected(value) {
    this.state.option_exitReason.map((elem) => {
      elem.options.map((option) => {
        if (value == option.value) {
          if (option.value == 'Tjeter...') {
            this.setState({ otherExitReason: true })
          } else {
            this.setState({ otherExitReason: false })
          }
          this.setState({
            value_exitReason: option
          })
        }
      })
    })
  }

  validateOnChange = async (event) => {
    const input = event.target
    const form = input.form
    const value = input.type === 'checkbox' ? input.checked : input.value
    const result = FormValidator.validate(input)

    if (this.state.formUser.is_active && this.state.getData.is_active === false) {
      this.setState({ tempSalary: value })
    }

    if (input.name === 'user_level' && value !== 3) {
      await this.setState({
        [form.name]: {
          ...this.state[form.name],
          is_project_manager: false
        }
      })
    }
    await this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    })
    if (input.name == 'projectSelected') {
      await axios
        .get('departments', {
          params: {
            projectId: this.state.formUser.projectSelected
          }
        })
        .then((res) => {
          var getDepartments = []
          res.data.data.forEach((department) => {
            getDepartments.push({
              value: department._id,
              label: department.name
            })
          })
          this.setState({
            formUser: {
              ...this.state.formUser,
              departmentSelected: []
            }
          })
          this.setState({
            departments: getDepartments
          })
        })
        .catch((err) => {
          console.log('error', err)
        })
    } else {
    }
  }

  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    )
  }

  onSubmit = async (e) => {
    const {
      email,
      first_name,
      last_name,
      alias_name,
      speeex_badge,
      activitySelected,
      workingStatusSelected,
      maritalStatusSelected,
      user_level,
      employeer,
      office_location,
      is_active,
      city,
      address,
      bank,
      gender,
      nationality,
      phone,
      bankAccount,
      projectSelected,
      departmentSelected,
      teamLeaderSelected,
      managerSelected,
      functionSelected,
      identity_card,
      salary,
      skill_bonus,
      work_percentage,
      healthCertificate,
      healthSafetyCertificate,
      investigationCertificate,
      emergencyPhone,
      should_include_trust,
      personal_email,
      citizenship,
      trust_account,
      residence_permit,
      is_project_manager,
      project_manager_selected,
      accumulated_leaves
    } = this.state.formUser

    const { first_call, birthDate, start_date, exit_date } = this.state

    e.preventDefault()
    const ID = this.props.match.params.idUser
    const form = e.target
    const inputs = [...form.elements].filter((i) => {
      if (i.name) {
        return ['INPUT', 'SELECT'].includes(i.nodeName)
      } else {
        return false
      }
    })
    const isUpdating = !!ID
    const shouldUseTempFirstCall = this.state.formUser.is_active === true && this.state.getData.is_active === false

    const requiredFirstCall = isUpdating && shouldUseTempFirstCall ? this.state.tempFirstCall : this.state.first_call

    if (!requiredFirstCall) {
      this.setState({ first_call_error: true })
      pubsub.publish('showNotification', {
        message: 'Dita e parë është e detyrueshme! Ju lutem vendosni një datë.',
        type: 'error'
      })
      return
    }

    if (!moment(requiredFirstCall).isValid()) {
      this.setState({ first_call_error: true })
      pubsub.publish('showNotification', {
        message: 'Data e parë është e pavlefshme! Ju lutem zgjidhni një datë të saktë.',
        type: 'error'
      })
      return
    }

    const { errors, hasError } = FormValidator.bulkValidate(inputs)

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      }
    })

    var setLanguages = []

    this.state.languages.forEach((element) => {
      if (element.checked == true && !element.level == '') {
        setLanguages.push({ level: element.level, _id: element._id })
      }
    })

    if (hasError) {
      pubsub.publish('showNotification', {
        message: 'Plotësoni të gjitha fushat!',
        type: 'error'
      })
    }

    if (!hasError) {
      const level = user_level
      const body = {
        email: email,
        first_name: first_name,
        last_name: last_name,
        alias: alias_name,
        speeex_badge: speeex_badge,
        languages: setLanguages,
        user_level: user_level,
        employeer: employeer,
        office_location: office_location,
        function: functionSelected,
        is_active: is_active,
        identity_card: identity_card,
        accumulated_leaves: accumulated_leaves,
        base_salary: salary,
        skill_bonus: skill_bonus,
        work_percentage: work_percentage,
        health_certificate: healthCertificate,
        health_safety_certificate: healthSafetyCertificate,
        court_certificate: investigationCertificate,
        emergency_contact: emergencyPhone,
        phone_no: !phone == null || !phone == '' ? phone + '' : undefined,
        address: !address == null || !address == '' ? address : undefined,
        bank_account_no: !bankAccount == null || !bankAccount == '' ? bankAccount : undefined,
        bank: !bank == null || !bank == '' ? bank : undefined,
        gender: !gender == null || !gender == '' ? gender : undefined,
        project: !projectSelected == null || !projectSelected == '' ? projectSelected : undefined,
        city: !city == null || !city == '' ? city : undefined,
        nationality: !nationality == null || !nationality == '' ? nationality : undefined,
        first_call: shouldUseTempFirstCall
          ? this.state.tempFirstCall
            ? moment(this.state.tempFirstCall).format('YYYY-MM-DD')
            : undefined
          : this.state.first_call
            ? moment(this.state.first_call).format('YYYY-MM-DD')
            : undefined,
        activity: !activitySelected == null || !activitySelected == '' ? activitySelected : undefined,
        work: !workingStatusSelected == null || !workingStatusSelected == '' ? workingStatusSelected : undefined,
        marital_status: !maritalStatusSelected == null || !maritalStatusSelected == '' ? maritalStatusSelected : undefined,
        teamleader: level == 4 || 8 ? teamLeaderSelected : undefined,
        manager: managerSelected ? managerSelected : undefined,
        birthdate: !birthDate == null || !birthDate == '' ? moment(birthDate).format('YYYY-MM-DD') : undefined,
        start_date: !start_date == null || !start_date == '' ? moment(start_date).format('YYYY-MM-DD') : undefined,
        should_include_trust: should_include_trust,
        personal_email: personal_email,
        citizenship: citizenship,
        trust_account: trust_account,
        residence_permit: residence_permit,
        is_project_manager: is_project_manager,
        has_project_manager: project_manager_selected ? project_manager_selected : undefined,
        ...(!is_active && exit_date && { exit_date: exit_date }),
        ...(!is_active && this.state.exit_reason_selected && { exit_reason: this.state.exit_reason_selected }),
        ...(!is_active &&
          this.state.exit_reason_selected &&
          this.state.formUser.other_exit_reason && { other_exit_reason: this.state.formUser.other_exit_reason })
      }

      if (this.state.formUser.departmentSelected.length == 0 && level != 1) {
        await this.setState({
          departmentError: true
        })
      } else {
        await this.setState({
          departmentError: false
        })
      }

      if (departmentSelected.length > 0) {
        var targetDepartment = []
        this.state.formUser.departmentSelected.map((target) => {
          targetDepartment.push(target.value)
          body.department = targetDepartment
        })
      }

      if (departmentSelected.length == 0 && level == 1) {
        var targetDepartment = []
        body.department = targetDepartment
      }

      if (this.state.formUser.teamLeaderSelected == '') {
        delete body.teamleader
      }

      if (this.state.formUser.project_manager_selected == '') {
        delete body.project_manager_selected
      }

      if (ID) {
        if (!this.state.departmentError) {
          axios
            .put(`users/${ID}`, body)
            .catch(function (error) {
              if (!error.response.data.success) {
                pubsub.publish('showNotification', {
                  message: error.response.data.message,
                  type: 'error'
                })
              }
            })
            .then(async (res) => {
              if (res.data.success) {
                this.setState({
                  getData: res.data.data
                })

                this.setState({
                  formUser: {
                    ...this.state.formUser,
                    transfers: res.data.data.transfers
                  }
                })

                pubsub.publish('showNotification', {
                  message: 'Të dhënat janë ruajtur!',
                  type: 'success'
                })
                await this.getUser(ID)
              } else {
                pubsub.publish('showNotification', {
                  message: res.data.message,
                  type: 'error'
                })
              }
            })
            .catch((err) => {
              pubsub.publish('showNotification', {
                message: 'Gabim gjatë ruajtjes së të dhënave',
                type: 'error'
              })
            })
          await this.getUser(ID)
        } else {
          pubsub.publish('showNotification', {
            message: 'Gabim gjatë ruajtjes së të dhënave',
            type: 'error'
          })
        }
      } else {
        if (!this.state.departmentError) {
          body.password = this.state.formUser.password
          axios
            .post('users', body)
            .then(async (res) => {
              if (res.data.success) {
                pubsub.publish('showNotification', {
                  message: 'Të dhënat janë ruajtur!',
                  type: 'success'
                })
                this.props.history.push('/users')
              } else {
                pubsub.publish('showNotification', {
                  message: res.data.message,
                  type: 'error'
                })
              }
            })
            .catch((err) => {
              pubsub.publish('showNotification', {
                message: 'Gabim gjatë ruajtjes të të dhënave!',
                type: 'error'
              })
            })
        } else {
          pubsub.publish('showNotification', {
            message: 'Plotësoni të gjitha fushat!',
            type: 'error'
          })
        }
      }
    }
  }

  projectSelected(projectSelected) {
    axios
      .get(`departments`, {
        params: {
          projectId: projectSelected
        }
      })
      .then((response) => {
        if (response.data.success) {
          var getDepartments = []
          response.data.data.forEach((department) => {
            getDepartments.push({
              value: department._id,
              label: department.name
            })
          })
          this.setState({
            departments: getDepartments
          })
          this.state.getDepartments.map((getDepartment) => {
            const getDepartmentID = getDepartment._id
            this.state.departments.forEach(async (departments) => {
              const departmentID = departments.value
              if (departmentID == getDepartmentID) {
                await this.setState({
                  formUser: {
                    ...this.state.formUser,
                    departmentSelected: [
                      ...this.state.formUser.departmentSelected.filter((department) => department.value !== departments.value),
                      ,
                      { value: departments.value, label: departments.label }
                    ]
                  }
                })
              }
            })
          })
        }
      })
      .catch((error) => {})
  }

  getUser = async (ID) => {
    await axios
      .get(`users/${ID}`)
      .then(async (response) => {
        const res = response.data.data
        const showNewContractBtn = showDownloadNewContractButton(res.first_call)
        this.setState({
          shouldShowNewContractBtn: showNewContractBtn
        })
        await this.setState({
          formUser: {
            ...this.state.formUser,
            email: res.email && res.email,
            first_name: res.first_name && res.first_name,
            last_name: res.last_name && res.last_name,
            alias_name: res.alias && res.alias,
            speeex_badge: res.speeex_badge && res.speeex_badge,
            activitySelected: res.activity && res.activity._id,
            workingStatusSelected: res.work && res.work._id,
            maritalStatusSelected: res.marital_status && res.marital_status,
            user_level: res.user_level && res.user_level,
            employeer: res.employeer && res.employeer,
            office_location: res.office_location && res.office_location,
            is_active: res.is_active && res.is_active,
            phone: res.phone_no && res.phone_no,
            gender: res.gender && res.gender,
            nationality: res.nationality && res.nationality,
            bankAccount: res.bank_account_no && res.bank_account_no,
            address: res.address && res.address,
            projectSelected: res.project && res.project._id,
            functionSelected: res.function && res.function._id,
            city: res.city && res.city._id,
            teamLeaderSelected: res.teamleader && res.teamleader._id,
            managerSelected: res.manager && res.manager._id,
            bank: res.bank && res.bank._id,
            identity_card: res.identity_card && res.identity_card,
            exit_reason: res.exit_reason && res.exit_reason ? res.exit_reason : '',
            emergencyPhone: res.emergency_contact && res.emergency_contact,
            healthCertificate: res.health_certificate && res.health_certificate,
            healthSafetyCertificate: res.health_safety_certificate && res.health_safety_certificate,
            investigationCertificate: res.court_certificate && res.court_certificate,
            salary: res.base_salary && res.base_salary,
            skill_bonus: res.skill_bonus && res.skill_bonus,
            work_percentage: res.work_percentage && res.work_percentage,

            other_exit_reason: res.other_exit_reason && res.other_exit_reason,
            should_include_trust: res.should_include_trust,

            personal_email: res.personal_email && res.personal_email,
            citizenship: res.citizenship && res.citizenship,
            trust_account: res.trust_account && res.trust_account,
            residence_permit: res.residence_permit && res.residence_permit,

            last_year_leaves: res.last_year_leaves && res.last_year_leaves,
            extra_leaves: res.extra_leaves && res.extra_leaves,
            accumulated_leaves: res.accumulated_leaves && res.accumulated_leaves,
            transfers: res.transfers && res.transfers,
            is_project_manager: res.is_project_manager && res.is_project_manager,
            project_manager_selected: res.has_project_manager && res.has_project_manager._id
          },
          getLanguages: res.languages && res.languages,
          first_call: res.first_call && moment(res.first_call),
          birthDate: res.birthdate && moment(res.birthdate),
          getData: res,
          getDepartments: res.department && res.department,
          exit_date: res.exit_date && res.exit_date != null && res.exit_date != undefined ? moment(res.exit_date) : null,
          exit_reason_selected: res.exit_reason && res.exit_reason ? res.exit_reason : ''
        })
        this.projectSelected(this.state.formUser.projectSelected)
        await this._exitReasonSelected(res.exit_reason)

        await axios
          .get(`blacklist/fromHRMProfile`, {
            params: { idNumber: this.state.formUser.identity_card }
          })
          .then((res) => {
            if (res.data.data) {
              this.setState({ isInBlacklist: res.data.data })
            }
          })
          .catch((err) => console.log('error', err))
      })
      .catch((error) => {})
  }
  handleAnuloTransferClick = () => {
    this.getUser(this.props.match.params.idUser)
  }

  async UNSAFE_componentWillMount() {
    const ID = this.props.match.params.idUser
    if (ID) {
      this.setState({ isEdit: true })
    }
    if (ID) {
      await this.getUser(ID)
    }

    axios
      .get(`projects`)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            projects: response.data.data
          })
        }
      })
      .catch((error) => {})

    axios
      .get(`roles`)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            funksionet: response.data.data
          })
        }
      })
      .catch((error) => {})

    axios
      .get(`users/teamleaders`)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            teamLeaders: response.data.data
          })
        }
      })
      .catch((error) => {})

    axios
      .get(`users/managers`)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            managers: response.data.data
          })
        }
      })
      .catch((error) => {})

    axios
      .get(`users/projectManagers`)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            project_managers: response.data.data
          })
        }
      })
      .catch((error) => {})

    axios
      .get(`cities`)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            cities: response.data.data
          })
        }
      })
      .catch((error) => {})

    axios
      .get(`banks`)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            banks: response.data.data
          })
        }
      })
      .catch((error) => {})

    axios
      .get(`activities`)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            activities: response.data.data
          })
        }
      })
      .catch((error) => {})

    axios
      .get(`works`)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            working_status: response.data.data
          })
        }
      })
      .catch((error) => {})

    axios
      .get(`departments`)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            departmentRender: response.data.data
          })
        }
      })
      .catch((error) => {})

    axios.get(`languages`).then((response) => {
      if (response.data.success) {
        const languages = response.data.data.map((object) => ({
          ...object,
          checked: false,
          level: ''
        }))

        const setLanguages = {}
        this.state.getLanguages?.forEach((element) => {
          const language = languages.find((lang) => lang?._id === element?._id?._id)
          if (language) {
            language.checked = true
            language.level = element.level
            setLanguages[language.name] = element.level
          }
        })

        this.setState({
          languages,
          setLanguages
        })
      }
    })
  }

  handleChangeDepartment = (department) => {
    this.setState({
      formUser: {
        ...this.state.formUser,
        departmentSelected: department
      },
      departmentTransferDate: true
    })
  }

  _incrementBonus = (type) => {
    const { skill_bonus } = this.state.formUser
    switch (type) {
      case '+':
        if (typeof skill_bonus == 'string') {
          if (skill_bonus == '') {
            this.setState({
              formUser: {
                ...this.state.formUser,
                skill_bonus: 1
              }
            })
          } else {
            this.setState({
              formUser: {
                ...this.state.formUser,
                skill_bonus: parseInt(skill_bonus) + 1
              }
            })
          }
        } else {
          this.setState({
            formUser: {
              ...this.state.formUser,
              skill_bonus: skill_bonus + 1
            }
          })
        }
        break
      case '-':
        this.setState({
          formUser: {
            ...this.state.formUser,
            skill_bonus: skill_bonus - 1
          }
        })
    }
  }

  _incrementSalary = (type) => {
    const { salary } = this.state.formUser
    switch (type) {
      case '+':
        if (typeof salary == 'string') {
          if (salary == '') {
            this.setState({
              formUser: {
                ...this.state.formUser,
                salary: 1
              }
            })
          } else {
            this.setState({
              formUser: {
                ...this.state.formUser,
                salary: parseInt(salary) + 1
              }
            })
          }
        } else {
          this.setState({
            formUser: {
              ...this.state.formUser,
              salary: salary + 1
            }
          })
        }
        break
      case '-':
        this.setState({
          formUser: {
            ...this.state.formUser,
            salary: salary - 1
          }
        })
    }
  }

  render() {
    return (
      <ContentWrapper>
        <Modal isOpen={this.state.vacancyModal} toggle={this.vacancyToggle} className='resign_modal'>
          <ModalHeader toggle={this.vacancyToggle}>Arsya e Pushimit</ModalHeader>
          <ModalBody>
            <VacancyForm getUser={this.getUser} reloadData={this.vacancyToggle} userId={this.props.match.params.idUser} />
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.modal} toggle={this.toggle} className='resign_modal'>
          <ModalHeader toggle={this.toggle}>Arsya e dorëheqjes</ModalHeader>
          <ModalBody>
            <form onSubmit={this.onResignSubmit} name='resignForm'>
              <Label for='resign_reason'>Ju lutem shenoni arsyen e doreheqjes:</Label>
              <Input type='textarea' name='resign_reason' onChange={this.validateOnChange} value={this.state.resignForm.resign_reason} />
              <span className='invalid-feedback'>Fusha është e nevojshme</span>
              <div className='mt-2'>
                <Label>Lloji i doreheqjen:</Label>
                <Input
                  type='select'
                  name='resign_type'
                  onChange={this.validateOnChange}
                  value={this.state.resignForm.resign_type || ''}
                  invalid={this.hasError('resignForm', 'resign_type', 'required')}
                  data-validate='["required"]'
                >
                  <option key={'0'} value=''>
                    Zgjedhni llojin e Doreheqjes
                  </option>
                  <option key='1' value='involuntary'>
                    Jo Vullnetare
                  </option>
                  <option key='2' value='voluntary'>
                    Vullnetare
                  </option>
                </Input>
                <span className='invalid-feedback'>Fusha është e nevojshme</span>
              </div>
              <div className='mt-3'>
                <Label for='resign_day'>Dita e Dorëheqjes:</Label>
                <DateTimeBoostrap
                  utc={true}
                  dateFormat={'DD/MM/YYYY'}
                  timeFormat={false}
                  closeOnSelect
                  value={this.state.resignForm.resign_day}
                  onChange={this._onChangeResignDay}
                  inputProps={{
                    className: `form-cont ${this.state.resignFormError.resign_day ? 'border-danger' : ''} `,
                    name: 'resign_day'
                  }}
                />
                {this.state.resignFormError.resign_day && (
                  <span style={{ color: 'red', fontSize: '11px' }} className='danger'>
                    Fusha është e nevojshme
                  </span>
                )}
              </div>
              <div className='mt-3'>
                <Label>Dita e fundit si punetor:</Label>
                <DateTimeBoostrap
                  utc={true}
                  dateFormat={'DD/MM/YYYY'}
                  timeFormat={false}
                  value={this.state.resignForm.last_day_work}
                  onChange={this._onChangeLastDayWork}
                  closeOnSelect
                />
              </div>
              <Row className='mt-3'>
                <Col md={5}>
                  <Label for='descriptio_leaves'>Ditët e mbetura të pushimit:</Label>
                  <Input
                    type='number'
                    name='leave_days'
                    onChange={this.validateOnChange}
                    className='height-2'
                    value={this.state.resignForm.leave_days}
                    invalid={this.hasError('resignForm', 'leave_days', 'required')}
                    data-validate='["required"]'
                  />
                  <span className='invalid-feedback'>Fusha është e nevojshme</span>
                </Col>
                <Col md={7}>
                  <Label for='descriptio_leaves'>Sqarim per ditët e mbetura të pushimit:</Label>
                  <Input
                    type='text'
                    name='leaves_description'
                    onChange={this.validateOnChange}
                    value={this.state.resignForm.leaves_description}
                  />
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col md={5}>
                  <Label for='descriptio_leaves'>Ditët e mbetura te punës:</Label>
                  <Input
                    type='number'
                    name='remaining_working_days'
                    onChange={this.validateOnChange}
                    className='height-2'
                    value={this.state.resignForm.remaining_working_days}
                    invalid={this.hasError('resignForm', 'remaining_working_days', 'required')}
                    data-validate='["required"]'
                  />
                  <span className='invalid-feedback'>Fusha është e nevojshme</span>
                </Col>
                <Col md={7}>
                  <Label for='descriptio_leaves'>Sqarim për ditët e mbetura te punës:</Label>
                  <Input
                    type='text'
                    name='remaining_working_days_description'
                    onChange={this.validateOnChange}
                    value={this.state.resignForm.remaining_working_days_description}
                  />
                </Col>
              </Row>
              <div className='mt-3'>
                <Label for='comment'>Komenti:</Label>
                <Input type='textarea' name='comment' onChange={this.validateOnChange} value={this.state.resignForm.comment} />
              </div>
              <div className='pt-2'>
                <Button color='danger' type='submit'>
                  Dërgo
                </Button>
              </div>
            </form>
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.reportModal} toggle={this.reportToggle} className='report_modal'>
          <ModalHeader toggle={this.reportToggle}>Raporto mungesë</ModalHeader>
          <ModalBody>
            <form onSubmit={this.onReportSubmit} name='reportForm'>
              <Label for='last_date'>Data e fundit</Label>
              <DateTimeBoostrap
                value={this.state.reportForm.last_date}
                onChange={this._onChangeReportMissingDate}
                onBlur={this._onChangeReportMissingBlur}
                utc={true}
                dateFormat={'DD/MM/YYYY'}
                timeFormat={false}
                inputProps={{
                  id: 'last_date',
                  'data-validate': '["validDate"]',
                  className: 'form-control',
                  name: 'last_date',
                  invalid: this.hasError('reportForm', 'last_date', 'validDate')
                }}
              />
              <span className='invalid-feedback'>Fusha është e nevojshme</span>
              {''}
              <div className='pt-2'>
                <Button color='danger' type='submit'>
                  Dërgo
                </Button>
              </div>
            </form>
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.blacklistModal} toggle={this.blacklistModalToggle} className='blacklist_modal'>
          <ModalHeader toggle={this.blacklistModalToggle}>Dërgo në listën e zezë</ModalHeader>
          <ModalBody>
            <form onSubmit={this.addToBlacklist} name='blackListForm'>
              <Label for='blacklistReason'>Ju lutem shënoni arsyen e futjes në listën e zezë:</Label>
              <Input
                type='textarea'
                name='blacklistReason'
                invalid={this.hasError('blackListForm', 'blacklistReason', 'required')}
                onChange={this.validateOnChange}
                value={this.state.blackListForm.blacklistReason}
                data-validate='["required"]'
              />
              <span className='invalid-feedback'>Fusha është e nevojshme</span>
              <div className='pt-2'>
                <Button color='danger' type='submit'>
                  Dërgo
                </Button>
              </div>
            </form>
          </ModalBody>
        </Modal>

        {this.state.isEdit ? (
          <div className='d-flex justify-content-between'>
            <p style={{ fontSize: 23, color: 'gray' }}>Profili dhe të dhënat e përdoruesit</p>
            <div>
              <Button onClick={() => this.props.history.goBack()} color='info' className='btn-labeled'>
                <span className='btn-label'>
                  <i className='fa fa-arrow-left' />
                </span>{' '}
                Prapa
              </Button>
            </div>
          </div>
        ) : (
          <div className='d-flex justify-content-between'>
            <p style={{ fontSize: 23, color: 'gray' }}>Shto Perdorues</p>
            <div>
              <Button onClick={() => this.props.history.goBack()} color='info' className='btn-labeled'>
                <span className='btn-label'>
                  <i className='fa fa-arrow-left' />
                </span>{' '}
                Prapa
              </Button>
            </div>
          </div>
        )}
        {this.state.isEdit ? (
          <Row>
            <Col lg='4'>
              <div className='card card-default'>
                <div className='card-body text-center'>
                  <div className='py-4'>
                    <img className={`img-fluid rounded-circle img-thumbnail thumb96`} src={'/img/blank_profile.png'} alt='Contact' />
                  </div>
                  <h3 className='m-0 text-bold'>{`${this.state.formUser.first_name} ${this.state.formUser.last_name}`}</h3>
                  {!this.state.isInBlacklist ? (
                    <>
                      <div className='my-3'>
                        <p>{this.state.getData.function && this.state.getData.function.name}</p>
                      </div>
                      <div className='d-flex justify-content-center'>
                        <Row className='d-flex justify-content-center'>
                          <div style={{ marginRight: 10 }} className='form-group row'>
                            <Col md={10}>
                              <Link
                                to={{
                                  pathname: `/manageLeaves/${this.props.match.params.idUser}`,
                                  state: {
                                    userName: this.state.formUser.first_name,
                                    lastName: this.state.formUser.last_name
                                  }
                                }}
                              >
                                <button type='button' className='btn btn-primary '>
                                  Pushimet
                                </button>
                              </Link>
                            </Col>
                          </div>
                          {(this.props.userStore.userLevel == 1 ||
                            this.props.userStore.userLevel == 2 ||
                            this.props.userStore.userLevel == 3 ||
                            this.props.userStore.userLevel == 5 ||
                            this.props.userStore.userLevel == 6) && (
                            <div className='form-group row'>
                              <Col md={10}>
                                <Link
                                  to={{
                                    pathname: `/wages/${this.props.match.params.idUser}`,
                                    state: {
                                      userName: this.state.formUser.first_name,
                                      lastName: this.state.formUser.last_name
                                    }
                                  }}
                                >
                                  <button type='button' className='btn btn-primary '>
                                    Pagat
                                  </button>
                                </Link>
                              </Col>
                            </div>
                          )}
                        </Row>
                      </div>

                      <div className='d-flex flex-wrap justify-content-center'>
                        {(this.props.userStore.userLevel == 1 ||
                          this.props.userStore.userLevel == 2 ||
                          this.props.userStore.userLevel == 3 ||
                          this.props.userStore.userLevel == 5 ||
                          this.props.userStore.userLevel == 6) &&
                          (!this.state.resignForm.is_active ? (
                            <button style={{ margin: '10px' }} type='button' onClick={this.reportToggle} className='btn btn-danger '>
                              Raporto mungesë
                            </button>
                          ) : (
                            ''
                          ))}

                        {[1, 5, 6].includes(this.props.userStore.userLevel) && (
                          <button
                            style={{ margin: '10px' }}
                            type='button'
                            onClick={!this.state.resignForm.is_active ? this.toggle : this.toggleSwal}
                            className={`btn ${!this.state.resignForm.is_active ? 'btn-danger' : 'btn-success'}`}
                          >
                            {!this.state.resignForm.is_active ? 'Shkëput kontratën' : 'Terhiqe Shkeputjen'}
                          </button>
                        )}

                        {(this.props.userStore.userLevel == 1 ||
                          this.props.userStore.userLevel == 5 ||
                          this.props.userStore.userLevel == 6) &&
                          !this.state.isInBlacklist && (
                            <button
                              style={{ margin: '10px' }}
                              type='button'
                              onClick={this.blacklistModalToggle}
                              className='btn btn-danger '
                            >
                              Shto në listën e zezë
                            </button>
                          )}
                      </div>
                    </>
                  ) : (
                    <div className='mt-4'>
                      <h4>Ky person gjendet në listën e zezë</h4>
                      <Button color='info' onClick={() => this.props.history.push(`/blacklists/${this.state.isInBlacklist}`)}>
                        Shiko më shumë
                      </Button>
                    </div>
                  )}
                </div>
                <div className='table-responsive'>
                  <table style={{ tableLayout: 'fixed' }} className='table'>
                    <tbody>
                      {this.state.getData.first_name ? (
                        <tr>
                          <td>
                            <strong>Emri</strong>
                          </td>
                          <td>{this.state.getData.first_name}</td>
                        </tr>
                      ) : null}
                      {this.state.getData.last_name ? (
                        <tr>
                          <td>
                            <strong>Mbiemri</strong>
                          </td>
                          <td>{this.state.getData.last_name}</td>
                        </tr>
                      ) : null}
                      <tr>
                        <td>
                          <strong>SPEEEX Badge</strong>
                        </td>
                        <td>{this.state.getData.speeex_badge ? this.state.getData.speeex_badge : 'Ende i padefinuar'}</td>
                      </tr>
                      {this.state.getData.alias ? (
                        <tr>
                          <td>
                            <strong>Alias</strong>
                          </td>
                          <td>{this.state.getData.alias}</td>
                        </tr>
                      ) : null}{' '}
                      {this.state.getData.team ? (
                        <tr>
                          <td>
                            <strong>Team</strong>
                          </td>
                          <td>{this.state.getData.team}</td>
                        </tr>
                      ) : null}
                      {this.state.getData.email ? (
                        <tr>
                          <td>
                            <strong>Email</strong>
                          </td>
                          <td>{this.state.getData.email}</td>
                        </tr>
                      ) : null}
                      <tr>
                        <td>
                          <strong>Email-i Personal</strong>
                        </td>
                        <td>{this.state.getData.personal_email ? this.state.getData.personal_email : 'Ende i padefinuar'}</td>
                      </tr>
                      {this.state.getData.phone_no ? (
                        <tr>
                          <td>
                            <strong>Telefoni</strong>
                          </td>
                          <td>{this.state.getData.phone_no}</td>
                        </tr>
                      ) : null}
                      {this.state.getData.emergency_contact ? (
                        <tr>
                          <td>
                            <strong>Kontakti emergjent</strong>
                          </td>
                          <td>{this.state.getData.emergency_contact}</td>
                        </tr>
                      ) : null}
                      {this.state.getData.marital_status ? (
                        <tr>
                          <td>
                            <strong>Statusi martesor</strong>
                          </td>
                          <td>{this.state.getData.marital_status}</td>
                        </tr>
                      ) : null}
                      {this.state.getData.identity_card ? (
                        <tr>
                          <td>
                            <strong>Numri i leternjoftimit</strong>
                          </td>
                          <td>{this.state.getData.identity_card}</td>
                        </tr>
                      ) : null}
                      {this.state.getData.activity ? (
                        <tr>
                          <td>
                            <strong>Aktiviteti</strong>
                          </td>
                          {this.state.activities.map((activities) => {
                            if (activities._id == this.state.getData.activity._id) {
                              return <td key={activities._id}>{activities.name}</td>
                            }
                          })}
                        </tr>
                      ) : null}
                      {this.state.getData.department ? (
                        <tr>
                          <td>
                            <strong>Departamenti</strong>
                          </td>
                          <td>
                            {this.state.getData.department.map((item, index) => {
                              return <p key={index}>{item.name}</p>
                            })}
                          </td>
                        </tr>
                      ) : null}
                      {this.state.getData.project ? (
                        <tr>
                          <td>
                            <strong>Projekti</strong>
                          </td>
                          {this.state.projects.map((project) => {
                            if (project._id == this.state.getData.project._id) {
                              return <td key={project._id}>{project.name}</td>
                            }
                          })}
                        </tr>
                      ) : null}
                      {this.state.getData.line ? (
                        <tr>
                          <td>
                            <strong>Linja</strong>
                          </td>
                          <td>{this.state.getData.line}</td>
                        </tr>
                      ) : null}
                      {this.state.getData.user_level ? (
                        <tr>
                          <td>
                            <strong>Niveli i Perdoruesit</strong>
                          </td>
                          {this.state.getData.user_level == 1 && <td>Administrator</td>}
                          {this.state.getData.user_level == 5 && <td>HR Manager</td>}
                          {this.state.getData.user_level == 6 && <td>HR Admin</td>}
                          {this.state.getData.user_level == 2 && <td>Manager</td>}
                          {this.state.getData.user_level == 3 && <td>Team Leader</td>}
                          {this.state.getData.user_level == 4 && <td>Perdorues i thjeshte</td>}
                          {this.state.getData.user_level == 7 && <td>Mirembajtes</td>}
                          {this.state.getData.user_level == 8 && <td>Koordinator i pushimeve</td>}
                        </tr>
                      ) : null}
                      {this.state.getData.languages ? (
                        <tr>
                          <td>
                            <strong>Gjuhet</strong>
                          </td>
                          <td>
                            {this.state.languages.map((language, index) => {
                              return (
                                <p key={index}>
                                  {language.checked ? language.name.toUpperCase() : ''} {language.checked ? '-' : ''}{' '}
                                  {language.checked ? language.level : ''}
                                </p>
                              )
                            })}
                          </td>
                        </tr>
                      ) : null}
                      {this.state.getData.address ? (
                        <tr>
                          <td>
                            <strong>Adresa</strong>
                          </td>
                          <td>{this.state.getData.address}</td>
                        </tr>
                      ) : null}
                      {this.state.getData.city ? (
                        <tr>
                          <td>
                            <strong>Qyteti</strong>
                          </td>
                          {this.state.cities.map((city) => {
                            if (city._id == this.state.getData.city._id) {
                              return <td key={city._id}>{city.name}</td>
                            }
                          })}
                        </tr>
                      ) : null}
                      {this.state.getData.birthdate ? (
                        <tr>
                          <td>
                            <strong>Data e lindjes</strong>
                          </td>
                          <td>{moment(this.state.getData.birthdate).format('DD/MM/YYYY')}</td>
                        </tr>
                      ) : null}
                      {this.state.getData.nationality ? (
                        <tr>
                          <td>
                            <strong>Nacionaliteti</strong>
                          </td>
                          <td>{this.state.getData.nationality}</td>
                        </tr>
                      ) : null}
                      <tr>
                        <td>
                          <strong>Shtetësia</strong>
                        </td>
                        <td>{this.state.getData.citizenship ? this.state.getData.citizenship : 'Ende e padefinuar'}</td>
                      </tr>
                      {this.state.getData.residence_permit ? (
                        <tr>
                          <td>
                            <strong>Lejeqëndrimi</strong>
                          </td>
                          <td>{this.state.getData.residence_permit ? 'Po' : `Jo`}</td>
                        </tr>
                      ) : null}
                      {this.state.getData.gender ? (
                        <tr>
                          <td>
                            <strong>Gjinia</strong>
                          </td>
                          <td>{this.state.getData.gender}</td>
                        </tr>
                      ) : null}
                      <tr>
                        <td>
                          <strong>Certifikata shëndetësore</strong>
                        </td>
                        <td>{this.state.getData.health_certificate ? ' E ka dorëzuar' : 'Nuk e ka dorëzuar'}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Certifikata nën hetime</strong>
                        </td>
                        <td>{this.state.getData.court_certificate ? ' E ka dorëzuar' : 'Nuk e ka dorëzuar'}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>SSHP</strong>
                        </td>
                        <td>{this.state.getData.health_safety_certificate ? ' E ka dorëzuar' : 'Nuk e ka dorëzuar'}</td>
                      </tr>
                      {this.state.getData.is_project_manager || this.state.getData.has_project_manager ? (
                        <tr>
                          <td>
                            <strong>Project Manager</strong>
                          </td>
                          {this.state.getData.is_project_manager ? (
                            <td>Është Project Manager</td>
                          ) : (
                            <td>
                              {this.state.getData.has_project_manager.first_name} {this.state.getData.has_project_manager.last_name}
                            </td>
                          )}
                        </tr>
                      ) : null}
                      {this.state.getData.manager ? (
                        <tr>
                          <td>
                            <strong>Menaxher</strong>
                          </td>
                          {this.state.managers.map((manager) => {
                            if (manager._id == this.state.getData.manager._id) {
                              return (
                                <td key={manager._id}>
                                  {manager.first_name} {manager.last_name}
                                </td>
                              )
                            }
                          })}
                        </tr>
                      ) : null}
                      {this.state.getData.teamleader ? (
                        <tr>
                          <td>
                            <strong>Team Leader</strong>
                          </td>
                          {this.state.teamLeaders.map((teamLeader) => {
                            if (teamLeader._id == this.state.getData.teamleader._id) {
                              return (
                                <td key={teamLeader._id}>
                                  {teamLeader.first_name} {teamLeader.last_name}
                                </td>
                              )
                            }
                          })}
                        </tr>
                      ) : null}
                      {this.state.getData.function ? (
                        <tr>
                          <td>
                            <strong>Funksioni</strong>
                          </td>
                          {this.state.funksionet.map((funksion) => {
                            if (funksion._id == this.state.getData.function._id) {
                              return <td key={funksion._id}>{funksion.name}</td>
                            }
                          })}
                        </tr>
                      ) : null}
                      <tr>
                        <td>
                          <strong>Rroga baze</strong>
                        </td>
                        {this.props.userStore.userLevel == 1 ||
                        this.props.userStore.userLevel == 5 ||
                        this.props.userStore.userLevel == 2 ||
                        this.props.userStore.userLevel == 3 ? (
                          <td>{this.state.getData.base_salary}</td>
                        ) : (
                          <td>0</td>
                        )}
                      </tr>
                      <tr>
                        <td>
                          <strong>Skill Bonus</strong>
                        </td>
                        {this.props.userStore.userLevel == 1 ||
                        this.props.userStore.userLevel == 5 ||
                        this.props.userStore.userLevel == 2 ||
                        this.props.userStore.userLevel == 3 ? (
                          <td>{this.state.getData.skill_bonus}</td>
                        ) : (
                          <td>0</td>
                        )}
                      </tr>
                      <tr>
                        <td>
                          <strong>Work Percentage</strong>
                        </td>
                        <td>{this.state.getData.work_percentage ? this.state.getData.work_percentage : 0}%</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Punëdhënësi</strong>
                        </td>
                        <td>{this.state.getData.employeer ? this.state.getData.employeer : 'Primar'}</td>
                      </tr>
                      {this.state.getData.office_location && (
                        <tr>
                          <td>
                            <strong>Lokacioni i punës</strong>
                          </td>
                          <td>{this.state.getData.office_location}</td>
                        </tr>
                      )}
                      {this.state.getData.work ? (
                        <tr>
                          <td>
                            <strong>Statusi i punës</strong>
                          </td>
                          {this.state.working_status.map((work) => {
                            if (work._id == this.state.getData.work._id) {
                              return <td key={work._id}>{work.name}</td>
                            }
                          })}
                        </tr>
                      ) : null}
                      {this.state.getData.bank ? (
                        <tr>
                          <td>
                            <strong>Banka</strong>
                          </td>
                          {this.state.banks.map((bank) => {
                            if (bank._id == this.state.getData.bank._id) {
                              return <td key={bank._id}>{bank.name}</td>
                            }
                          })}
                        </tr>
                      ) : null}
                      {this.state.getData.bank_account_no ? (
                        <tr>
                          <td>
                            <strong>Llogaria Bankare</strong>
                          </td>
                          <td>{this.state.getData.bank_account_no}</td>
                        </tr>
                      ) : null}
                      {this.state.getData.trust_account ? (
                        <tr>
                          <td>
                            <strong>Llogaria në Trust</strong>
                          </td>
                          <td>{this.state.getData.trust_account ? 'Po' : 'Jo'}</td>
                        </tr>
                      ) : null}
                      {this.state.getData.first_call ? (
                        <tr>
                          <td>
                            <strong>Dita e pare</strong>
                          </td>
                          <td>{moment(this.state.getData.first_call).format('DD/MM/YYYY')}</td>
                        </tr>
                      ) : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>

            <Col lg='8'>
              {(this.props.userStore.userLevel == 1 ||
                this.props.userStore.userLevel == 2 ||
                this.props.userStore.userLevel == 3 ||
                this.props.userStore.userLevel == 5 ||
                this.props.userStore.userLevel == 6) && (
                <Fragment>
                  <Nav tabs fill>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === '1'
                        })}
                        onClick={() => {
                          this.toggleTab('1')
                        }}
                      >
                        Shënimet e përdoruesit
                      </NavLink>
                    </NavItem>
                    {(this.props.userStore.userLevel == 1 ||
                      this.props.userStore.userLevel == 5 ||
                      this.props.userStore.userLevel == 6) && (
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === '3'
                          })}
                          onClick={() => {
                            this.toggleTab('3')
                          }}
                        >
                          Folderi digjital
                        </NavLink>
                      </NavItem>
                    )}
                    {(this.props.userStore.userLevel == 1 ||
                      this.props.userStore.userLevel == 5 ||
                      this.props.userStore.userLevel == 6) && (
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === '2'
                          })}
                          onClick={() => {
                            this.toggleTab('2')
                          }}
                        >
                          Edito të dhënat
                        </NavLink>
                      </NavItem>
                    )}

                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === '4'
                        })}
                        onClick={() => {
                          this.toggleTab('4')
                        }}
                      >
                        Vërejtjet
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent style={{ backgroundColor: 'white' }} activeTab={this.state.activeTab}>
                    <TabPane tabId='1'>
                      <UserNotes
                        userId={this.props.match.params.idUser}
                        transfers={this.state.formUser.transfers}
                        onAnuloTransferClick={this.handleAnuloTransferClick}
                      />
                    </TabPane>
                    {(this.props.userStore.userLevel == 1 ||
                      this.props.userStore.userLevel == 5 ||
                      this.props.userStore.userLevel == 6) && (
                      <TabPane tabId='3'>
                        <UserFolder
                          shouldShowNewContractBtn={this.state.shouldShowNewContractBtn}
                          userForm={this.state.formUser}
                          userId={this.props.match.params.idUser}
                        />
                      </TabPane>
                    )}
                    <TabPane tabId='2'>
                      {this.state.isInBlacklist ? (
                        <div>Ky person eshte ne listen e zezë</div>
                      ) : (
                        <div className='card '>
                          <div className='card-header d-flex align-items-center'>
                            <div className='d-flex justify-content-center col'>
                              <div className='h4 m-0 text-center'>Edito të dhënat personale</div>
                            </div>
                          </div>
                          <div className='card-body'>
                            <div className='row py-4 justify-content-center'>
                              <div className='col-12 col-sm-10'>
                                <form onSubmit={this.onSubmit} name='formUser' className='form-horizontal'>
                                  <div className='form-group row nowrapmobile'>
                                    <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='city'>
                                      Aktiv?
                                    </label>
                                    <div className='col-xl-6 col-md-2 col-3'>
                                      <div className='d-flex align-items-center'>
                                        <label className='switch switch-lg'>
                                          <input
                                            name='is_active'
                                            onChange={this.validateOnChange}
                                            value={this.state.formUser.is_active}
                                            type='checkbox'
                                            checked={this.state.formUser.is_active}
                                          />
                                          <span className='invalid-feedback' />
                                        </label>
                                        <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                                      </div>
                                    </div>
                                  </div>
                                  {this.state.formUser.is_active == true ? null : (
                                    <div>
                                      <div className='form-group row'>
                                        <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='department'>
                                          Arsyeja e daljes
                                        </label>
                                        <div className='col-xl-10 col-md-9 col-8'>
                                          <Select
                                            key='Arsyeja'
                                            placeholder='Selekto arsyen e daljes'
                                            name='departmentSelected'
                                            value={this.state.value_exitReason}
                                            onChange={this.handleExitReason}
                                            options={this.state.option_exitReason}
                                          />
                                        </div>
                                      </div>

                                      {this.state.otherExitReason ? (
                                        <div className='form-group row'>
                                          <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='phone' />
                                          <div className='col-xl-10 col-md-9 col-8'>
                                            <Input
                                              key='other_exit_reason'
                                              type='textarea'
                                              name='other_exit_reason'
                                              placeholder='Ju lutem shkruani arsyen ne vecanti!'
                                              onChange={this.validateOnChange}
                                              data-validate='["required"]'
                                              value={this.state.formUser.other_exit_reason}
                                            />
                                          </div>
                                        </div>
                                      ) : null}

                                      <div className='form-group row'>
                                        <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='phone'>
                                          Data e daljes
                                        </label>
                                        <div className='col-xl-10 col-md-9 col-8'>
                                          <DateTimeBoostrap
                                            key='exit_date'
                                            value={this.state.exit_date}
                                            onChange={this._onExitDateChange}
                                            locale='de'
                                            utc={true}
                                            dateFormat='DD/MM/YYYY'
                                            onBlur={this._onExitDateBlur}
                                            data-validate='["validDate"]'
                                            timeFormat={false}
                                            inputProps={{
                                              'data-validate': '["validDate"]',
                                              className: 'form-control',
                                              name: 'exit_date',
                                              autoComplete: 'off',
                                              invalid: this.hasError('formUser', 'exit_date', 'validDate')
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div className='lead bb mb-5 mt-5' />
                                    </div>
                                  )}
                                  {this.state.formUser.is_active === true && this.state.getData.is_active === false ? (
                                    <fieldset>
                                      <div className='form-group row mb'>
                                        <label className='col-md-2 col-form-label mb text-bold'>Dita e pare: </label>
                                        <Col md={8}>
                                          <DateTimeBoostrap
                                            value={
                                              this.state.formUser.is_active === true && this.state.getData.is_active === false
                                                ? this.state.tempFirstCall || ''
                                                : this.state.first_call || ''
                                            }
                                            onChange={this._onFirstCallChange}
                                            utc={true}
                                            dateFormat={'DD/MM/YYYY'}
                                            onBlur={this._onFirstCallBlur}
                                            timeFormat={false}
                                            inputProps={{
                                              className: `form-control ${this.state.first_call_error ? 'is-invalid' : ''}`,
                                              name: 'first_call',
                                              autoComplete: 'off'
                                            }}
                                          />
                                          {this.state.first_call_error && (
                                            <div className='invalid-feedback'>Dita e parë është e detyrueshme!</div>
                                          )}
                                        </Col>
                                      </div>
                                    </fieldset>
                                  ) : null}
                                  <div className='form-group row'>
                                    <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='phone'>
                                      Emri
                                    </label>
                                    <div className='col-xl-10 col-md-9 col-8'>
                                      <Input
                                        type='text'
                                        name='first_name'
                                        invalid={this.hasError('formUser', 'first_name', 'required')}
                                        onChange={this.validateOnChange}
                                        value={this.state.formUser.first_name}
                                        data-validate='["required"]'
                                      />
                                      <span className='invalid-feedback'>Fusha është e nevojshme</span>
                                    </div>
                                  </div>
                                  <div className='form-group row'>
                                    <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='phone'>
                                      Mbiemri
                                    </label>
                                    <div className='col-xl-10 col-md-9 col-8'>
                                      <Input
                                        type='text'
                                        name='last_name'
                                        invalid={this.hasError('formUser', 'last_name', 'required')}
                                        onChange={this.validateOnChange}
                                        value={this.state.formUser.last_name}
                                        data-validate='["required"]'
                                      />
                                      <span className='invalid-feedback'>Fusha është e nevojshme</span>
                                    </div>
                                  </div>
                                  <div className='form-group row'>
                                    <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='phone'>
                                      Alias
                                    </label>
                                    <div className='col-xl-10 col-md-9 col-8'>
                                      <Input
                                        type='text'
                                        name='alias_name'
                                        invalid={this.hasError('formUser', 'alias_name')}
                                        onChange={this.validateOnChange}
                                        value={this.state.formUser.alias_name}
                                      />
                                    </div>
                                  </div>
                                  <div className='form-group row'>
                                    <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='speeex_badge'>
                                      SPEEEX Badge
                                    </label>
                                    <div className='col-xl-10 col-md-9 col-8'>
                                      <Input
                                        type='text'
                                        name='speeex_badge'
                                        onChange={this.validateOnChange}
                                        value={this.state.formUser.speeex_badge || ''}
                                      />
                                    </div>
                                  </div>
                                  <div className='form-group row'>
                                    <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right'>Email Adresa :</label>
                                    <div className='col-xl-10 col-md-9 col-8'>
                                      <Input
                                        type='email'
                                        name='email'
                                        invalid={
                                          this.hasError('formUser', 'email', 'required') || this.hasError('formUser', 'email', 'email')
                                        }
                                        onChange={this.validateOnChange}
                                        value={this.state.formUser.email}
                                        data-validate='["required", "email"]'
                                      />
                                      {this.hasError('formUser', 'email', 'required') && (
                                        <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                                      )}
                                      {this.hasError('formUser', 'email', 'email') && (
                                        <span className='invalid-feedback'>Emaili i tille egziston</span>
                                      )}
                                    </div>
                                  </div>
                                  <div className='form-group row'>
                                    <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right'>Email Personal:</label>
                                    <div className='col-xl-10 col-md-9 col-8'>
                                      <Input
                                        type='email'
                                        name='personal_email'
                                        invalid={
                                          this.hasError('formUser', 'personal_email', 'required') ||
                                          this.hasError('formUser', 'personal_email', 'email')
                                        }
                                        onChange={this.validateOnChange}
                                        value={this.state.formUser.personal_email}
                                        data-validate='["required", "email"]'
                                      />
                                      {this.hasError('formUser', 'personal_email', 'required') && (
                                        <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                                      )}
                                      {this.hasError('formUser', 'personal_email', 'email') && (
                                        <span className='invalid-feedback'>Emaili i tille egziston</span>
                                      )}
                                    </div>
                                  </div>
                                  <div className='form-group row'>
                                    <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='phone'>
                                      Numri i leternjoftimit
                                    </label>
                                    <div className='col-xl-10 col-md-9 col-8'>
                                      <Input
                                        type='text'
                                        name='identity_card'
                                        invalid={
                                          this.hasError('formUser', 'identity_card', 'required') ||
                                          this.hasError('formUser', 'identity_card')
                                        }
                                        onChange={this.validateOnChange}
                                        data-validate='["required"]'
                                        value={this.state.formUser.identity_card || ''}
                                        placeholder='xxxxxxxxxx'
                                      />
                                      {this.hasError('formUser', 'identity_card', 'required') && (
                                        <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                                      )}
                                    </div>
                                  </div>
                                  <div className='form-group row'>
                                    <label
                                      className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right'
                                      htmlFor='maritalStatusSelected'
                                    >
                                      Statusi martesor
                                    </label>
                                    <div className='col-xl-10 col-md-9 col-8'>
                                      <Input
                                        type='select'
                                        name='maritalStatusSelected'
                                        onChange={this.validateOnChange}
                                        value={this.state.formUser.maritalStatusSelected || ''}
                                        className='custom-select custom-select'
                                        data-validate='["required"]'
                                        invalid={
                                          this.hasError('formUser', 'maritalStatusSelected', 'required') ||
                                          this.hasError('formUser', 'identity_card')
                                        }
                                      >
                                        <option key={'0'} value=''>
                                          Zgjedhni Statusin Martesor...
                                        </option>
                                        <option key={'1'} value={'Beqar/e'}>
                                          Beqar/e
                                        </option>
                                        <option key={'2'} value={'I/e martuar'}>
                                          I/e martuar
                                        </option>
                                        <option key={'3'} value={'I/e divorcuar'}>
                                          I/e divorcuar
                                        </option>
                                        <option key={'4'} value={'I/e ve'}>
                                          I/e ve
                                        </option>
                                      </Input>
                                      <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                                    </div>
                                  </div>
                                  <div className='form-group row'>
                                    <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='phone'>
                                      Telefoni
                                    </label>
                                    <div className='col-xl-10 col-md-9 col-8'>
                                      <Input
                                        type='text'
                                        name='phone'
                                        onChange={this.validateOnChange}
                                        value={this.state.formUser.phone || ''}
                                        placeholder='3834xxxxxxx'
                                      />
                                    </div>
                                  </div>
                                  <div className='form-group row'>
                                    <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='phone'>
                                      Kontakti emergjent
                                    </label>
                                    <div className='col-xl-10 col-md-9 col-8'>
                                      <Input
                                        type='text'
                                        name='emergencyPhone'
                                        onChange={this.validateOnChange}
                                        value={this.state.formUser.emergencyPhone || ''}
                                        placeholder=''
                                      />
                                    </div>
                                  </div>
                                  <div className='form-group row'>
                                    <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='address'>
                                      Adresa
                                    </label>
                                    <div className='col-xl-10 col-md-9 col-8'>
                                      <Input
                                        type='text'
                                        name='address'
                                        onChange={this.validateOnChange}
                                        value={this.state.formUser.address || ''}
                                      />
                                      <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                                    </div>
                                  </div>
                                  <div className='form-group row'>
                                    <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='city'>
                                      Qyteti
                                    </label>
                                    <div className='col-xl-10 col-md-9 col-8'>
                                      <Input
                                        type='select'
                                        name='city'
                                        onChange={this.validateOnChange}
                                        value={this.state.formUser.city || ''}
                                        placeholder='Rruga Filan Fisteku, 30'
                                      >
                                        <option key={'0'} value=''>
                                          Zgjedhni qytetin tuaj...
                                        </option>
                                        {this.state.cities.map((city) => {
                                          return (
                                            <option key={city._id} value={city._id}>
                                              {city.name}
                                            </option>
                                          )
                                        })}
                                      </Input>
                                      <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                                    </div>
                                  </div>
                                  <div className='form-group row'>
                                    <label
                                      className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right'
                                      htmlFor='activitySelected'
                                    >
                                      Aktiviteti
                                    </label>
                                    <div className='col-xl-10 col-md-9 col-8'>
                                      <Input
                                        type='select'
                                        name='activitySelected'
                                        onChange={this.validateOnChange}
                                        value={this.state.formUser.activitySelected || ''}
                                      >
                                        <option key={'0'} value=''>
                                          Zgjedhni Aktivitetin tuaj...
                                        </option>
                                        {this.state.activities.map((activity) => {
                                          return (
                                            <option key={activity._id} value={activity._id}>
                                              {activity.name}
                                            </option>
                                          )
                                        })}
                                      </Input>
                                      <span className='invalid-feedback'>Fusha është e nevojshme</span>
                                    </div>
                                  </div>
                                  <div className='form-group row'>
                                    <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='address'>
                                      Banka
                                    </label>
                                    <div className='col-xl-10 col-md-9 col-8'>
                                      <Input
                                        type='select'
                                        name='bank'
                                        onChange={this.validateOnChange}
                                        value={this.state.formUser.bank || ''}
                                        placeholder='Rruga Filan Fisteku, 30'
                                        invalid={this.hasError('formUser', 'min', 'required')}
                                      >
                                        <option key={'0'} value=''>
                                          Zgjedhni Banken tuaj...
                                        </option>
                                        {this.state.banks.map((bank) => {
                                          return (
                                            <option key={bank._id} value={bank._id}>
                                              {bank.name}
                                            </option>
                                          )
                                        })}
                                      </Input>
                                      <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                                    </div>
                                  </div>
                                  <div className='form-group row'>
                                    <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='phone'>
                                      Llogaria Bankare
                                    </label>
                                    <div className='col-xl-10 col-md-9 col-8'>
                                      <Input
                                        type='text'
                                        name='bankAccount'
                                        onChange={this.validateOnChange}
                                        value={this.state.formUser.bankAccount || ''}
                                        placeholder=''
                                      />
                                    </div>
                                  </div>
                                  {this.props.userStore.userLevel == 1 || this.props.userStore.userLevel == 5 ? (
                                    <Fragment>
                                      <div className='form-group row'>
                                        <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='phone'>
                                          Rroga baze
                                        </label>
                                        <div className='col-xl-10 col-md-9 col-8'>
                                          <InputGroup>
                                            <Input
                                              type='number'
                                              name='salary'
                                              step='0.01'
                                              onChange={this.validateOnChange}
                                              value={
                                                this.state.formUser.is_active && this.state.getData.is_active === false
                                                  ? this.state.tempSalary ?? 0
                                                  : this.state.formUser.salary
                                              }
                                              placeholder=''
                                            />
                                            <InputGroupAddon addonType='append'>
                                              <Button onClick={() => this._incrementSalary('+')} className='font-weight-bold'>
                                                +
                                              </Button>
                                              <Button onClick={() => this._incrementSalary('-')} className='font-weight-bold'>
                                                -
                                              </Button>
                                            </InputGroupAddon>
                                          </InputGroup>
                                        </div>
                                      </div>

                                      <div className='form-group row'>
                                        <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='phone'>
                                          Skill Bonus
                                        </label>
                                        <div className='col-xl-10 col-md-9 col-8'>
                                          <InputGroup>
                                            <Input
                                              type='number'
                                              name='skill_bonus'
                                              onChange={this.validateOnChange}
                                              value={this.state.formUser.skill_bonus || 0}
                                              placeholder='0'
                                            />
                                            <InputGroupAddon addonType='append'>
                                              <Button onClick={() => this._incrementBonus('+')} className='font-weight-bold'>
                                                +
                                              </Button>
                                              <Button onClick={() => this._incrementBonus('-')} className='font-weight-bold'>
                                                -
                                              </Button>
                                            </InputGroupAddon>
                                          </InputGroup>
                                        </div>
                                      </div>
                                    </Fragment>
                                  ) : null}
                                  <div className='form-group row'>
                                    <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='address'>
                                      Koha e punes se punetorit:
                                    </label>
                                    <div className='col-xl-10 col-md-9 col-8'>
                                      <Input
                                        type='select'
                                        name='work_percentage'
                                        onChange={this.validateOnChange}
                                        value={this.state.formUser.work_percentage || ''}
                                        className='custom-select custom-select'
                                      >
                                        <option key={'0'} value=''>
                                          Zgjedh kohen e punes se punetorit
                                        </option>
                                        {usersWorkHoursPercentage.map((item, i) => (
                                          <option key={i + 1} value={item.percentage}>
                                            {item.value}
                                          </option>
                                        ))}
                                      </Input>
                                      <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                                    </div>
                                  </div>
                                  <div className='form-group row'>
                                    <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='address'>
                                      Punëdhënësi
                                    </label>
                                    <div className='col-xl-10 col-md-9 col-8'>
                                      <Input
                                        type='select'
                                        name='employeer'
                                        onChange={this.validateOnChange}
                                        value={this.state.formUser.employeer || 'Primar'}
                                        className='custom-select custom-select'
                                      >
                                        <option key={'1'} value={'Primar'}>
                                          Primar
                                        </option>
                                        <option key={'2'} value={'Sekondar'}>
                                          Sekondar
                                        </option>
                                      </Input>
                                      <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                                    </div>
                                  </div>
                                  <div className='form-group row'>
                                    <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='address'>
                                      Lokacioni i punës
                                    </label>
                                    <div className='col-xl-10 col-md-9 col-8'>
                                      <Input
                                        type='select'
                                        name='office_location'
                                        onChange={this.validateOnChange}
                                        value={this.state.formUser.office_location || ''}
                                        className='custom-select custom-select'
                                      >
                                        <option key={'0'} value={''}>
                                          Zgjedhni lokacionin e punës
                                        </option>
                                        <option key={'1'} value={'Home Office'}>
                                          Home Office
                                        </option>
                                        <option key={'2'} value={'Prishtinë'}>
                                          Prishtinë
                                        </option>
                                        <option key={'3'} value={'Prishtinë HQ'}>
                                          Prishtinë HQ
                                        </option>
                                        <option key={'4'} value={'Vushtrri'}>
                                          Vushtrri
                                        </option>
                                        <option key={'5'} value={'Ferizaj'}>
                                          Ferizaj
                                        </option>
                                        <option key={'6'} value={'Prizren'}>
                                          Prizren
                                        </option>
                                      </Input>
                                      <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                                    </div>
                                  </div>
                                  <div className='form-group row'>
                                    <label
                                      className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right'
                                      htmlFor='workingStatusSelected'
                                    >
                                      Statusi i punës
                                    </label>
                                    <div className='col-xl-10 col-md-9 col-8'>
                                      <Input
                                        type='select'
                                        name='workingStatusSelected'
                                        onChange={this.validateOnChange}
                                        value={this.state.formUser.workingStatusSelected || ''}
                                        className='custom-select custom-select'
                                      >
                                        <option key={'0'} value=''>
                                          Zgjedhni Statusin e Punës...
                                        </option>
                                        {this.state.working_status.map((el) => {
                                          return (
                                            <option key={el._id} value={el._id}>
                                              {el.name}
                                            </option>
                                          )
                                        })}
                                      </Input>
                                      <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                                    </div>
                                  </div>
                                  <div className='form-group row'>
                                    <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='city'>
                                      Llogaria e Trustit:
                                    </label>
                                    <div className='col-xl-10 col-md-9 col-8'>
                                      <div className='d-flex align-items-center'>
                                        <label className='switch switch-lg'>
                                          <input
                                            name='trust_account'
                                            onChange={this.validateOnChange}
                                            value={this.state.formUser.trust_account}
                                            type='checkbox'
                                            checked={this.state.formUser.trust_account}
                                          />
                                          {this.state.formUser.trust_account ? (
                                            <span
                                              style={{
                                                backgroundColor: '#FF902B',
                                                borderColor: '#FF902B'
                                              }}
                                              className='invalid-feedback'
                                            />
                                          ) : (
                                            <span className='invalid-feedback' />
                                          )}
                                        </label>
                                      </div>
                                      <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                                    </div>
                                  </div>
                                  <div className='form-group row'>
                                    <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='city'>
                                      Llogaritet Trusti në Pagë:
                                    </label>
                                    <div className='col-xl-10 col-md-9 col-8'>
                                      <div className='d-flex align-items-center'>
                                        <label className='switch switch-lg'>
                                          <input
                                            name='should_include_trust'
                                            onChange={this.validateOnChange}
                                            value={this.state.formUser.should_include_trust}
                                            type='checkbox'
                                            checked={this.state.formUser.should_include_trust}
                                          />
                                          {this.state.formUser.should_include_trust ? (
                                            <span
                                              style={{
                                                backgroundColor: '#FF902B',
                                                borderColor: '#FF902B'
                                              }}
                                              className='invalid-feedback'
                                            />
                                          ) : (
                                            <span className='invalid-feedback' />
                                          )}
                                        </label>
                                      </div>
                                      <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                                    </div>
                                  </div>
                                  <div className='form-group row'>
                                    <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='phone'>
                                      Data e lindjes
                                    </label>
                                    <div className='col-xl-10 col-md-9 col-8'>
                                      <DateTimeBoostrap
                                        key='birthDate'
                                        locale='de'
                                        value={this.state.birthDate}
                                        onChange={this._onBirthDateChange}
                                        utc={true}
                                        dateFormat={'DD/MM/YYYY'}
                                        onBlur={this._onBirthDateBlur}
                                        timeFormat={false}
                                        inputProps={{
                                          className: 'form-control',
                                          name: 'birthDate',
                                          autoComplete: 'off'
                                        }}
                                      />
                                    </div>
                                  </div>
                                  {this.state.formUser.is_active !== false && this.state.getData.is_active !== true ? null : (
                                    <div className='form-group row'>
                                      <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='phone'>
                                        Dita e Pare
                                      </label>
                                      <div className='col-xl-10 col-md-9 col-8'>
                                        <DateTimeBoostrap
                                          value={this.state.first_call}
                                          onChange={this._onFirstCallChange}
                                          utc={true}
                                          dateFormat={'DD/MM/YYYY'}
                                          onBlur={this._onFirstCallBlur}
                                          timeFormat={false}
                                          inputProps={{
                                            className: 'form-control',
                                            name: 'first_call',
                                            autoComplete: 'off'
                                          }}
                                        />
                                      </div>
                                    </div>
                                  )}
                                  <div className='form-group row'>
                                    <VacancyList
                                      getUser={this.getUser}
                                      reloadList={this.state.vacancyModal}
                                      userId={this.props.match.params.idUser}
                                    />
                                  </div>{' '}
                                  <div className='form-group row'>
                                    <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='phone'>
                                      Ditë pushimi shtesë
                                    </label>
                                    <div className='col-xl-10 col-md-9 col-8'>
                                      <InputGroup>
                                        <Input
                                          type='number'
                                          disabled={true}
                                          name='extra_leaves'
                                          onChange={this.validateOnChange}
                                          value={this.state.formUser.extra_leaves?.toFixed(2)}
                                          placeholder=''
                                          invalid={this.hasError('formUser', 'extra_leaves', 'required')}
                                          data-validate='["required"]'
                                        />
                                        <InputGroupAddon addonType='append'>
                                          <Button onClick={() => this.vacancyToggle()} className='font-weight-bold'>
                                            +
                                          </Button>
                                        </InputGroupAddon>
                                      </InputGroup>
                                      {this.hasError('formUser', 'extra_leaves', 'required') && (
                                        <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                                      )}
                                    </div>
                                  </div>
                                  <div className='form-group row'>
                                    <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='phone'>
                                      Ditë pushimi nga viti i kaluar
                                    </label>
                                    <div className='col-xl-10 col-md-9 col-8'>
                                      <Input disabled value={this.state.formUser.last_year_leaves?.toFixed(2)} />
                                    </div>
                                  </div>
                                  <div className='form-group row'>
                                    <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='phone'>
                                      Ditë pushimi nga viti i tanishem
                                    </label>
                                    <div className='col-xl-10 col-md-9 col-8'>
                                      <Input disabled value={this.state.formUser.accumulated_leaves?.toFixed(2)} />
                                    </div>
                                  </div>{' '}
                                  <div className='form-group row'>
                                    <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='phone'>
                                      Gjithsej ditë pushimi
                                    </label>
                                    <div className='col-xl-10 col-md-9 col-8'>
                                      <Input
                                        disabled
                                        value={(
                                          this.state.formUser.accumulated_leaves +
                                            this.state.formUser.last_year_leaves +
                                            this.state.formUser.extra_leaves || 0
                                        )?.toFixed(2)}
                                      />
                                    </div>
                                  </div>
                                  <div className='form-group row'>
                                    <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='address'>
                                      Gjinia
                                    </label>
                                    <div className='col-xl-10 col-md-9 col-8'>
                                      <Input
                                        type='select'
                                        name='gender'
                                        onChange={this.validateOnChange}
                                        value={this.state.formUser.gender || ''}
                                        className='custom-select custom-select'
                                      >
                                        <option key={'0'} value=''>
                                          Zgjedhni gjinine tuaj...
                                        </option>
                                        <option key={'1'} value={'Mashkull'}>
                                          Mashkull
                                        </option>
                                        <option key={'2'} value={'Femer'}>
                                          Femer
                                        </option>
                                      </Input>
                                      <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                                    </div>
                                  </div>
                                  <div className='form-group row'>
                                    <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='address'>
                                      Nacionaliteti
                                    </label>
                                    <div className='col-xl-10 col-md-9 col-8'>
                                      <Input
                                        type='text'
                                        name='nationality'
                                        onChange={this.validateOnChange}
                                        value={this.state.formUser.nationality || ''}
                                      />
                                      <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                                    </div>
                                  </div>
                                  <div className='form-group row'>
                                    <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='address'>
                                      Shtetësia
                                    </label>
                                    <div className='col-xl-10 col-md-9 col-8'>
                                      <Input
                                        type='text'
                                        name='citizenship'
                                        onChange={this.validateOnChange}
                                        value={this.state.formUser.citizenship || ''}
                                      />
                                      <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                                    </div>
                                  </div>
                                  <div className='form-group row'>
                                    <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='city'>
                                      Lejeqëndrim:
                                    </label>
                                    <div className='col-xl-10 col-md-9 col-8'>
                                      <div className='d-flex align-items-center'>
                                        <label className='switch switch-lg'>
                                          <input
                                            name='residence_permit'
                                            onChange={this.validateOnChange}
                                            value={this.state.formUser.residence_permit}
                                            type='checkbox'
                                            checked={this.state.formUser.residence_permit}
                                          />
                                          {this.state.formUser.residence_permit ? (
                                            <span
                                              style={{
                                                backgroundColor: '#FF902B',
                                                borderColor: '#FF902B'
                                              }}
                                              className='invalid-feedback'
                                            />
                                          ) : (
                                            <span className='invalid-feedback' />
                                          )}
                                        </label>
                                      </div>
                                      <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                                    </div>
                                  </div>
                                  {(this.props.userStore.userLevel == 1 ||
                                    this.props.userStore.userLevel == 5 ||
                                    this.props.userStore.userLevel == 6) && (
                                    <div className='form-group row'>
                                      <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='city'>
                                        Niveli i Perdoruesit
                                      </label>
                                      <div className='col-xl-10 col-md-9 col-8'>
                                        <Input
                                          type='select'
                                          name='user_level'
                                          invalid={this.hasError('formUser', 'user_level', 'required')}
                                          onChange={this.validateOnChange}
                                          data-validate='["required"]'
                                          value={this.state.formUser.user_level || ''}
                                          placeholder=''
                                        >
                                          <option key={'0'} value=''>
                                            Zgjedhni Nivelin e Perdoruesit...
                                          </option>
                                          {this.props.userStore.userLevel == 1 && (
                                            <option key={'1'} value={1}>
                                              Administrator
                                            </option>
                                          )}
                                          <option key={'5'} value={5}>
                                            HR Manager
                                          </option>
                                          <option key={'6'} value={6}>
                                            HR Admin
                                          </option>
                                          <option key={'2'} value={2}>
                                            Manager
                                          </option>
                                          <option key={'3'} value={3}>
                                            Team Leader
                                          </option>
                                          <option key={'4'} value={4}>
                                            Perdorues i thjeshte
                                          </option>
                                          <option key={'7'} value={7}>
                                            Mirembajtes
                                          </option>
                                          <option key={'8'} value={8}>
                                            Koordinator i pushimeve
                                          </option>
                                        </Input>
                                        <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                                      </div>
                                    </div>
                                  )}
                                  {this.state.formUser.user_level == 1 ||
                                  this.state.formUser.user_level == 2 ||
                                  this.state.formUser.user_level == 5 ||
                                  this.state.formUser.user_level == 6 ? (
                                    <div className='form-group row'>
                                      <label
                                        className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right'
                                        htmlFor='is_project_manager'
                                      >
                                        Project Manager:
                                      </label>
                                      <div className='col-xl-10 col-md-9 col-8'>
                                        <div className='d-flex align-items-center'>
                                          <label className='switch switch-lg'>
                                            <input
                                              name='is_project_manager'
                                              onChange={this.validateOnChange}
                                              value={this.state.formUser.is_project_manager}
                                              type='checkbox'
                                              checked={this.state.formUser.is_project_manager}
                                            />
                                            {this.state.formUser.is_project_manager ? (
                                              <span
                                                style={{
                                                  backgroundColor: '#FF902B',
                                                  borderColor: '#FF902B'
                                                }}
                                                className='invalid-feedback'
                                              />
                                            ) : (
                                              <span className='invalid-feedback' />
                                            )}
                                          </label>
                                        </div>
                                        <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                                      </div>
                                    </div>
                                  ) : null}
                                  <div className='form-group row'>
                                    <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='project'>
                                      Projekti
                                    </label>
                                    <div className='col-xl-10 col-md-9 col-8'>
                                      <Input
                                        type='select'
                                        name='projectSelected'
                                        invalid={
                                          this.state.formUser.user_level == 2 ||
                                          this.state.formUser.user_level == 3 ||
                                          this.state.formUser.user_level == 4 ||
                                          this.state.formUser.user_level == 7
                                            ? this.hasError('formUser', 'projectSelected', 'required')
                                            : false
                                        }
                                        onChange={this.validateOnChange}
                                        data-validate={
                                          this.state.formUser.user_level == 2 ||
                                          this.state.formUser.user_level == 3 ||
                                          this.state.formUser.user_level == 4 ||
                                          this.state.formUser.user_level == 7
                                            ? '["required"]'
                                            : null
                                        }
                                        value={this.state.formUser.projectSelected}
                                        placeholder=''
                                      >
                                        <option key={'0'} value=''>
                                          Zgjedhni projektin tuaj...
                                        </option>
                                        {this.state.projects.map((element) => {
                                          return (
                                            <option key={element._id} value={element._id}>
                                              {element.name}
                                            </option>
                                          )
                                        })}
                                      </Input>
                                      <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                                    </div>
                                  </div>
                                  <div className='form-group row'>
                                    <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='department'>
                                      Departamenti
                                    </label>
                                    <div className='col-xl-10 col-md-9 col-8'>
                                      <Select
                                        placeholder='Selekto Departamentet'
                                        name='departmentSelected'
                                        isMulti
                                        simpleValue
                                        value={this.state.formUser.departmentSelected}
                                        options={this.state.departments}
                                        onChange={this.handleChangeDepartment}
                                      />
                                      {this.state.formUser.departmentSelected.length == 0 &&
                                      this.state.formUser.user_level != 1 &&
                                      this.state.departmentError ? (
                                        <span style={{ color: 'red', fontSize: 12 }}>Zgjedh se paku nje Departament</span>
                                      ) : null}
                                    </div>
                                  </div>
                                  {this.state.departmentTransferDate === true ? (
                                    <>
                                      <div className='form-group row'>
                                        <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='start_date'>
                                          Data e transferimit
                                        </label>
                                        <div className='col-xl-10 col-md-9 col-8'>
                                          <DateTimeBoostrap
                                            key='start_date'
                                            locale='de'
                                            value={this.state.formUser.start_date}
                                            onChange={this._onStartDateChange}
                                            utc={true}
                                            dateFormat={'DD/MM/YYYY'}
                                            onBlur={this._onStartDateBlur}
                                            timeFormat={false}
                                            inputProps={{
                                              className: 'form-control',
                                              name: 'start_date',
                                              autoComplete: 'off',
                                              required: true
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                  {this.state.formUser.user_level == 1 ||
                                  this.state.formUser.user_level == 5 ||
                                  this.state.formUser.user_level == 6 ||
                                  this.state.formUser.user_level == 7 ||
                                  this.state.formUser.user_level == 8 ||
                                  this.state.formUser.is_project_manager ? null : (
                                    <div className='form-group row'>
                                      <label
                                        className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right'
                                        htmlFor='project_manager_selected'
                                      >
                                        Project Manager
                                      </label>
                                      <div className='col-xl-10 col-md-9 col-8'>
                                        <Input
                                          type='select'
                                          name='project_manager_selected'
                                          invalid={this.hasError('formUser', 'project_manager_selected')}
                                          onChange={this.validateOnChange}
                                          value={this.state.formUser.project_manager_selected || ''}
                                        >
                                          <option key={'0'} value=''>
                                            Zgjedhni project managerin
                                          </option>
                                          {this.state.project_managers.map((element) => {
                                            return (
                                              <option key={element._id} value={element._id}>
                                                {`${element.first_name} ${element.last_name}`}
                                              </option>
                                            )
                                          })}
                                        </Input>
                                        <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                                      </div>
                                    </div>
                                  )}
                                  <div className='form-group row'>
                                    <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='city'>
                                      Manager
                                    </label>
                                    <div className='col-xl-10 col-md-9 col-8'>
                                      <Input
                                        type='select'
                                        name='managerSelected'
                                        invalid={this.hasError('formUser', 'managerSelected', 'required')}
                                        onChange={this.validateOnChange}
                                        data-validate='["required"]'
                                        value={this.state.formUser.managerSelected || ''}
                                        placeholder=''
                                      >
                                        <option key={'0'} value=''>
                                          Zgjedhni managerin tuaj...
                                        </option>
                                        {this.state.managers.map((element) => {
                                          return (
                                            <option key={element._id} value={element._id}>
                                              {`${element.first_name} ${element.last_name}`}
                                            </option>
                                          )
                                        })}
                                      </Input>
                                      <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                                    </div>
                                  </div>
                                  {this.state.formUser.user_level == 1 ||
                                  this.state.formUser.user_level == 2 ||
                                  this.state.formUser.user_level == 3 ||
                                  this.state.formUser.user_level == 5 ||
                                  this.state.formUser.user_level == 6 ? null : (
                                    <div className='form-group row'>
                                      <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='city'>
                                        Team Leader
                                      </label>
                                      <div className='col-xl-10 col-md-9 col-8'>
                                        <Input
                                          type='select'
                                          disabled={
                                            this.state.formUser.user_level == 1 ||
                                            this.state.formUser.user_level == 5 ||
                                            this.state.formUser.user_level == 6
                                              ? true
                                              : false || this.state.formUser.user_level == 2
                                                ? true
                                                : false || this.state.formUser.user_level == 3
                                                  ? true
                                                  : false
                                          }
                                          name='teamLeaderSelected'
                                          invalid={this.hasError('formUser', 'teamLeaderSelected')}
                                          onChange={this.validateOnChange}
                                          value={this.state.formUser.teamLeaderSelected || ''}
                                          placeholder=''
                                        >
                                          <option key={'0'} value=''>
                                            Zgjedhni team leaderin tuaj...
                                          </option>
                                          {this.state.teamLeaders.map((element) => {
                                            return (
                                              <option key={element._id} value={element._id}>
                                                {`${element.first_name} ${element.last_name}`}
                                              </option>
                                            )
                                          })}
                                        </Input>
                                        <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                                      </div>
                                    </div>
                                  )}
                                  <div className='form-group row'>
                                    <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='city'>
                                      Funksioni
                                    </label>
                                    <div className='col-xl-10 col-md-9 col-8'>
                                      <Input
                                        type='select'
                                        name='functionSelected'
                                        invalid={this.hasError('formUser', 'functionSelected', 'required')}
                                        onChange={this.validateOnChange}
                                        data-validate='["required"]'
                                        value={this.state.formUser.functionSelected}
                                        placeholder=''
                                      >
                                        <option key={'0'} value=''>
                                          Zgjedhni funksionin tuaj...
                                        </option>
                                        {this.state.funksionet.map((element) => {
                                          return (
                                            <option key={element._id} value={element._id}>
                                              {element.name}
                                            </option>
                                          )
                                        })}
                                      </Input>
                                      <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                                    </div>
                                  </div>
                                  <div className='form-group row'>
                                    <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='phone'>
                                      Certifikata nen hetime
                                    </label>
                                    <div className='col-xl-10 col-md-9 col-8 d-flex align-items-center'>
                                      <div className='checkbox c-checkbox'>
                                        <label>
                                          <Input
                                            onChange={this.validateOnChange}
                                            name='investigationCertificate'
                                            type='checkbox'
                                            checked={this.state.formUser.investigationCertificate}
                                            value={this.state.formUser.investigationCertificate}
                                          />
                                          <span className='fa fa-check' />
                                          {''}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='form-group row'>
                                    <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='phone'>
                                      Certifikata shendetesore
                                    </label>
                                    <div className='col-xl-10 col-md-9 col-8 d-flex align-items-center'>
                                      <div className='checkbox c-checkbox'>
                                        <label>
                                          <Input
                                            onChange={this.validateOnChange}
                                            name='healthCertificate'
                                            type='checkbox'
                                            checked={this.state.formUser.healthCertificate}
                                            value={this.state.formUser.healthCertificate}
                                          />
                                          <span className='fa fa-check' />
                                          {''}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='form-group row'>
                                    <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='phone'>
                                      SSHP
                                    </label>
                                    <div className='col-xl-10 col-md-9 col-8 d-flex align-items-center'>
                                      <div className='checkbox c-checkbox'>
                                        <label>
                                          <Input
                                            onChange={this.validateOnChange}
                                            name='healthSafetyCertificate'
                                            type='checkbox'
                                            checked={this.state.formUser.healthSafetyCertificate}
                                            value={this.state.formUser.healthSafetyCertificate}
                                          />
                                          <span className='fa fa-check' />
                                          {''}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='form-group row'>
                                    <label className='text-bold col-xl-2 col-md-3 col-4 col-form-label text-right' htmlFor='city'>
                                      Gjuhet
                                    </label>
                                    <div className='col-md-10'>
                                      {this.state.languages.map((item, index) => {
                                        return (
                                          <div key={index} className='card b mb-8'>
                                            <CardHeader>
                                              <CardTitle>
                                                <div className='checkbox c-checkbox'>
                                                  <label>
                                                    <Input
                                                      key={item._id}
                                                      name={item.name}
                                                      checked={item.checked}
                                                      onChange={() => this.changeLanguage(index)}
                                                      type='checkbox'
                                                      value={item._id || ''}
                                                    />
                                                    <span className='fa fa-check' />
                                                    {item.name}
                                                  </label>
                                                </div>
                                              </CardTitle>
                                            </CardHeader>
                                            <Collapse isOpen={item.checked}>
                                              <CardBody id='collapse01'>
                                                <Input
                                                  key={item._id}
                                                  type='select'
                                                  name={item.name}
                                                  onChange={this.validateLanguages}
                                                  value={this.state.setLanguages[item.name] || ''}
                                                >
                                                  <option key={'0'} value=''>
                                                    Zgjedhni Nivelin e Gjuhes...
                                                  </option>
                                                  <option key={'A1'} value={'A1'}>
                                                    A1
                                                  </option>
                                                  <option key={'A2'} value={'A2'}>
                                                    A2
                                                  </option>
                                                  <option key={'B1'} value={'B1'}>
                                                    B1
                                                  </option>
                                                  <option key={'B2'} value={'B2'}>
                                                    B2
                                                  </option>
                                                  <option key={'C1'} value={'C1'}>
                                                    C1
                                                  </option>
                                                  <option key={'C2'} value={'C2'}>
                                                    C2
                                                  </option>
                                                </Input>
                                              </CardBody>
                                            </Collapse>
                                          </div>
                                        )
                                      })}
                                    </div>

                                    <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                                  </div>
                                  <div className='form-group row float-right'>
                                    <div style={{ padding: 15 }} className='row-md-10'>
                                      <button style={{ marginRight: 10 }} className='btn btn-info' type='submit'>
                                        Ruaj
                                      </button>
                                      <Button color='danger' onClick={this.cancelGoBack.bind(this)}>
                                        Anulo
                                      </Button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </TabPane>
                    <TabPane tabId='4'>
                      <Warnings userId={this.props.match.params.idUser} />
                    </TabPane>
                  </TabContent>
                </Fragment>
              )}
            </Col>
          </Row>
        ) : (
          <Row>
            <div className='col-md-12'>
              <form onSubmit={this.onSubmit} name='formUser'>
                <Card className='card-default'>
                  <CardBody>
                    <legend className='mb-4' />
                    <fieldset>
                      <div className='form-group row align-items-center'>
                        <label className='col-md-2 col-form-label'>Email Adresa :</label>
                        <Col md={8}>
                          <Input
                            type='text'
                            name='email'
                            invalid={this.hasError('formUser', 'email', 'required') || this.hasError('formUser', 'email', 'email')}
                            onChange={this.validateOnChange}
                            value={this.state.formUser.email}
                            data-validate='["required", "email"]'
                          />
                          {this.hasError('formUser', 'email', 'required') && (
                            <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                          )}
                          {this.hasError('formUser', 'email', 'email') && (
                            <span className='invalid-feedback'>Fusha duhet te permbaje nje '@'</span>
                          )}
                        </Col>
                      </div>
                    </fieldset>

                    <fieldset>
                      <div className='form-group row align-items-center'>
                        <label className='col-md-2 col-form-label'>Email Adresa Personale:</label>
                        <Col md={8}>
                          <Input
                            type='text'
                            name='personal_email'
                            invalid={
                              this.hasError('formUser', 'personal_email', 'required') ||
                              this.hasError('formUser', 'personal_email', 'email')
                            }
                            onChange={this.validateOnChange}
                            value={this.state.formUser.personal_email}
                            data-validate='["required", "email"]'
                          />
                          {this.hasError('formUser', 'personal_email', 'required') && (
                            <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                          )}
                          {this.hasError('formUser', 'personal_email', 'email') && (
                            <span className='invalid-feedback'>Fusha duhet te permbaje nje '@'</span>
                          )}
                        </Col>
                      </div>
                    </fieldset>

                    <fieldset>
                      <div className='form-group row align-items-center'>
                        <label className='col-md-2 col-form-label'>Fjalekalimi :</label>
                        <Col md={8}>
                          <Input
                            type='password'
                            name='password'
                            invalid={this.hasError('formUser', 'password', 'required')}
                            onChange={this.validateOnChange}
                            value={this.state.formUser.password}
                            data-validate='["required"]'
                          />
                          <span className='invalid-feedback'>Fusha është e nevojshme</span>
                        </Col>
                      </div>
                    </fieldset>

                    <fieldset>
                      <div className='form-group row align-items-center'>
                        <label className='col-md-2 col-form-label'>Emri :</label>
                        <Col md={8}>
                          <Input
                            type='text'
                            name='first_name'
                            invalid={this.hasError('formUser', 'first_name', 'required')}
                            onChange={this.validateOnChange}
                            value={this.state.formUser.first_name}
                            data-validate='["required"]'
                          />
                          <span className='invalid-feedback'>Fusha është e nevojshme</span>
                        </Col>
                      </div>
                    </fieldset>

                    <fieldset>
                      <div className='form-group row align-items-center'>
                        <label className='col-md-2 col-form-label'>Mbiemri :</label>
                        <Col md={8}>
                          <Input
                            type='text'
                            name='last_name'
                            invalid={this.hasError('formUser', 'last_name', 'required')}
                            onChange={this.validateOnChange}
                            value={this.state.formUser.last_name}
                            data-validate='["required"]'
                          />
                          <span className='invalid-feedback'>Fusha është e nevojshme</span>
                        </Col>
                      </div>
                    </fieldset>

                    <fieldset>
                      <div className='form-group row align-items-center'>
                        <label className='col-md-2 col-form-label'>Alias :</label>
                        <Col md={8}>
                          <Input
                            type='text'
                            name='alias_name'
                            invalid={this.hasError('formUser', 'alias_name')}
                            onChange={this.validateOnChange}
                            value={this.state.formUser.alias_name}
                          />
                        </Col>
                      </div>
                    </fieldset>

                    <fieldset>
                      <div className='form-group row align-items-center'>
                        <label className='col-md-2 col-form-label'>SPEEEX Badge :</label>
                        <Col md={8}>
                          <Input
                            type='text'
                            name='speeex_badge'
                            invalid={this.hasError('formUser', 'speeex_badge')}
                            onChange={this.validateOnChange}
                            value={this.state.formUser.speeex_badge}
                          />
                        </Col>
                      </div>
                    </fieldset>

                    <fieldset>
                      <div className='form-group row align-items-center'>
                        <label className='col-md-2 col-form-label'>Numri i leternjoftimit :</label>
                        <Col md={8}>
                          <Input
                            type='text'
                            name='identity_card'
                            invalid={this.hasError('formUser', 'identity_card', 'required') || this.hasError('formUser', 'identity_card')}
                            onChange={this.validateOnChange}
                            data-validate='["required"]'
                            value={this.state.formUser.identity_card || ''}
                            placeholder='xxxxxxxxxx'
                          />
                          {this.hasError('formUser', 'identity_card', 'required') && (
                            <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                          )}
                          {this.hasError('formUser', 'identity_card', 'number') && (
                            <span className='invalid-feedback'>Ju lutem shenoni numer</span>
                          )}
                        </Col>
                      </div>
                    </fieldset>

                    <fieldset>
                      <div className='form-group row align-items-center'>
                        <label className='col-md-2 col-form-label'>Gjinia :</label>
                        <Col md={8}>
                          <Input
                            type='select'
                            name='gender'
                            onChange={this.validateOnChange}
                            value={this.state.formUser.gender}
                            className='custom-select custom-select'
                          >
                            <option key={'0'} value=''>
                              Zgjedhni gjinine tuaj...
                            </option>
                            <option key={'1'} value={'Mashkull'}>
                              Mashkull
                            </option>
                            <option key={'2'} value={'Femer'}>
                              Femer
                            </option>
                          </Input>
                          <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                        </Col>
                      </div>
                    </fieldset>

                    <fieldset>
                      <div className='form-group row align-items-center'>
                        <label className='col-md-2 col-form-label'>Nacionaliteti :</label>
                        <Col md={8}>
                          <Input type='text' name='nationality' onChange={this.validateOnChange} value={this.state.formUser.nationality} />
                          <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                        </Col>
                      </div>
                    </fieldset>

                    <fieldset>
                      <div className='form-group row align-items-center'>
                        <label className='col-md-2 col-form-label'>Shtetësia :</label>
                        <Col md={8}>
                          <Input type='text' name='citizenship' onChange={this.validateOnChange} value={this.state.formUser.citizenship} />
                          <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                        </Col>
                      </div>
                    </fieldset>
                    <fieldset>
                      <div className='form-group row align-items-center'>
                        <label className='col-md-2 col-form-label'>Lejeqëndrim :</label>
                        <Col md={10}>
                          <div className='d-flex align-items-center'>
                            <label className='switch'>
                              <input
                                name='residence_permit'
                                onChange={this.validateOnChange}
                                value={this.state.formUser.residence_permit}
                                type='checkbox'
                                checked={this.state.formUser.residence_permit}
                              />
                              {this.state.formUser.residence_permit ? (
                                <span
                                  style={{
                                    backgroundColor: '#FF902B',
                                    borderColor: '#FF902B'
                                  }}
                                  className='invalid-feedback'
                                />
                              ) : (
                                <span className='invalid-feedback' />
                              )}
                            </label>
                          </div>
                        </Col>
                      </div>
                    </fieldset>
                    <fieldset>
                      <div className='form-group row align-items-center'>
                        <label className='col-md-2 col-form-label'>Banka :</label>
                        <Col md={8}>
                          <Input
                            type='select'
                            name='bank'
                            onChange={this.validateOnChange}
                            value={this.state.formUser.bank}
                            placeholder='Rruga Filan Fisteku, 30'
                          >
                            <option key={'0'} value=''>
                              Zgjedhni Banken tuaj...
                            </option>
                            {this.state.banks.map((bank) => {
                              return (
                                <option key={bank._id} value={bank._id}>
                                  {bank.name}
                                </option>
                              )
                            })}
                          </Input>
                          <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                        </Col>
                      </div>
                    </fieldset>

                    <fieldset>
                      <div className='form-group row align-items-center'>
                        <label className='col-md-2 col-form-label'>Llogaria Bankare :</label>
                        <Col md={8}>
                          <Input
                            type='text'
                            name='bankAccount'
                            minLength='16'
                            maxLength='20'
                            onChange={this.validateOnChange}
                            value={this.state.formUser.bankAccount}
                          />
                        </Col>
                      </div>
                    </fieldset>

                    <fieldset>
                      <div className='form-group row align-items-center'>
                        <label className='col-md-2 col-form-label'>Qyteti :</label>
                        <Col md={8}>
                          <Input
                            type='select'
                            name='city'
                            onChange={this.validateOnChange}
                            value={this.state.formUser.city}
                            placeholder='Rruga Filan Fisteku, 30'
                          >
                            <option key={'0'} value=''>
                              Zgjedhni qytetin tuaj...
                            </option>
                            {this.state.cities.map((city) => {
                              return (
                                <option key={city._id} value={city._id}>
                                  {city.name}
                                </option>
                              )
                            })}
                          </Input>
                          <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                        </Col>
                      </div>
                    </fieldset>

                    <fieldset>
                      <div className='form-group row align-items-center'>
                        <label className='col-md-2 col-form-label'>Telefoni :</label>
                        <Col md={8}>
                          <Input
                            type='text'
                            name='phone'
                            onChange={this.validateOnChange}
                            value={this.state.formUser.phone}
                            placeholder='3834xxxxxxx'
                          />
                          {this.hasError('formUser', 'phone', 'required') && (
                            <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                          )}
                          {this.hasError('formUser', 'phone', 'number') && <span className='invalid-feedback'>Ju lutem shenoni numer</span>}
                        </Col>
                      </div>
                    </fieldset>

                    <fieldset>
                      <div className='form-group row align-items-center'>
                        <label className='col-md-2 col-form-label'>Kontakti emergjent :</label>
                        <Col md={8}>
                          <Input
                            type='text'
                            name='emergencyPhone'
                            onChange={this.validateOnChange}
                            value={this.state.formUser.emergencyPhone}
                            placeholder=''
                          />
                          {this.hasError('formUser', 'emergencyPhone', 'required') && (
                            <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                          )}
                          {this.hasError('formUser', 'emergencyPhone', 'number') && (
                            <span className='invalid-feedback'>Ju lutem shenoni numer</span>
                          )}
                        </Col>
                      </div>
                    </fieldset>

                    <fieldset>
                      <div className='form-group row align-items-center'>
                        <label className='col-md-2 col-form-label'>Adresa :</label>
                        <Col md={8}>
                          <Input type='text' name='address' onChange={this.validateOnChange} value={this.state.formUser.address} />
                          <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                        </Col>
                      </div>
                    </fieldset>

                    <fieldset>
                      <div className='form-group row align-items-center'>
                        <label className='col-md-2 col-form-label'>Aktiviteti :</label>
                        <Col md={8}>
                          <Input
                            type='select'
                            name='activitySelected'
                            onChange={this.validateOnChange}
                            value={this.state.formUser.activitySelected || ''}
                          >
                            <option key={'0'} value=''>
                              Zgjedhni Aktivitetin tuaj...
                            </option>
                            {this.state.activities.map((activity) => {
                              return (
                                <option key={activity._id} value={activity._id}>
                                  {activity.name}
                                </option>
                              )
                            })}
                          </Input>
                          <span className='invalid-feedback'>Fusha është e nevojshme</span>
                        </Col>
                      </div>
                    </fieldset>
                    {(this.props.userStore.userLevel == 1 ||
                      this.props.userStore.userLevel == 5 ||
                      this.props.userStore.userLevel == 6) && (
                      <fieldset>
                        <div className='form-group row align-items-center'>
                          <label className='col-md-2 col-form-label'>Niveli i Perdoruesit :</label>
                          <div className='col-md-8'>
                            <Input
                              type='select'
                              name='user_level'
                              invalid={this.hasError('formUser', 'user_level', 'required')}
                              onChange={this.validateOnChange}
                              data-validate='["required"]'
                              value={this.state.formUser.user_level}
                              placeholder=''
                            >
                              <option key={'0'} value=''>
                                Zgjedhni Nivelin e Perdoruesit...
                              </option>
                              {this.props.userStore.userLevel == 1 && (
                                <option key={'1'} value={1}>
                                  Administrator
                                </option>
                              )}
                              <option key={'5'} value={5}>
                                HR Manager
                              </option>
                              <option key={'6'} value={6}>
                                HR Admin
                              </option>
                              <option key={'2'} value={2}>
                                Manager
                              </option>
                              <option key={'3'} value={3}>
                                Team Leader
                              </option>
                              <option key={'4'} value={4}>
                                Perdorues i thjeshte
                              </option>
                              <option key={'7'} value={7}>
                                Mirembajtes
                              </option>
                              <option key={'8'} value={8}>
                                Koordinator i pushimeve
                              </option>
                            </Input>
                            <span className='invalid-feedback'>Fusha është e nevojshme</span>
                          </div>
                        </div>
                      </fieldset>
                    )}

                    {this.state.formUser.user_level == 1 ||
                    this.state.formUser.user_level == 2 ||
                    this.state.formUser.user_level == 5 ||
                    this.state.formUser.user_level == 6 ? (
                      <fieldset>
                        <div className='form-group row align-items-center'>
                          <label className='col-md-2 col-form-label'>Eshte project manager :</label>
                          <Col md={10}>
                            <div className='d-flex align-items-center'>
                              <label className='switch'>
                                <input
                                  name='is_project_manager'
                                  onChange={this.validateOnChange}
                                  value={this.state.formUser.is_project_manager}
                                  type='checkbox'
                                  checked={this.state.formUser.is_project_manager}
                                />
                                {this.state.formUser.is_project_manager ? (
                                  <span
                                    style={{
                                      backgroundColor: '#FF902B',
                                      borderColor: '#FF902B'
                                    }}
                                    className='invalid-feedback'
                                  />
                                ) : (
                                  <span className='invalid-feedback' />
                                )}
                              </label>
                            </div>
                          </Col>
                        </div>
                      </fieldset>
                    ) : null}

                    <fieldset>
                      <div className='form-group row align-items-center'>
                        <label className='col-md-2 col-form-label'>Projekti :</label>
                        <Col md={8}>
                          <Input
                            type='select'
                            name='projectSelected'
                            invalid={
                              this.state.formUser.user_level == 2 ||
                              this.state.formUser.user_level == 3 ||
                              this.state.formUser.user_level == 4 ||
                              this.state.formUser.user_level == 7
                                ? this.hasError('formUser', 'projectSelected', 'required')
                                : false
                            }
                            onChange={this.validateOnChange}
                            data-validate={
                              this.state.formUser.user_level == 2 ||
                              this.state.formUser.user_level == 3 ||
                              this.state.formUser.user_level == 4 ||
                              this.state.formUser.user_level == 7
                                ? '["required"]'
                                : null
                            }
                            value={this.state.formUser.projectSelected}
                            placeholder=''
                          >
                            <option key={'0'} value=''>
                              Zgjedhni projektin tuaj...
                            </option>
                            {this.state.projects.map((element) => {
                              return (
                                <option key={element._id} value={element._id}>
                                  {element.name}
                                </option>
                              )
                            })}
                          </Input>
                          <span className='invalid-feedback'>Fusha është e nevojshme</span>
                        </Col>
                      </div>
                    </fieldset>

                    <fieldset>
                      <div className='form-group row align-items-center'>
                        <label className='col-md-2 col-form-label'>Departamenti :</label>
                        <Col md={8}>
                          <Select
                            hasError={true}
                            placeholder='Selekto Departamentet'
                            name='departmentSelected'
                            isMulti
                            simpleValue
                            value={this.state.formUser.departmentSelected}
                            options={this.state.departments}
                            onChange={this.handleChangeDepartment}
                          />
                          {this.state.formUser.departmentSelected.length == 0 &&
                          this.state.formUser.user_level != 1 &&
                          this.state.departmentError ? (
                            <span style={{ color: 'red', fontSize: 12 }}>Zgjedh se paku nje Departament</span>
                          ) : null}
                        </Col>
                      </div>
                    </fieldset>

                    {this.state.formUser.user_level == 1 ||
                    this.state.formUser.user_level == 5 ||
                    this.state.formUser.user_level == 6 ||
                    this.state.formUser.user_level == 7 ||
                    this.state.formUser.user_level == 8 ||
                    this.state.formUser.is_project_manager ? null : (
                      <fieldset>
                        <div className='form-group row align-items-center'>
                          <label className='col-md-2 col-form-label'>Project Manager :</label>
                          <Col md={8}>
                            <Input
                              type='select'
                              name='project_manager_selected'
                              invalid={this.hasError('formUser', 'project_manager_selected')}
                              onChange={this.validateOnChange}
                              value={this.state.formUser.project_manager_selected}
                              placeholder=''
                            >
                              <option key={'0'} value=''>
                                Zgjedhni Managerin tuaj...
                              </option>
                              {this.state.project_managers.map((element) => {
                                return (
                                  <option key={element._id} value={element._id}>
                                    {`${element.first_name} ${element.last_name}`}
                                  </option>
                                )
                              })}
                            </Input>
                            <span className='invalid-feedback'>Fusha është e nevojshme</span>
                          </Col>
                        </div>
                      </fieldset>
                    )}
                    <fieldset>
                      <div className='form-group row align-items-center'>
                        <label className='col-md-2 col-form-label'>Manager :</label>
                        <Col md={8}>
                          <Input
                            type='select'
                            name='managerSelected'
                            invalid={this.hasError('formUser', 'managerSelected', 'required')}
                            onChange={this.validateOnChange}
                            data-validate='["required"]'
                            value={this.state.formUser.managerSelected}
                            placeholder=''
                          >
                            <option key={'0'} value=''>
                              Zgjedhni Managerin tuaj...
                            </option>
                            {this.state.managers.map((element) => {
                              return (
                                <option key={element._id} value={element._id}>
                                  {`${element.first_name} ${element.last_name}`}
                                </option>
                              )
                            })}
                          </Input>
                          <span className='invalid-feedback'>Fusha është e nevojshme</span>
                        </Col>
                      </div>
                    </fieldset>
                    {this.state.formUser.user_level == 1 ||
                    this.state.formUser.user_level == 2 ||
                    this.state.formUser.user_level == 3 ||
                    this.state.formUser.user_level == 5 ||
                    this.state.formUser.user_level == 6 ? null : (
                      <fieldset>
                        <div className='form-group row align-items-center'>
                          <label className='col-md-2 col-form-label'>Team Leader :</label>
                          <Col md={8}>
                            <Input
                              type='select'
                              disabled={
                                this.state.formUser.user_level == 1 ||
                                this.state.formUser.user_level == 5 ||
                                this.state.formUser.user_level == 6
                                  ? true
                                  : false || this.state.formUser.user_level == 2
                                    ? true
                                    : false || this.state.formUser.user_level == 3
                                      ? true
                                      : false
                              }
                              name='teamLeaderSelected'
                              invalid={this.hasError('formUser', 'teamLeaderSelected')}
                              onChange={this.validateOnChange}
                              value={this.state.formUser.teamLeaderSelected}
                              placeholder=''
                            >
                              <option key={'0'} value=''>
                                Zgjedhni team leaderin tuaj...
                              </option>
                              {this.state.teamLeaders.map((element) => {
                                return (
                                  <option key={element._id} value={element._id}>
                                    {`${element.first_name} ${element.last_name}`}
                                  </option>
                                )
                              })}
                            </Input>
                            <span className='invalid-feedback'>Fusha është e nevojshme</span>
                          </Col>
                        </div>
                      </fieldset>
                    )}
                    <fieldset>
                      <div className='form-group row align-items-center'>
                        <label className='col-md-2 col-form-label'>Funksioni :</label>
                        <Col md={8}>
                          <Input
                            type='select'
                            name='functionSelected'
                            invalid={this.hasError('formUser', 'functionSelected', 'required')}
                            onChange={this.validateOnChange}
                            data-validate='["required"]'
                            value={this.state.formUser.functionSelected}
                            placeholder=''
                          >
                            <option key={'0'} value=''>
                              Zgjedhni Funksionin tuaj...
                            </option>
                            {this.state.funksionet.map((element) => {
                              return (
                                <option key={element._id} value={element._id}>
                                  {element.name}
                                </option>
                              )
                            })}
                          </Input>
                          <span className='invalid-feedback'>Fusha është e nevojshme</span>
                        </Col>
                      </div>
                    </fieldset>
                    <fieldset>
                      <div className='form-group row align-items-center'>
                        <label className='col-md-2 col-form-label'>Gjuhet :</label>
                        <div className='col-md-8'>
                          {this.state.languages.map((item, index) => {
                            return (
                              <div key={index} className='card b mb-8'>
                                <CardHeader>
                                  <CardTitle>
                                    <div className='checkbox c-checkbox'>
                                      <label>
                                        <Input
                                          key={item._id}
                                          name={item.name}
                                          checked={item.checked}
                                          onChange={() => this.changeLanguage(index)}
                                          type='checkbox'
                                          value={item._id || ''}
                                        />
                                        <span className='fa fa-check' />
                                        {item.name}
                                      </label>
                                    </div>
                                  </CardTitle>
                                </CardHeader>
                                <Collapse isOpen={item.checked}>
                                  <CardBody id='collapse01'>
                                    <Input
                                      key={item._id}
                                      type='select'
                                      name='level'
                                      onChange={(e) => {
                                        item['level'] = e.target.value
                                      }}
                                      defaultValue={item.level || ''}
                                      placeholder=''
                                    >
                                      <option key={'0'} value=''>
                                        Zgjedhni Nivelin e Gjuhes...
                                      </option>
                                      <option key={'A1'} value='A1'>
                                        A1
                                      </option>
                                      <option key={'A2'} value='A2'>
                                        A2
                                      </option>
                                      <option key={'B1'} value='B1'>
                                        B1
                                      </option>
                                      <option key={'B2'} value='B2'>
                                        B2
                                      </option>
                                      <option key={'C1'} value='C1'>
                                        C1
                                      </option>
                                      <option key={'C2'} value='C2'>
                                        C2
                                      </option>
                                    </Input>
                                  </CardBody>
                                </Collapse>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </fieldset>
                    <fieldset>
                      <div className='form-group row mb'>
                        <label className='col-md-2 col-form-label mb'>Certifikata nen hetime: </label>
                        <Col className='d-flex align-items-center' md={8}>
                          <div className='checkbox c-checkbox'>
                            <label>
                              <Input
                                onChange={this.validateOnChange}
                                name='investigationCertificate'
                                type='checkbox'
                                checked={this.state.formUser.investigationCertificate}
                                value={this.state.formUser.investigationCertificate}
                              />
                              <span className='fa fa-check' />
                              {''}
                            </label>
                          </div>
                        </Col>
                      </div>
                    </fieldset>
                    <fieldset>
                      <div className='form-group row mb'>
                        <label className='col-md-2 col-form-label mb'>Certifikata shendetesore: </label>
                        <Col className='d-flex align-items-center' md={8}>
                          <div className='checkbox c-checkbox'>
                            <label>
                              <Input
                                onChange={this.validateOnChange}
                                name='healthCertificate'
                                type='checkbox'
                                checked={this.state.formUser.healthCertificate}
                                value={this.state.formUser.healthCertificate}
                              />
                              <span className='fa fa-check' />
                              {''}
                            </label>
                          </div>
                        </Col>
                      </div>
                    </fieldset>
                    <fieldset>
                      <div className='form-group row'>
                        <label className='col-xl-2 col-md-3 col-4 col-form-label mb' htmlFor='healthSafetyCertificate'>
                          SSHP
                        </label>
                        <div className='col-xl-10 col-md-9 col-8 d-flex align-items-center'>
                          <div className='checkbox c-checkbox'>
                            <label>
                              <Input
                                onChange={this.validateOnChange}
                                name='healthSafetyCertificate'
                                type='checkbox'
                                checked={this.state.formUser.healthSafetyCertificate}
                                value={this.state.formUser.healthSafetyCertificate}
                              />
                              <span className='fa fa-check' />
                              {''}
                            </label>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset>
                      <div className='form-group row mb'>
                        <label className='col-md-2 col-form-label mb'>Dita e pare: </label>
                        <Col md={8}>
                          <DateTimeBoostrap
                            value={this.state.first_call}
                            onChange={this._onFirstCallChange}
                            utc={true}
                            dateFormat={'DD/MM/YYYY'}
                            onBlur={this._onFirstCallBlur}
                            timeFormat={false}
                            inputProps={{
                              className: 'form-control',
                              name: 'first_call',
                              autoComplete: 'off'
                            }}
                          />
                        </Col>
                      </div>
                    </fieldset>
                    <fieldset>
                      <div className='form-group row align-items-center'>
                        <label className='col-md-2 col-form-label'>Rroga baze :</label>
                        <Col md={8}>
                          <InputGroup>
                            <Input
                              type='number'
                              name='salary'
                              step='0.01'
                              onChange={this.validateOnChange}
                              value={this.state.formUser.salary}
                              placeholder=''
                            />
                            <InputGroupAddon addonType='append'>
                              <Button onClick={() => this._incrementSalary('+')} className='font-weight-bold'>
                                +
                              </Button>
                              <Button onClick={() => this._incrementSalary('-')} className='font-weight-bold'>
                                -
                              </Button>
                            </InputGroupAddon>
                          </InputGroup>
                          {this.hasError('formUser', 'salary', 'required') && (
                            <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                          )}
                          {this.hasError('formUser', 'salary', 'number') && (
                            <span className='invalid-feedback'>Ju lutem shenoni numer</span>
                          )}
                        </Col>
                      </div>
                    </fieldset>
                    <fieldset>
                      <div className='form-group row align-items-center'>
                        <label className='col-md-2 col-form-label'>Skill Bonus :</label>
                        <Col md={8}>
                          <InputGroup>
                            <Input
                              type='number'
                              name='skill_bonus'
                              onChange={this.validateOnChange}
                              value={this.state.formUser.skill_bonus}
                              placeholder=''
                            />
                            <InputGroupAddon addonType='append'>
                              <Button onClick={() => this._incrementBonus('+')} className='font-weight-bold'>
                                +
                              </Button>
                              <Button onClick={() => this._incrementBonus('-')} className='font-weight-bold'>
                                -
                              </Button>
                            </InputGroupAddon>
                          </InputGroup>
                          {this.hasError('formUser', 'skill_bonus', 'required') && (
                            <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                          )}
                          {this.hasError('formUser', 'skill_bonus', 'number') && (
                            <span className='invalid-feedback'>Ju lutem shenoni numer</span>
                          )}
                        </Col>
                      </div>
                    </fieldset>
                    <fieldset>
                      <div className='form-group row align-items-center'>
                        <label className='col-md-2 col-form-label'>Koha e punes se punetorit:</label>
                        <Col md={8}>
                          <Input
                            type='select'
                            name='work_percentage'
                            onChange={this.validateOnChange}
                            value={this.state.formUser.work_percentage || ''}
                            className='custom-select custom-select'
                          >
                            <option key={'0'} value=''>
                              Zgjedh kohen e punes se punetorit
                            </option>
                            {usersWorkHoursPercentage.map((item, i) => (
                              <option key={i + 1} value={item.percentage}>
                                {item.value}
                              </option>
                            ))}
                          </Input>
                          <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                        </Col>
                      </div>
                    </fieldset>
                    <fieldset>
                      <div className='form-group row align-items-center'>
                        <label className='col-md-2 col-form-label'>Punëdhënësi</label>
                        <Col md={8}>
                          <Input
                            type='select'
                            name='employeer'
                            onChange={this.validateOnChange}
                            value={this.state.formUser.employeer || 'Primar'}
                            className='custom-select custom-select'
                          >
                            <option key={'1'} value='Primar'>
                              Primar
                            </option>
                            <option key={'2'} value='Sekondar'>
                              Sekondar
                            </option>
                          </Input>
                          <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                        </Col>
                      </div>
                    </fieldset>
                    <fieldset>
                      <div className='form-group row align-items-center'>
                        <label className='col-md-2 col-form-label'>Lokacioni i punës</label>
                        <Col md={8}>
                          <Input
                            type='select'
                            name='office_location'
                            onChange={this.validateOnChange}
                            value={this.state.office_location}
                            className='custom-select custom-select'
                          >
                            <option key={'0'} value={''}>
                              Zgjedhni lokacionin e punës
                            </option>
                            <option key={'1'} value={'Home Office'}>
                              Home Office
                            </option>
                            <option key={'2'} value={'Prishtinë'}>
                              Prishtinë
                            </option>
                            <option key={'3'} value={'Prishtinë HQ'}>
                              Prishtinë HQ
                            </option>
                            <option key={'4'} value={'Vushtrri'}>
                              Vushtrri
                            </option>
                            <option key={'5'} value={'Ferizaj'}>
                              Ferizaj
                            </option>
                            <option key={'6'} value={'Prizren'}>
                              Prizren
                            </option>
                          </Input>
                          <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                        </Col>
                      </div>
                    </fieldset>
                    <fieldset>
                      <div className='form-group row align-items-center'>
                        <label className='col-md-2 col-form-label'>Statusi i punës</label>
                        <Col md={8}>
                          <Input
                            type='select'
                            name='workingStatusSelected'
                            onChange={this.validateOnChange}
                            value={this.state.workingStatusSelected}
                            className='custom-select custom-select'
                          >
                            <option key={'0'} value=''>
                              Zgjedhni Statusin e Punës...
                            </option>
                            {this.state.working_status.map((el) => {
                              return (
                                <option key={el._id} value={el._id}>
                                  {el.name}
                                </option>
                              )
                            })}
                          </Input>
                          <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                        </Col>
                      </div>
                    </fieldset>
                    <fieldset>
                      <div className='form-group row align-items-center'>
                        <label className='col-md-2 col-form-label'>Llogaria e Trustit: :</label>
                        <Col md={10}>
                          <div className='d-flex align-items-center'>
                            <label className='switch'>
                              <input
                                name='trust_account'
                                onChange={this.validateOnChange}
                                value={this.state.formUser.trust_account}
                                type='checkbox'
                                checked={this.state.formUser.trust_account}
                              />
                              {this.state.formUser.trust_account ? (
                                <span
                                  style={{
                                    backgroundColor: '#FF902B',
                                    borderColor: '#FF902B'
                                  }}
                                  className='invalid-feedback'
                                />
                              ) : (
                                <span className='invalid-feedback' />
                              )}
                            </label>
                          </div>
                        </Col>
                      </div>
                    </fieldset>
                    <fieldset>
                      <div className='form-group row align-items-center'>
                        <label className='col-md-2 col-form-label'>Llogaritet Trusti në Pagë :</label>
                        <Col md={10}>
                          <div className='d-flex align-items-center'>
                            <label className='switch'>
                              <input
                                name='should_include_trust'
                                onChange={this.validateOnChange}
                                value={this.state.formUser.should_include_trust}
                                type='checkbox'
                                checked={this.state.formUser.should_include_trust}
                              />
                              {this.state.formUser.should_include_trust ? (
                                <span
                                  style={{
                                    backgroundColor: '#FF902B',
                                    borderColor: '#FF902B'
                                  }}
                                  className='invalid-feedback'
                                />
                              ) : (
                                <span className='invalid-feedback' />
                              )}
                            </label>
                          </div>
                        </Col>
                      </div>
                    </fieldset>
                    <fieldset>
                      <div className='form-group row mb'>
                        <label className='col-md-2 col-form-label mb'>Data e lindjes : </label>
                        <Col md={8}>
                          <DateTimeBoostrap
                            value={this.state.birthDate}
                            onChange={this._onBirthDateChange}
                            utc={true}
                            dateFormat={'DD/MM/YYYY'}
                            onBlur={this._onBirthDateBlur}
                            timeFormat={false}
                            inputProps={{
                              className: 'form-control',
                              name: 'birthDate',
                              autoComplete: 'off'
                            }}
                          />
                        </Col>
                      </div>
                    </fieldset>
                    <fieldset>
                      <div className='form-group row mb'>
                        <label className='col-md-2 col-form-label mb'>Ditë pune para ksaj date : </label>
                        <Col md={8}>
                          <InputGroup>
                            <Input
                              type='number'
                              name='accumulated_leaves'
                              onChange={this.validateOnChange}
                              value={this.state.formUser.accumulated_leaves}
                              placeholder=''
                              invalid={this.hasError('formUser', 'accumulated_leaves', 'required')}
                              data-validate='["required"]'
                            />
                          </InputGroup>
                          {this.hasError('formUser', 'accumulated_leaves', 'required') && (
                            <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                          )}
                        </Col>
                      </div>
                    </fieldset>
                    <fieldset>
                      <div className='form-group row align-items-center'>
                        <label className='col-md-2 col-form-label'>Statusi martesor</label>
                        <Col md={8}>
                          <Input
                            type='select'
                            name='maritalStatusSelected'
                            onChange={this.validateOnChange}
                            value={this.state.formUser.maritalStatusSelected}
                            className='custom-select custom-select'
                            data-validate='["required"]'
                            invalid={this.hasError('formUser', 'maritalStatusSelected', 'required')}
                          >
                            <option key={'0'} value=''>
                              Zgjedhni Statusin Martesor...
                            </option>
                            <option key={'1'} value={'Beqar/e'}>
                              Beqar/e
                            </option>
                            <option key={'2'} value={'I/e martuar'}>
                              I/e martuar
                            </option>
                            <option key={'3'} value={'I/e divorcuar'}>
                              I/e divorcuar
                            </option>
                            <option key={'4'} value={'I/e ve'}>
                              I/e ve
                            </option>
                          </Input>
                          <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                        </Col>
                      </div>
                    </fieldset>
                    <fieldset>
                      <div className='form-group row align-items-center'>
                        <label className='col-md-2 col-form-label'>Aktiv? :</label>
                        <Col md={10}>
                          <div className='d-flex align-items-center'>
                            <label className='switch'>
                              <input
                                name='is_active'
                                onChange={this.validateOnChange}
                                value={this.state.formUser.is_active}
                                type='checkbox'
                                checked={this.state.formUser.is_active}
                              />
                              <span className='invalid-feedback' />
                            </label>
                          </div>
                        </Col>
                      </div>
                    </fieldset>
                  </CardBody>
                  <CardFooter className='text-right'>
                    <button type='submit' className='btn btn-info'>
                      Ruaj
                    </button>
                  </CardFooter>
                </Card>
              </form>
            </div>
          </Row>
        )}
      </ContentWrapper>
    )
  }
}

export default EditUsers
