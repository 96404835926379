import { inject } from 'mobx-react'
import 'moment/locale/sq' // without this line it didn't work
import React, { Fragment } from 'react'
import { Alert, Button, CardFooter, Col, Input, InputGroup, InputGroupAddon } from 'reactstrap'
import DateTimeBoostrap from '../../components/Forms/DateTimeBootstrap'

let hasDeleteButton = false
@inject('userStore')
class LeaveForm extends React.Component {
  renderLeaveType() {
    const items = this.props.leaveTypes.map((element, index) => {
      return (
        <option key={index} value={element._id}>
          {element.name}
        </option>
      )
    })

    return (
      <fieldset>
        <div className='form-group row align-items-center'>
          <label className='col-md-2 col-form-label'>Kategoria:</label>
          <div className='col-md-8'>
            <Input
              type='select'
              name='type'
              disabled='disabled'
              data-validate='["required"]'
              value={this.props.type}
              className='custom-select custom-select'
            >
              <option value=''>Zgjidhni Kategorine...</option>
              {items}
            </Input>
            <span className='invalid-feedback'>Fusha është e nevojshme</span>
          </div>
        </div>
      </fieldset>
    )
  }

  renderLabel() {
    let color
    switch (this.props.labelStatus) {
      case 'Pending':
        color = 'badge bg-yellow'
        hasDeleteButton = false
        break
      case 'Approved':
        color = 'badge badge-success'
        hasDeleteButton = false
        break
      case 'Rejected':
        color = 'badge badge-danger'
        hasDeleteButton = true
        break
      case 'Partially approved':
        hasDeleteButton = false
        color = 'badge badge-warning'
        break
    }
    switch (this.props.userLevel) {
      case 4:
        hasDeleteButton = false
        break
    }
    return (
      <fieldset>
        <div className='form-group row align-items-center'>
          <label className='col-md-2 col-form-label'>Statusi</label>
          <Col md={8}>
            <span className={color}>{this.props.labelStatus}</span>
          </Col>
        </div>
      </fieldset>
    )
  }

  render() {
    const user_Level = this.props.userStore.userLevel

    return (
      <form onSubmit={this.props.onSubmit} name='formNewLeave'>
        <div className='card b w-100'>
          <div className='card-header d-flex align-items-center'>
            <div className='d-flex justify-content-center col'>
              <div className='h4 m-0 text-center'>Shqyrto Pushimin</div>
            </div>
          </div>
          <div className='card-body text-left'>
            {this.renderLabel()}
            <fieldset>
              <div className='form-group row align-items-center'>
                <label className='col-md-2 col-form-label'>Prej</label>
                <Col md={8}>
                  <DateTimeBoostrap
                    value={this.props.dateFrom}
                    utc={true}
                    dateFormat={'DD/MM/YYYY'}
                    timeFormat=''
                    inputProps={{
                      id: 'dateFrom',
                      disabled: 'disabled',
                      'data-validate': '["validDate"]',
                      className: 'form-control',
                      name: 'dateFrom'
                    }}
                  />
                </Col>
              </div>
            </fieldset>
            <fieldset>
              <div className='form-group row align-items-center'>
                <label className='col-md-2 col-form-label'>Deri</label>
                <Col md={8}>
                  <DateTimeBoostrap
                    value={this.props.dateTo}
                    utc={true}
                    dateFormat={'DD/MM/YYYY'}
                    timeFormat=''
                    inputProps={{
                      disabled: 'disabled',
                      'data-param': 'dateFrom',
                      'data-validate': '["dateIsAfter"]',
                      className: 'form-control',
                      name: 'dateTo'
                    }}
                  />
                </Col>
              </div>
            </fieldset>
            <fieldset>
              <div className='form-group row align-items-center'>
                <label className='col-md-2 col-form-label'>Data e paraqitjes në punë</label>
                <Col md={8}>
                  <DateTimeBoostrap
                    value={this.props.returnToWork}
                    onChange={this.props.returnToWorkChanged}
                    utc={true}
                    dateFormat={'DD/MM/YYYY'}
                    timeFormat=''
                    closeOnSelect
                    name='returnToWork'
                    inputProps={{
                      required: true,
                      placeholder: 'Zgjedh datën e paraqitjes në punë',
                      className: 'form-control'
                    }}
                  />
                </Col>
              </div>
            </fieldset>
            <fieldset>
              <div className='form-group row align-items-center'>
                <label className='col-md-2 col-form-label'>Ditë pushimi:</label>
                <Col md={8}>
                  <InputGroup>
                    <Input type='number' disabled='disabled' name='days' value={this.props.days} />
                    {[1, 5].includes(user_Level) && (
                      <InputGroupAddon addonType='append'>
                        <Button disabled={this.props.days <= 1} onClick={this.props.removeLeaveDays} className='font-weight-bold'>
                          -
                        </Button>
                      </InputGroupAddon>
                    )}
                    <InputGroupAddon addonType='append'>
                      <Button onClick={this.props.addLeaveDays} className='font-weight-bold'>
                        +
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                  <span className='invalid-feedback'>Fusha është e nevojshme</span>
                </Col>
              </div>
            </fieldset>
            {this.renderLeaveType()}
            <fieldset>
              <div className='form-group row align-items-center'>
                <label className='col-md-2 col-form-label'>Informata Shtesë:</label>
                <Col md={8}>
                  <Input disabled='disabled' type='text' name='otherInfo' value={this.props.otherInfo} />
                  <span className='invalid-feedback'>Fusha është e nevojshme</span>
                </Col>
              </div>
            </fieldset>
            <fieldset>
              <div className='form-group row align-items-center'>
                <label className='col-md-2 col-form-label'>Shenim:</label>
                <Col md={8}>
                  <Input type='textarea' name='message' value={this.props.message} onChange={this.props.messageChanged} />
                  <span className='invalid-feedback'>Fusha është e nevojshme</span>
                </Col>
              </div>
            </fieldset>
          </div>
          <CardFooter className='text-right'>
            {this.props.labelStatus == 'Approved' && user_Level == 3 ? (
              <Alert className={'text-center'} color={'success'}>
                Pushimi është aprovuar
              </Alert>
            ) : (
              <Fragment>
                {hasDeleteButton && this.props.userStore.userLevel == 1 && (
                  <Button style={{ marginRight: 10 }} color='danger' onClick={this.props.delete}>
                    Fshij
                  </Button>
                )}
                <Button color='danger' onClick={this.props.disApprove}>
                  Refuzo
                </Button>
                <button style={{ marginLeft: 10 }} type='submit' className='btn btn-info'>
                  Aprovo
                </button>
              </Fragment>
            )}
          </CardFooter>
        </div>
      </form>
    )
  }
}

export default LeaveForm
