import axios from 'axios'
import buildQueryString from './../utils/buildQueryString'
import { saveAs } from 'file-saver'
import { toast } from 'react-toastify'
import moment from 'moment'

const downloadCsv = (from, to, search, project, departments, office_locations) => {
  let query = buildQueryString(from, to, search, project, departments, office_locations)
  axios
    .get(`/resigns/downloadCSV?${query}`, {
      responseType: 'blob'
    })
    .then((response) => {
      if (response.headers['content-type'] == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        saveAs(response.data, `Lista e dorëheqjeve ${moment(Date.now()).format('DD-MM-YYYY')}.csv`)
        toast.success('Te dhenat u shkarkuan!')
      }
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message ?? 'Ndodhi nje gabim!')
    })
}

export default downloadCsv
