import axios from 'axios'
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'

const DeleteDialogBox = ({ path, name, goBack }) => {
  Swal.fire({
    title: 'A jeni i sigurt?',
    text: `Nëse fshini ${name}, të gjitha të dhënat do të fshihen!`,
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#33cc33',
    confirmButtonText: 'Po, fshije.',
    cancelButtonText: 'Jo.'
  }).then((result) => {
    if (result.value) {
      axios
        .delete(path)
        .then((response) => {
          if (response.data.success) {
            Swal.fire('E fshire!', 'Të dhënat janë fshirë me sukses.', 'success').then(goBack)
          } else {
            Swal.fire('Anuluar', 'Të dhënat nuk janë fshirë', 'error')
          }
        })
        .catch((e) => {
          Swal.fire('Anuluar', 'Të dhënat nuk janë fshirë', 'error')
        })
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire('Anuluar', 'Të dhënat nuk janë fshirë', 'error')
    }
  })
}

const ChangePasswordDialog = async () => {
  const steps = ['1', '2', '3']

  const questions = ['', 'Fjalekalimi aktual', 'Fjalekalimi i ri', 'Perseritni fjalekalimin e ri']

  const texts = [
    '',
    'Ju lutem shënoni fjalëkalimin aktual.',
    'Ju lutem shënoni fjalëkalimin e ri.',
    'Që te sigurohemi se keni shkruar fjalëkalimin saktë, përsëriteni edhe një herë'
  ]

  const swalQueueStep = Swal.mixin({
    input: 'password',
    confirmButtonText: 'Vazhdo &rarr;',
    confirmButtonColor: '#3085d6',
    cancelButtonText: 'Anulo',
    progressSteps: steps
  })

  const values = []
  let currentStep

  for (currentStep = 0; currentStep < steps.length; ) {
    const result = await swalQueueStep.fire({
      title: `${questions[steps[currentStep]]}`,
      text: `${texts[steps[currentStep]]}`,
      inputValue: values[currentStep],
      showCancelButton: true,
      currentProgressStep: currentStep
    })

    if (result.value) {
      values[currentStep] = result.value
      currentStep++

      if (values.length == 3) {
        if (values[1] !== values[2]) {
          Swal.fire('Anuluar', 'Fjalëkalimet e reja nuk përputhen', 'error')
        } else {
          const postData = {
            old_password: values[0],
            password1: values[1],
            password2: values[2]
          }

          axios
            .post('/users/updatePassword', postData)
            .then((response) => {
              if (response.data.success) {
                Swal.fire('Sukses', 'Fjalëkalimi i ri është vendosur për llogarinë tuaj.', 'success')
              } else {
                Swal.fire('Anuluar', response.data.message, 'error')
              }
            })
            .catch((e) => {
              Swal.fire('Anuluar', 'Gabim gjatë komunikimit me server', 'error')
            })
        }
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      break
    } else {
      break
    }
  }
}

export { ChangePasswordDialog, DeleteDialogBox }
