export const pinnedDocs = [
  {
    label: 'Kontrata e punës',
    fileKey: 'Kontrata_e_punes_2024'
  },
  {
    label: 'Marrëveshja e konfidencialitetit',
    fileKey: 'Marreveshja_e_konfidencialitetit'
  },
  {
    label: 'Rregullorja e punës për kompaninë SPEEEX SH.P.K',
    fileKey: 'Rregullorja_e_punes_per_kompanine'
  },
  {
    label: 'Politikat e sigurisë së informacionit',
    fileKey: 'Information_Security_Policy'
  },
  {
    label: 'Mbrojtja e te dhenave personale',
    fileKey: 'Mbrojtja_e_te_dhenave_personale'
  },
  {
    label: 'Politikat e tavolinës së pastër (Clean Desk)',
    fileKey: 'Clean_Desk_Policy'
  },
  {
    label: 'Standardet Minimale',
    fileKey: 'Standardet_minimale'
  },
  {
    label: 'Detyrat dhe përgjegjësitë e punës',
    fileKey: 'Detyrat_dhe_pergjegjesite_e_punes'
  },
  {
    label: 'Procesi i trajtimit të kërkesave për punë nga shtëpia (Home Office)',
    fileKey: 'Training_home_office'
  }
]
