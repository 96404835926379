import axios from 'axios'
import pubsub from 'pubsub-js'
import React from 'react'
import 'react-datetime/css/react-datetime.css'
import { Button, Card, CardBody, CardFooter, Col, Input, Row } from 'reactstrap'
import { DeleteDialogBox } from '../../Common/'
import FormValidator from '../../Forms/FormValidator.js'
import ContentWrapper from '../../Layout/ContentWrapper'

class EditRoles extends React.Component {
  constructor() {
    super()
    this.state = {
      isEdit: false,
      formRole: {
        nameRoles: ''
      }
    }
  }

  validateOnChange = (event) => {
    const input = event.target
    const form = input.form
    const value = input.type === 'checkbox' ? input.checked : input.value
    const result = FormValidator.validate(input)

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    })
  }

  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    )
  }

  deleteButton(id) {
    const goBack = () => this.props.history.goBack()
    const path = `roles/${id}`
    const name = 'Poziten'
    DeleteDialogBox({ path, name, goBack })
  }

  onSubmit = (e) => {
    const ID = this.props.match.params.idRoles
    const form = e.target
    const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT'].includes(i.nodeName))

    const { errors, hasError } = FormValidator.bulkValidate(inputs)
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      }
    })

    if (!hasError) {
      const postData = {
        name: this.state.formRole.nameRoles
      }
      if (ID) {
        axios
          .put(`roles/${ID}`, postData)
          .then((res) => {
            if (res.data.success) {
              this.props.history.push('/roles')
              pubsub.publish('showNotification', {
                message: 'Të dhënat janë ndryshuar!',
                type: 'success'
              })
            } else {
              pubsub.publish('showNotification', {
                message: res.data.message,
                type: 'error'
              })
            }
          })

          .catch((err) => {
            pubsub.publish('showNotification', {
              message: 'Gabim gjatë ruajtjes së të dhënave',
              type: 'error'
            })
          })
      } else {
        axios
          .post('roles', postData)
          .then((res) => {
            if (res.data.success) {
              pubsub.publish('showNotification', {
                message: 'Të dhënat janë ruajtur!',
                type: 'success'
              })
              this.props.history.push('/roles')
            } else {
              pubsub.publish('showNotification', {
                message: res.data.message,
                type: 'error'
              })
            }
          })
          .catch((err) => {
            pubsub.publish('showNotification', {
              message: 'Gabim gjatë ruajtjes të të dhënave',
              type: 'error'
            })
          })
      }
    }

    e.preventDefault()
  }

  async componentDidMount() {
    const idRoles = this.props.match.params.idRoles
    if (idRoles) {
      try {
        const res = await axios.get('roles/' + idRoles, {})
        if (res.data.success) {
          this.setState({
            isEdit: true,
            formRole: {
              nameRoles: res.data.data.name
            }
          })
        }
      } catch (error) {}
    }
  }

  render() {
    const id = this.props.match.params.idRoles
    return (
      <ContentWrapper>
        {this.state.isEdit ? (
          <div className='d-flex justify-content-between'>
            <p style={{ fontSize: 23, color: 'gray' }}>Modifiko pozitën</p>
            <div>
              <Button onClick={() => this.props.history.goBack()} color='info' className='btn-labeled'>
                <span className='btn-label'>
                  <i className='fa fa-arrow-left' />
                </span>{' '}
                Prapa
              </Button>
            </div>
          </div>
        ) : (
          <div className='d-flex justify-content-between'>
            <p style={{ fontSize: 23, color: 'gray' }}>Shton pozitë</p>
            <div>
              <Button onClick={() => this.props.history.goBack()} color='info' className='btn-labeled'>
                <span className='btn-label'>
                  <i className='fa fa-arrow-left' />
                </span>{' '}
                Prapa
              </Button>
            </div>
          </div>
        )}
        <Row>
          <div className='col-md-12'>
            <form onSubmit={this.onSubmit} name='formRole'>
              {/* START card */}
              <Card className='card-default'>
                <CardBody>
                  <legend className='mb-4' />
                  <fieldset>
                    <div className='form-group row align-items-center'>
                      <label className='col-md-2 col-form-label'>Emri i pozitës :</label>
                      <Col md={6}>
                        <Input
                          key='nameRoles'
                          type='text'
                          name='nameRoles'
                          invalid={this.hasError('formRole', 'nameRoles', 'required')}
                          onChange={this.validateOnChange}
                          value={this.state.formRole.nameRoles}
                          data-validate='["required"]'
                        />
                        <span className='invalid-feedback'>Fusha është e nevojshme</span>
                      </Col>
                    </div>
                  </fieldset>
                </CardBody>
                {this.state.isEdit ? (
                  <CardFooter className='text-right'>
                    <button style={{ marginRight: 10 }} type='submit' className='btn btn-info'>
                      Ruaj / Modifiko
                    </button>
                    <Button
                      color='danger'
                      onClick={() => {
                        this.deleteButton(id)
                      }}
                    >
                      Fshije
                    </Button>
                  </CardFooter>
                ) : (
                  <CardFooter className='text-right'>
                    <button type='submit' className='btn btn-info'>
                      Ruaj
                    </button>
                  </CardFooter>
                )}
              </Card>
              {/* END card */}
            </form>
          </div>
        </Row>
      </ContentWrapper>
    )
  }
}

export default EditRoles
