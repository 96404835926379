import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button, Table } from 'reactstrap'
import { BASE_API } from './../../utils/consts'

const BlacklistComponent = ({ id }) => {
  const history = useHistory()
  const [blacklistPerson, setBlacklistPerson] = useState({})

  useEffect(() => {
    const fetchBlacklistPerson = async () => {
      try {
        const response = await axios.get(`${BASE_API}/blacklist/${id}`)
        if (response.data.status) {
          setBlacklistPerson(response.data.data)
        }
      } catch (error) {
        history.push('/blacklists')
        toast.error(error?.response?.data?.message ?? 'Ndodhi nje gabim!')
      }
    }
    fetchBlacklistPerson()
  }, [id])

  const renderTableRow = (label, value) => {
    return (
      value && (
        <tr>
          <td>
            <strong>{label}:</strong>
          </td>
          <td>
            <p>{value}</p>
          </td>
        </tr>
      )
    )
  }

  return (
    <div>
      <div className='content-heading d-flex justify-content-between'>
        <div className='d-flex align-items-center'>{blacklistPerson && `${blacklistPerson?.first_name} ${blacklistPerson?.last_name}`}</div>
        <div>
          <Button style={{ marginLeft: 10 }} type='button' onClick={() => history.push('/blacklists')} color='primary'>
            Prapa
          </Button>
        </div>
      </div>
      <div className='card card-default'>
        <div className='card-body'>
          <div className='py-2'>
            <div className='table-responsive'>
              <Table style={{ tableLayout: 'fixed' }}>
                <tbody>
                  {renderTableRow('Emri', blacklistPerson?.first_name)}
                  {renderTableRow('Mbiemri', blacklistPerson?.last_name)}
                  {renderTableRow('Numri i personal', blacklistPerson?.idNumber)}
                  {renderTableRow('Email', blacklistPerson?.email)}
                  {renderTableRow('Shtuar nga platforma', blacklistPerson?.insert_from)}
                  {blacklistPerson?.added_from && (
                    <tr>
                      <td>
                        <strong>Shtuar nga personi:</strong>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <p style={{ marginBottom: '0px' }}>
                            {`${blacklistPerson?.added_from.first_name} ${blacklistPerson?.added_from.last_name}`}
                          </p>
                          <Button
                            style={{ marginLeft: '50px' }}
                            type='button'
                            onClick={() => window.open(`/editUsers/${blacklistPerson?.added_from._id}`, '_blank')}
                            color='primary'
                            size='small'
                          >
                            Shiko profilin
                          </Button>
                        </div>
                      </td>
                    </tr>
                  )}
                  {renderTableRow('Arsyeja', blacklistPerson?.description)}
                  {renderTableRow('Shtuar më', blacklistPerson?.created_at && moment(blacklistPerson?.created_at).format('LLL'))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlacklistComponent
