import axios from 'axios'
import moment from 'moment'
import Swal from 'sweetalert2'
import { getAwsDocuments, getAwsImage } from '../../../utils/awsPaths'

export const downloadLeaveReport = async (leave) => {
  try {
    Swal.fire({
      title: 'Ju lutem prisni...',
      text: 'Duke gjeneruar dokumentin',
      didOpen: () => {
        Swal.showLoading()
      }
    })
    const postData = {
      document: 'leave_confirmation',
      leave_category_id: leave.category._id,
      leave_start: moment(leave.date_from).format('YYYY-MM-DD'),
      leave_end: moment(leave.date_to).format('YYYY-MM-DD'),
      return_to_work: leave.return_to_work ? moment(leave.return_to_work).format('YYYY-MM-DD') : null,
      last_edit_date: moment(leave.last_edit_date).format('YYYY-MM-DD'),
      created_date: moment(leave.created_date).format('YYYY-MM-DD'),
      days: leave.days
    }
    const filename = `Konfirmim_Pushimi_${leave.user_id.first_name}_${leave.user_id.last_name}.docx`
    const response = await axios.post(getAwsDocuments(leave.user_id._id, 'default'), postData)
    if (response.headers['content-type'] == 'application/json; charset=utf-8') {
      saveAs(getAwsImage(response.data.file), filename)
      Swal.close()
      Swal.fire({
        title: 'Te dhenat u shkarkuan me sukses.',
        text: 'Dokumenti eshte gjeneruar me sukses!',
        type: 'success'
      })
    }
  } catch (error) {
    console.log('error', error)
    Swal.fire({
      title: 'Gabim gjatë shkarkimit të dokumentit',
      text: 'Gabim gjatë shkarkimit të dokumentit',
      type: 'warning'
    })
  }
}
