const options = [
  { key: '1', value: 'aspak', text: 'Aspak' },
  { key: '2', value: 'pak', text: 'Pak' },
  { key: '3', value: 'mesatarisht', text: 'Mesatarisht' },
  { key: '4', value: 'mjaftueshëm', text: 'Mjaftueshëm' },
  { key: '5', value: 'shumë', text: 'Shumë' }
]
export const questions = [
  {
    name: 'Sa jeni i/e kënaqur me hapsirën e ofruar nga SPEEEX për të u përmbushur detyrat e juaja?',
    inputName: 'q0',
    type: 'radio',
    options: options,
    required: true
  },
  {
    name: 'Si kanë qenë marrëdhëniet tuaja me anëtarët e tjerë të ekipës?',
    inputName: 'q1',
    type: 'radio',
    options: options,
    required: true
  },
  {
    name: 'Si ka qenë marrëdhënia e juaj me Team Leader-in/en?',
    inputName: 'q2',
    type: 'radio',
    options: options,
    required: true
  },
  {
    name: 'Sa ka komunikuar qartë me ju Team Leader-i/ja?',
    inputName: 'q3',
    type: 'radio',
    options: options,
    required: true
  },
  {
    name: 'Sa keni pasur mbështetje nga TeamLeaderi për të u zhvilluar më tutje?',
    inputName: 'q4',
    type: 'radio',
    options: options,
    required: true
  },
  {
    name: 'Sa ju është ofruar zgjidhje në lidhje me përshtatjen/rregullimin e orarit të punës?',
    inputName: 'q5',
    type: 'radio',
    options: options,
    required: true
  },
  {
    name: 'Sa keni qenë i/e kënaqur me kompensimin financiar që keni marrë në lidhje me performancën tuaj (Paga/Bonuset)?',
    inputName: 'q6',
    type: 'radio',
    options: options,
    required: true
  },
  {
    name: 'Sa mendoni që SPEEEX ofron benefite atraktive?',
    inputName: 'q7',
    type: 'radio',
    options: options,
    required: true
  },
  {
    name: 'A mendoni që trajnimet e SPEEEX janë atraktive dhe ju mundësojnë edukim/shkollim të metutjeshëm?',
    inputName: 'q8',
    type: 'radio',
    options: [
      { key: '1', value: 'po', text: 'Po' },
      { key: '2', value: 'jo', text: 'Jo' }
    ],
    required: true
  },
  {
    name: 'Sa mendoni që potenciali për zhvillim dhe mundësitë për avancim janë të mira në SPEEEX?',
    inputName: 'q9',
    type: 'radio',
    options: options,
    required: true
  },
  {
    name: 'Çfarë do të parandalonte dorëheqjen tuaj?',
    inputName: 'q10',
    type: 'text',
    required: true
  },
  {
    name: 'Cilat janë arsyet e largimit tuaj nga SPEEEX?',
    inputName: 'q11',
    type: 'text',
    required: true
  },
  {
    name: 'Çfarë benefite ju ofron punëdhënësi i ri?',
    inputName: 'q12',
    type: 'text',
    required: true
  },
  {
    name: 'A mund të jepni disa informata në lidhje me punen tuaj të re? (emri i punëdhënësit,adresa etj)',
    inputName: 'q13',
    type: 'text',
    required: true
  },
  {
    name: 'A do të punonit në të ardhmën përsëri në SPEEEX?',
    inputName: 'q14',
    type: 'text',
    required: true
  },
  {
    name: 'Nëse keni ndonjë koment shtesë ju lutem shkruani më poshtë.',
    inputName: 'q15',
    type: 'text'
  }
]
