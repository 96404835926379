import { inject } from 'mobx-react'
import moment from 'moment'
import React from 'react'
import 'react-circular-progressbar/dist/styles.css'
import { Col, Row } from 'reactstrap'
import DateTimeBoostrap from '../../components/Forms/DateTimeBootstrap'

@inject('userStore')
class LeavesHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedDate: new Date()
    }
    this._onDateChange = this._onDateChange.bind(this)
  }

  async _onDateChange(date) {
    if (typeof date == 'object') {
      await this.setState({ selectedDate: date })
    }
    this.props.onYear(moment(this.state.selectedDate).format('YYYY'))
  }

  _onDateBlur = (date) => {
    this.setState({ selectedDate: moment(date, 'YYYY') })
  }

  messageCrop(text) {
    var limit = 18
    var trimmedString = text.length > limit ? text.slice(0, limit - 3) + '...' : text
    return trimmedString
  }

  render() {
    if (!this.props.stats) {
      return (
        <div className='card b w-100'>
          {this.props.stats !== null && (
            <div className='row row-flush'>
              <div className='col-lg col-4 py-4 d-flex align-items-center justify-content-center rounded-left' />
              <div className='col-lg-12 col-8 py-2 br d-flex align-items-center justify-content-center whirl standard' />
            </div>
          )}
          <div className='card-body text-center'>
            <Row style={{ padding: 20 }}>
              <Col>
                <Row>
                  <Col>
                    <div className='form-group row mb float-left'>
                      <label className='col-form-label pl-3 mb'>Zgjedh vitin:</label>
                      <Col md={5}>
                        <DateTimeBoostrap
                          key='selectDate'
                          value={this.state.selectedDate}
                          onChange={this._onDateChange}
                          locale='de'
                          endDate={new Date()}
                          utc={true}
                          dateFormat='YYYY'
                          onBlur={this._onDateBlur}
                          data-validate='["validDate"]'
                          timeFormat={false}
                          inputProps={{
                            'data-validate': '["validDate"]',
                            className: 'form-control',
                            name: 'data',
                            autoComplete: 'off'
                          }}
                        />
                      </Col>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xl={4} lg={4} md={12}>
                    <div className='card border-0' style={{ backgroundColor: '#CD7331', color: 'white' }}>
                      <div className='card-header' style={{ padding: '0.7rem 0.9375rem' }}>
                        <div className='row align-items-center'>
                          <div className='col-4'>
                            <em className='fas fa-calendar-check fa-5x' />
                          </div>
                          <div className='col-8 text-right'>
                            <h4 className=''>Pushim vjetor </h4>
                          </div>
                          <div className={!this.props.previous ? 'col-12 text-right previous' : 'col-12 text-right'}>
                            <div className='text-lg' style={{ fontSize: '1.8rem' }}>
                              0
                            </div>
                            <p className='m-0'>ditë të mbetura</p>
                          </div>
                        </div>
                      </div>
                      {this.props.previous && (
                        <div className='card-footer bg-gray-dark bt0 clearfix btn-block text-right'>
                          <span>Nga viti i kaluar: {' ' + 0 + ' '} ditë</span>
                          <span className='ml-auto' />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      )
    }

    const approvedStats = this?.props?.stats?.statistics?.map((stat) => (stat.status === 'Approved' ? stat : { ...stat, totalDays: 0 }))
    const lessThanSixMonthsDiffernce = moment().diff(moment(this.props.userStore.first_call), 'months') < 6

    return (
      <div className='card b w-100'>
        <div className='card-body text-center'>
          <Row style={{ padding: 20 }}>
            <Col>
              <Row>
                <Col>
                  <div className='form-group row mb float-left'>
                    <label className='col-form-label pl-3 mb'>Zgjedh vitin:</label>
                    <Col md={5}>
                      <DateTimeBoostrap
                        key='selectDate'
                        value={this.state.selectedDate}
                        onChange={this._onDateChange}
                        locale='de'
                        endDate={new Date()}
                        utc={true}
                        dateFormat='YYYY'
                        onBlur={this._onDateBlur}
                        data-validate='["validDate"]'
                        timeFormat={false}
                        inputProps={{
                          'data-validate': '["validDate"]',
                          className: 'form-control',
                          name: 'data',
                          autoComplete: 'off'
                        }}
                      />
                    </Col>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={4} lg={4} md={12}>
                  <div className='card border-0' style={{ backgroundColor: '#CD7331', color: 'white' }}>
                    <div className='card-header' style={{ padding: '0.7rem 0.9375rem' }}>
                      <div className='row align-items-center'>
                        <div className='col-4'>
                          <em className='fas fa-calendar-check fa-5x' />
                        </div>
                        <div className='col-8 text-right'>
                          <h4 className=''>Pushimi total </h4>
                        </div>
                        <div className={!this.props.previous ? 'col-12 text-right previous' : 'col-12 text-right'}>
                          <div className='text-lg' style={{ fontSize: '1.8rem' }}>
                            {(
                              this.props.stats.accumulated_leaves + this.props.stats.last_year_leaves + this.props.stats.extra_leaves || 0
                            ).toFixed(2)}
                          </div>
                          <p className='m-0'>ditë të mbetura</p>
                          {lessThanSixMonthsDiffernce && <p className='m-0'>Nuk mund te aplikoni per pushim vjetor para 6 muajve punë!</p>}
                        </div>
                      </div>
                    </div>
                    <div className='card-footer bg-gray bt0 clearfix text-dark text-right'>
                      <span>Ditë shtesë: {(this.props.stats.extra_leaves || 0).toFixed(2)} ditë</span>
                      <span className='ml-auto' />
                    </div>
                    <div className='card-footer bg-gray-dark bt0 clearfix btn-block text-right'>
                      <span>Nga viti i kaluar: {(this.props.stats.last_year_leaves || 0).toFixed(2)} ditë</span>
                      <span className='ml-auto' />
                    </div>
                    <div className='card-footer bg-gray-darker bt0 clearfix text-right'>
                      <span>Nga viti i tanishëm: {(this.props.stats.accumulated_leaves || 0).toFixed(2)} ditë</span>
                      <span className='ml-auto' />
                    </div>
                  </div>
                  {[1, 5, 6].includes(this.props.userStore.userLevel) && (
                    <div
                      className='card-footer bg-gray-darker mt-3 bt0 clearfix text-right'
                      style={{
                        backgroundColor: '#9e8383',
                        color: '#fff'
                      }}
                    >
                      <span>Pushimi mjekësor (i mbetur): {(this.props.stats.sick_leaves || 0).toFixed(2)} ditë</span>
                      <div></div>
                      <span>Prej: {moment(this.props.stats.sick_leave_startDate).format('DD/MM/YYYY')}</span>
                      <div></div>
                      <span>Deri më: {moment(this.props.stats.sick_leave_endDate).format('DD/MM/YYYY')}</span>

                      <span className='ml-auto' />
                    </div>
                  )}
                </Col>
                <Col>
                  <Row>
                    {approvedStats?.map(
                      (e, i) =>
                        e.category.name != 'Pushim Vjetor' &&
                        e.category.name != 'Pushim Mjekësor' && (
                          <Col xl={6} lg={6} md={12} key={i}>
                            <div key={i} className='card flex-row align-items-center align-items-stretch border-0'>
                              <div
                                className='col-4 d-flex align-items-center justify-content-center rounded-left'
                                style={{
                                  backgroundColor: e.category.icon_color ? e.category.icon_color : 'rgba(57, 63, 82, 0.55)',
                                  color: '#fff'
                                }}
                              >
                                <div className='text-center'>
                                  {' '}
                                  <em className={e.category.icon + ' fa-2x'} /> <br />
                                  <div
                                    style={{
                                      width: '70px',
                                      overflow: 'hidden',
                                      lineHeight: '1rem',
                                      textOverflow: 'ellipsis',
                                      fontSize: '0.8rem'
                                    }}
                                  >
                                    {this.messageCrop(e.category && e.category.name)}
                                  </div>
                                </div>
                              </div>
                              <div
                                className='col-8 py-3 rounded-right'
                                style={{
                                  backgroundColor: e.category.color ? e.category.color : 'rgba(150, 154, 163, 0.68)',
                                  color: '#fff'
                                }}
                              >
                                <div className='text-center'>
                                  <div className='m-0 text-bold' style={{ fontSize: '1.7em' }}>
                                    {e.totalDays}
                                  </div>
                                  <div className=''> ditë të shpenzuara </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        )
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default LeavesHeader
