import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import ContentWrapper from '../../../../components/Layout/ContentWrapper'
import MobileZoneForm from '../components/form/form'
import { fetchOneMozoOrders } from '../API/endpoints'
import jwtDecode from 'jwt-decode'


const Edit_Mozo_Orders = () => {
  const history = useHistory()
  const params = useParams()
  const orderId = params?.orderId
  const token = localStorage.getItem('token')
  const decodedToken = token ? jwtDecode(token) : null
  const user_level = decodedToken?.user_level || ''

  let accessToDelete = false

  if (user_level === 1 || user_level === 2 || user_level === 3) {
    accessToDelete = true
  }

  const oneMozoOrder = useQuery({
    queryKey: ['oneMozoOrder', orderId],
    queryFn: () => fetchOneMozoOrders(orderId),
    staleTime: 50000
  })

  if (oneMozoOrder.isLoading) {
    return <CircularProgress />
  }
  if (oneMozoOrder.error) {
    return <Typography color='error'>Error loading data</Typography>
  }

  return (
    <ContentWrapper>
      <Box className='content-heading'>
        <p>Edit Mobilezone Orders</p>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "end", marginBottom: 3 }}>
        <Button variant='contained'
          color='info' onClick={() => history.goBack()} >
          Kthehu prapa
        </Button>
      </Box>
      <MobileZoneForm editData={oneMozoOrder.data[0]} edit={true} accessToDelete={accessToDelete} />
    </ContentWrapper>
  )
}

export default Edit_Mozo_Orders
