const buildQueryString = (from, to, searchText, project, departments, office_locations) => {
  let query = ''

  if (from.isChecked) {
    query += `&fromDate=${from.value}`
  }

  if (to.isChecked) {
    query += `&toDate=${to.value}`
  }

  if (searchText) {
    query += `&search=${searchText}`
  }

  if (project) {
    query += `&project=${project}`
  }

  if (departments) {
    query += `&departments[]=${departments}`
  }

  if (office_locations) {
    query += `&office_locations[]=${office_locations}`
  }

  return query
}

export default buildQueryString
