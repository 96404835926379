const options = (params, setParams, total) => {
  return {
    rowsPerPageOptions: [10, 50, 100, 200],
    download: false,
    filter: false,
    sort: false,
    search: false,
    responsive: 'scroll',
    count: total,
    page: params.page,
    elevation: 0,
    filterType: 'dropdown',
    selectableRows: 'none',
    viewColumns: true,
    rowsPerPage: params.limit,
    pagination: true,
    serverSide: true,
    print: false,
    textLabels: {
      body: {
        noMatch: 'Nuk ka të dhëna'
      }
    },
    onTableChange: (action, a) => (action === 'changeRowsPerPage' ? (a.page = 0) : null),
    onChangeRowsPerPage: (e) => {
      setParams((prevState) => ({
        ...prevState,
        limit: e,
        page: 0
      }))
    },
    onChangePage: (e) => {
      setParams((prevState) => ({
        ...prevState,
        page: e
      }))
    }
  }
}
export default options
