import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import moment from 'moment'

export const columnData = (item) => {
  const userLangs = Array.isArray(item?.user?.languages) ? item.user.languages : []
  const allLanguages = userLangs
    .map((l) => l.language?.name)
    .filter(Boolean)
    .join('\n')

  const allLevels = userLangs
    .map((l) => l.level)
    .filter(Boolean)
    .join('\n')
  const formatDate = (date) => moment(date).format('DD/MM/YYYY')
  return [
    item?.user.first_name + ' ' + item.user.last_name,
    item?.user.email,
    item?.user.identity_card,
    item?.user?.activity?.name,
    item?.user?.department[0]?.name,
    item?.user?.function?.name,
    allLanguages,
    allLevels,
    item?.user?.office_location,

    item?.resign_type ? (item.resign_type === 'involuntary' ? 'Jo-Vullnetare' : 'Vullnetare') : '',
    item?.created_date ? formatDate(item.created_date) : '',
    item?.resign_day ? formatDate(item.resign_day) : '',
    item?.last_date_on_work ? formatDate(item.last_date_on_work) : '',
    <Link to={`resignsDetails/${item._id}`}>
      <Button style={{ marginLeft: '5px' }} outline className='mb-1' color='warning' type='button'>
        <span>Detajet</span>
      </Button>
    </Link>
  ]
}
