import MUIDataTable from 'mui-datatables'
import { columnsOrderTable, optionsOrderTable } from './EnumOrder'

const OrdersTable = ({ type, orders }) => {
  return (
    <div className='pt-4'>
      <div>
        <MUIDataTable
          title={type == 'user' ? 'User Orders' : 'All Orders'}
          data={orders}
          options={optionsOrderTable}
          columns={columnsOrderTable}
        />
      </div>
    </div>
  )
}

export default OrdersTable
