import React, { Fragment } from 'react'
import UserForms from './UserForms'
import FileManager from './FileManager'

function UserFolder(props) {
  return (
    <Fragment>
      <div className='col-lg-12'>
        <UserForms {...props} userId={props.userId} />
      </div>
      <div className='col-lg-12'>
        <FileManager userId={props.userId} />
      </div>
    </Fragment>
  )
}

export default UserFolder
