import { inject } from 'mobx-react'
import React from 'react'
import ContentWrapper from '../Layout/ContentWrapper'
import BlacklistComponent from './BlacklistComponent'

@inject('userStore')
class Blacklist extends React.Component {
  constructor(props) {
    super(props)
    if (props.userStore.user_id) {
      this.state = {
        user: {
          user_id: props?.userStore.user_id,
          userLevel: props?.userStore.userLevel
        }
      }
    }
  }
  render() {
    return (
      <ContentWrapper>
        {this.state.user && <BlacklistComponent user={this.state.user} id={this.props.match.params.blacklistId} />}
      </ContentWrapper>
    )
  }
}

export default Blacklist
