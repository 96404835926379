import MUIDataTable from 'mui-datatables'
import axios from 'axios'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import ContentWrapper from '../Layout/ContentWrapper'
import DatePicker from './DatePicker/datePicker'
import { columns } from './utils/columns'
import useTableOptions from './utils/useOptions'
import buildQueryString from './utils/buildQueryString'
import Search from './Search/search'
import ResignsFilters from './Filters/filters'
import { columnData } from './utils/columnsData'
import { useDebounce } from '../../utils/searchDebouncer'

const Resigns = () => {
  const [resigns, setResigns] = useState([])
  const [loading, setLoading] = useState(true)
  const fromStartDate = moment(Date.now()).subtract(1, 'months').format('YYYY-MM-DD')
  const toStartDate = moment(Date.now()).format('YYYY-MM-DD')

  const [tableParams, setTableParams] = useState({ limit: 10, page: 0, total: 0, searchText: null })
  const [from, setFrom] = useState({ value: fromStartDate, isChecked: false })
  const [to, setTo] = useState({ value: toStartDate, isChecked: false })

  const [params, setParams] = useState({
    project: '',
    departments: [],
    office_locations: []
  })

  const searchTxt = useDebounce(tableParams.searchText)

  const fetchResigns = async () => {
    const query = buildQueryString(from, to, tableParams.searchText, params.project, params.departments, params.office_locations)

    await axios
      .get(`/resigns/${tableParams.limit}/${tableParams.page + 1}?${query}`)
      .then((response) => {
        if (response.data.success) {
          const tableData = response.data.data.map((item) => columnData(item))
          setTableParams((prevState) => ({ ...prevState, total: response.data.total }))
          setResigns(tableData)
          setLoading(false)
        }
      })
      .catch((error) => {
        console.log('error', error)
      })
  }
  useEffect(() => {
    fetchResigns()
  }, [
    tableParams.page,
    tableParams.limit,
    from.value,
    to.value,
    from.isChecked,
    to.isChecked,
    searchTxt,
    params.project,
    params.departments,
    params.office_locations
  ])

  const options = useTableOptions(
    tableParams,
    setTableParams,
    from,
    to,
    tableParams.searchText,
    params.project,
    params.departments,
    params.office_locations
  )

  return (
    <ContentWrapper>
      <div>
        <div className='content-heading'>
          <div>Lista e dorëheqjeve</div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'flex-end'
            }}
            className='ml-auto'
          ></div>
        </div>
        <Row>
          <Col>
            <Card className={`shadow bg-white ${loading && 'whirl standard'}`}>
              <CardBody>
                <Search tableParams={tableParams} setTableParams={setTableParams} />
                <div className='input-group pt-2'>
                  <Col>
                    <DatePicker date={from} setDate={setFrom} title={'Prej Dates'} />
                    <DatePicker date={to} setDate={setTo} title={'Deri'} />
                    <ResignsFilters params={params} setParams={setParams} />
                  </Col>
                </div>
              </CardBody>
              <MUIDataTable columns={columns} options={options} data={resigns} />
            </Card>
          </Col>
        </Row>
      </div>
    </ContentWrapper>
  )
}

export default Resigns
