export const columns = [
  {
    name: 'Emri dhe Mbiemri',
    label: 'Emri dhe Mbiemri',
    options: { sort: false }
  },
  {
    name: 'Email',
    label: 'Email',
    options: { sort: false }
  },
  {
    name: 'Nr Personal',
    label: 'Nr Personal',
    options: { sort: false }
  },
  {
    name: 'Statusi',
    label: 'Statusi',
    options: { sort: false, display: false }
  },
  {
    name: 'Departamenti',
    label: 'Departamenti',
    options: { sort: false }
  },
  {
    name: 'Pozita',
    label: 'Pozita',
    options: { sort: false, display: false }
  },
  {
    name: 'Gjuha',
    label: 'Gjuha',
    options: { sort: false, display: false }
  },
  {
    name: 'Niveli i Gjuhes',
    label: 'Niveli i Gjuhes',
    options: { sort: false, display: false }
  },
  {
    name: 'Lokacioni',
    label: 'Lokacioni',
    options: { sort: false, display: false }
  },
  {
    name: 'Lloji i doreheqjes',
    label: 'Lloji i doreheqjes',
    options: { sort: false }
  },
  {
    name: 'Data e krijimit',
    label: 'Data e krijimit',
    options: { sort: false }
  },
  {
    name: 'Data e doreheqjes',
    label: 'Data e doreheqjes',
    options: { sort: false }
  },
  {
    name: 'Data e fundit si punetor',
    label: 'Data e fundit si punetor',
    options: { sort: false }
  },
  {
    name: 'Detajet',
    label: 'Detajet',
    options: {
      sort: false
    }
  }
]
