import React from 'react'
import { Box, FormControl, InputLabel, ListSubheader, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import Modal from 'react-bootstrap/Modal'
import { Button } from 'reactstrap'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  textfield: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.23)'
    }
  }
})

const ApplicantModal = ({
  show,
  setShow,
  handleClose,
  status,
  testResult,
  setTestResult,
  languageLevel,
  setLanguageLevel,
  testPassability,
  setTestPassability,
  newStatus,
  setNewStatus,
  description,
  setDescription,
  error,
  setError,
  changeApplicantStatus,
  buttonsApplicants
}) => {
  const classes = useStyles()

  return (
    <Modal show={show} onHide={handleClose} className='modal-1'>
      <Modal.Header closeButton>
        <Modal.Title>{status === 'waiting_list' ? 'Rezultatet e testit' : 'Përshkrimi'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {status === 'waiting_list' ? (
          <>
            <TextField
              className={classes.textfield}
              fullWidth
              placeholder
              type='number'
              label='Pikët e testit'
              variant='outlined'
              laceholder='Pikët e testit'
              onChange={(e) => setTestResult(e.target.value)}
              value={testResult}
            />
            <FormControl variant='outlined' fullWidth className={classes.textfield} style={{ marginTop: '20px' }}>
              <InputLabel id='language_level_label'>Niveli i gjuhës</InputLabel>
              <Select
                label='Niveli i gjuhës'
                labelId='language_level'
                id='language_level_select'
                value={languageLevel}
                onChange={(e) => setLanguageLevel(e.target.value)}
              >
                <ListSubheader>Niveli A</ListSubheader>
                <MenuItem value='A1'>A1</MenuItem>
                <MenuItem value='A2'>A2</MenuItem>
                <ListSubheader>Niveli B</ListSubheader>
                <MenuItem value='B1'>B1</MenuItem>
                <MenuItem value='B2'>B2</MenuItem>
                <ListSubheader>Niveli C</ListSubheader>
                <MenuItem value='C1'>C1</MenuItem>
                <MenuItem value='C2'>C2</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant='outlined' fullWidth className={classes.textfield} style={{ marginTop: '20px' }}>
              <InputLabel id='test_passability_label'>Kalueshmëria e testit</InputLabel>
              <Select
                label='Kalueshmëria e testit'
                labelId='test_passability'
                id='test_passability_select'
                value={testPassability}
                onChange={(e) => setTestPassability(e.target.value)}
              >
                <MenuItem value={true}>Pozitive</MenuItem>
                <MenuItem value={false}>Negative</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant='outlined' fullWidth className={classes.textfield} style={{ marginTop: '20px' }}>
              <InputLabel id='next_status_label'>Statusi i ardhshëm</InputLabel>
              <Select
                label='Statusi i ardhshëm'
                labelId='next_status'
                id='next_status_select'
                value={newStatus}
                onChange={(e) => {
                  setNewStatus(e.target.value)
                }}
              >
                <MenuItem value='low'>Dobët</MenuItem>
                <MenuItem value='spxedu'>Speeex Education</MenuItem>
                <MenuItem value='waiting_list'>Listë e pritjes</MenuItem>
              </Select>
            </FormControl>
            <TextField
              variant='outlined'
              placeholder='Shkruani arsyen!'
              className={classes.textfield}
              style={{ marginTop: '20px', marginBottom: '30px' }}
              fullWidth
              multiline
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </>
        ) : (
          <>
            <TextField
              variant='outlined'
              placeholder='Shkruani arsyen!'
              className={classes.textfield}
              fullWidth
              multiline
              style={{ marginBottom: '30px' }}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </>
        )}
        {status !== 'new' && (
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            {error && (
              <Typography className='mt-2 mb-2 text-danger' style={{ fontSize: '14px' }}>
                Të gjitha të dhënat duhet të plotësohen!
              </Typography>
            )}
            <Button
              color='info'
              onClick={() => {
                if (!description) {
                  setError(true)
                } else if (
                  status === 'waiting_list' &&
                  (!description || !testResult || !languageLevel || testPassability == undefined || !newStatus)
                ) {
                  setError(true)
                } else {
                  setError(false)
                  setShow(false)
                  changeApplicantStatus()
                  setDescription('')
                }
              }}
            >
              {buttonsApplicants.filter((btn) => btn.type === status)[0].text}
            </Button>
          </Box>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default ApplicantModal
