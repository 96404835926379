import axios from 'axios'
import React, { useState } from 'react'
import moment from 'moment'
import { Modal, Box, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { Button, TabContent, Col, Input } from 'reactstrap'
import Swal from 'sweetalert2'
import DateTimeBoostrap from '../../Forms/DateTimeBootstrap'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '90vw', md: '80vw', lg: '50vw' },
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
}

const ResignIntervieModal = (props) => {
  const [fromDate, setFromDate] = useState(true)
  const [toDate, setToDate] = useState(true)
  const [fromDateValue, setFromDateValue] = useState(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'))
  const [toDateValue, setToDateValue] = useState(moment().add(1, 'months').endOf('month').format('YYYY-MM-DD'))
  const [open, setOpen] = useState(false)

  const downloadCsv = () => {
    let query = ''

    if (!fromDate && !toDate) {
      query = query + `&createdAt[gte]=${fromDateValue}&createdAt[lte]=${toDateValue}`
    }
    if (fromDate) {
      query = query + `&createdAt[gte]=${fromDateValue}`
    }
    if (toDate) {
      query = query + `&createdAt[lte]=${toDateValue}`
    }

    Swal.fire({
      title: 'Ju lutem prisni...',
      html: 'Duke shkarkuar të dhënat për historinë e përdoruesve',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading()
      },
      customClass: {
        container: 'custom-swal-class' // Add a custom class to target this Swal
      }
    })
    handleClose()

    axios
      .get(`resignInterview/CSV?${query}`, {
        responseType: 'blob'
      })
      .then((response) => {
        if (response.headers['content-type'] == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          saveAs(response.data, 'resignInterview.csv')
        }
        Swal.close()
      })
      .catch((error) => {
        console.log('error', error)
        Swal.close()
        Swal.fire('', 'Ndodhi nje gabim!', 'error')
      })
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onFromDateChange = async (date) => {
    if (typeof date == 'object') {
      setFromDateValue(moment(date).format('YYYY-MM-DD'))
    }
  }

  const onToDateChange = async (date) => {
    if (typeof date == 'object') {
      setToDateValue(moment(date).format('YYYY-MM-DD'))
    }
  }

  return (
    <>
      <Button color='info' className='btn-oval mb-4' style={{ margin: '0 10px' }} onClick={handleOpen}>
        Shkarko Intervistat Dalëse
      </Button>
      <Modal open={open} onClose={handleClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
        <Box sx={style}>
          <h2 style={{ opacity: '.6', marginBottom: '25px' }}>Shkarko Intervistat Dalëse</h2>
          <TabContent style={{ borderWidth: '1px 1px 1px 1px' }}>
            <div className='input-group pt-2'>
              <Col style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                <div className='form-group' style={{ display: 'flex' }}>
                  <div className='checkbox c-checkbox pt-2'>
                    <label>
                      <Input
                        onChange={() => {
                          setFromDate(!fromDate)
                        }}
                        id='fromdate'
                        name='fromdate'
                        type='checkbox'
                      />
                      <span className='fa fa-check' />
                    </label>
                  </div>
                  <label className='col-form-label mb' htmlFor='fromdate'>
                    Prej datës:
                  </label>
                  <Col md={3} className='from-date'>
                    <DateTimeBoostrap
                      onChange={onFromDateChange}
                      value={moment(fromDateValue).format('DD/MM/YYYY')}
                      utc={true}
                      locale='de'
                      dateFormat={'DD/MM/YYYY'}
                      timeFormat=''
                      name='fromdate'
                      type='checkbox'
                      initialViewMode='years'
                      closeOnSelect
                      data-validate='["validDate"]'
                      inputProps={{
                        disabled: fromDate && 'disabled',
                        id: 'dateFrom',
                        'data-validate': '["validDate"]',
                        className: 'form-control input',
                        name: 'dateFrom'
                      }}
                    />
                  </Col>
                </div>
                <div className='form-group row mb float-left pl-3'>
                  <div className='checkbox c-checkbox pt-2'>
                    <label>
                      <Input
                        onChange={() => {
                          setToDate(!toDate)
                        }}
                        name='todate'
                        id='todate'
                        type='checkbox'
                      />
                      <span className='fa fa-check' />
                    </label>
                  </div>
                  <label style={{ width: '60px' }} className='col-form-label mb' htmlFor='todate'>
                    Deri:
                  </label>
                  <Col md={3} className='to-date'>
                    <DateTimeBoostrap
                      value={moment(toDateValue).format('DD/MM/YYYY')}
                      type='date'
                      utc={true}
                      dateFormat={'DD/MM/YYYY'}
                      timeFormat=''
                      closeOnSelect
                      onChange={onToDateChange}
                      initialViewMode='years'
                      placeholder='yy/mm/dd'
                      inputProps={{
                        disabled: toDate && 'disabled',
                        id: 'dateTo',
                        'data-validate': '["validDate"]',
                        className: 'form-control',
                        name: 'dateTo'
                      }}
                    />
                  </Col>
                </div>
              </Col>
            </div>
            <div></div>
          </TabContent>
          <div className='d-flex justify-content-end' style={{ gap: '10px', marginTop: '20px' }}>
            <Button color='success' outline className='ms-auto' onClick={() => downloadCsv()}>
              Shkarko
            </Button>
            <Button onClick={handleClose} color='danger' outline>
              Anulo
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  )
}
export default ResignIntervieModal
