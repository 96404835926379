import axios from 'axios'
import moment from 'moment'
import MUIDataTable from 'mui-datatables'
import React from 'react'
import 'react-datetime/css/react-datetime.css'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody, Col, Input, Row } from 'reactstrap'
import ContentWrapper from '../../Layout/ContentWrapper'
import DateTimeBoostrap from '../../Forms/DateTimeBootstrap'

class Holidays extends React.Component {
  mounted = false
  constructor() {
    super()
    this.state = {
      data: [],
      filteredDate: [],
      selectedDate: new Date()
    }

    this._onDateChange = this._onDateChange.bind(this)
    this._dateFunctionFiltered = this._dateFunctionFiltered.bind(this)
  }

  _dateFunctionFiltered() {
    if (this.state.selectedDate) {
      const dateYear = moment(this.state.selectedDate).format('YYYY')
      const dataWithFilter = []
      this.state.data.map((dateFilter) => {
        const dateFiltered = moment(dateFilter.date).format('YYYY')
        if (dateYear == dateFiltered) {
          dataWithFilter.push(dateFilter)
          this.setState({
            filteredDate: dataWithFilter
          })
        }
      })
    }
  }

  async componentDidMount() {
    this.mounted = true
    try {
      const res = await axios.get('holidays', {})
      if (res.data.success) {
        if (this.mounted) {
          await this.setState({ data: res.data.data })
          this._dateFunctionFiltered()
        }
      }
    } catch (e) {
      this.setState({ error_message: 'Error fetching data from the server' })
    }
  }

  componentWillUnmount() {
    this.mounted = false
  }

  async _onDateChange(date) {
    this.setState({
      filteredDate: []
    })
    if (typeof date == 'object') {
      await this.setState({ selectedDate: date })
      this._dateFunctionFiltered()
    }
  }

  _onDateBlur = (date) => {
    this.setState({ selectedDate: moment(date, 'YYYY') })
  }

  render() {
    const columns = [
      {
        name: 'Emri',
        label: 'Emri',
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: 'Krijuar nga',
        label: 'Krijuar nga',
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: 'Data',
        label: 'Data',
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: 'Modifikuar nga',
        label: 'Modifikuar nga',
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: 'Data e modifikimit',
        label: 'Data e Modifikimit',
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: 'Pamje',
        label: 'Pamje',
        options: {
          filter: false,
          sort: false
        }
      }
    ]
    const options = {
      responsive: 'scroll',
      filterType: 'dropdown',
      selectableRows: 'none',
      textLabels: {
        body: {
          noMatch: 'Nuk ka të dhëna'
        }
      },
      elevation: 0
    }

    const data = this.state.filteredDate.map((elem, index) => {
      var date = moment(elem.date).format('DD/MM/YYYY')
      return [
        elem.name,
        elem.created_by.email,
        date,
        elem.edited_by && elem.edited_by.email,
        elem.last_edit_date && moment(elem.last_edit_date).format('DD/MM/YYYY'),
        <Link key={index} to={`/editHolidays/${elem._id}`}>
          <Button outline className='mb-1' color='warning' type='button'>
            <span>Modifiko</span>
          </Button>
        </Link>
      ]
    })
    return (
      <ContentWrapper>
        <div className='content-heading'>
          <div>Pushimet Shtetërore</div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'flex-end'
            }}
            className='ml-auto'
          >
            <Link to='/editHolidays/'>
              <Button color='success' className='btn-oval'>
                Shto pushim
              </Button>
            </Link>
          </div>
        </div>
        <Row>
          <Col>
            <Card className='shadow bg-white'>
              <CardBody>
                <div className='form-group row mb'>
                  <label className='col-md-2 col-form-label mb'>Zgjedh vitin:</label>
                  <Col md={4}>
                    <DateTimeBoostrap
                      key='selectDate'
                      value={this.state.selectedDate}
                      onChange={this._onDateChange}
                      locale='de'
                      utc={true}
                      dateFormat='YYYY'
                      onBlur={this._onDateBlur}
                      data-validate='["validDate"]'
                      timeFormat={false}
                      inputProps={{
                        'data-validate': '["validDate"]',
                        className: 'form-control',
                        name: 'data',
                        autoComplete: 'off'
                      }}
                    />
                  </Col>
                </div>
              </CardBody>
              <MUIDataTable title={'Pushimet Shtetërore'} columns={columns} options={options} data={data} />
            </Card>
          </Col>
        </Row>
      </ContentWrapper>
    )
  }
}

export default Holidays
