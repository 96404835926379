import { inject } from 'mobx-react'
import pubsub from 'pubsub-js'
import { Component } from 'react'
import { Collapse } from 'reactstrap'

@inject('userStore')
class SidebarUserBlock extends Component {
  state = {
    userBlockCollapse: true
  }

  componentDidMount() {
    this.pubsub_token = pubsub.subscribe('toggleUserblock', (topic, data) => {
      if (topic == 'toggleUserblock') {
        this.setState({
          userBlockCollapse: data.isopen
        })
      }
    })
  }

  componentWillUnmount() {
    pubsub.unsubscribe(this.pubsub_token)
  }

  goToProfile = (e) => {
    if (e.target.type != 'submit') {
      pubsub.publish('goToProfile')
    }
  }

  render() {
    const username = localStorage.getItem('name')
    const funct = localStorage.getItem('function')

    return (
      <Collapse id='user-block' onClick={this.goToProfile} isOpen={true}>
        <div>
          <div className='item user-block'>
            {/* User picture */}
            <div className='user-block-picture'>
              <div className='user-block-status'>
                <img className={`img-thumbnail rounded-circle`} width='60' height='60' src='/img/blank_profile.png' alt='' />

                <div className='circle bg-success circle-lg'></div>
              </div>
            </div>
            {/* Name and Job */}
            {/* Resize was display */}
            <div
              className='user-block-info'
              style={{
                padding: '15px',
                resize: this.state.userBlockCollapse ? 'block' : 'none'
              }}
            >
              <span className='user-block-name'>Përshëndetje, {username}</span>
              <span className='user-block-role'>{funct && funct != 'undefined' && funct}</span>
            </div>
          </div>
        </div>
      </Collapse>
    )
  }
}

export default SidebarUserBlock
