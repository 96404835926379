import { Box, Button, Dialog, DialogActions, DialogTitle, FormControlLabel, FormGroup, Switch } from '@mui/material'
import { toast } from 'react-toastify'
import { complianceLoginsUpdate } from '../../utils/requests'
import { switchTypes } from '../Filter/utils/switchTypes'
import SwitchControl from './SwitchControl'

const UpdateLoginModal = ({ handleModalClose, loginDetails, setLoginDetails, getData }) => {
  const handleSwitchChange = (property) => () => {
    setLoginDetails((prevState) => ({
      ...prevState,
      [property]: !prevState[property]
    }))
  }

  const handleSaveChanges = async () => {
    try {
      const postData = {
        partner_login: loginDetails.partnerLogin,
        intern_login: loginDetails.internLogin,
        badge: loginDetails.badgeLogin
      }
      const response = await complianceLoginsUpdate(loginDetails.editId, postData)
      if (response.data.data.success) {
        handleModalClose()
        getData()
        toast.success('Logins u përditësuan me sukses.')
      }
    } catch (error) {
      toast.error(error.response?.data?.message)
    }
  }

  return (
    <Dialog open={true} fullWidth maxWidth='sm'>
      <DialogTitle sx={{ textAlign: 'center', borderBottom: '3px solid #eee' }}>Modifiko të dhënat mbi loginat</DialogTitle>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 3 }}>
        <FormGroup sx={{ marginBottom: '30px' }}>
          {switchTypes.map(({ label, property }) => (
            <SwitchControl key={property} label={label} checked={loginDetails[property]} onChange={handleSwitchChange(property)} />
          ))}
        </FormGroup>
        <DialogActions>
          <Button variant='outlined' onClick={handleModalClose}>
            Close
          </Button>
          <Button variant='contained' onClick={handleSaveChanges} mr={2}>
            Save Changes
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default UpdateLoginModal
