import { Box, Card, Typography, Button } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import { useEffect, useState } from 'react'
import { CardBody } from 'reactstrap'
import moment from 'moment'
import { toast } from 'react-toastify'
import { saveAs } from 'file-saver'
import Filters from '../Filter/filters'
import UpdateLoginModal from '../Modal/UpdateLoginModal'
import columns from './utils/columns'
import options from './utils/options'
import { getComplianceData, downloadComplianceCsv } from '../../utils/requests'
import { buildQuery } from './hooks/buildQuery'
import { buildTableData } from './hooks/buildTableData'
import { useDebounce } from '../../../../utils/searchDebouncer'

const ComplianceTable = ({ showBtn, type }) => {
  const [data, setData] = useState([])
  const [params, setParams] = useState({ limit: 10, page: 0, total: 0, search: null })
  const fromStartDate = moment(Date.now()).subtract(1, 'months')
  const toStartDate = moment(Date.now())
  const [from, setFrom] = useState({ value: fromStartDate, isChecked: false })
  const [to, setTo] = useState({ value: toStartDate, isChecked: false })
  const [search, setSearch] = useState(null)
  const [loading, setLoading] = useState(true)
  const [isModalOpen, setModalOpen] = useState(false)
  const [loginDetails, setLoginDetails] = useState({
    partnerLogin: true,
    internLogin: true,
    badgeLogin: true,
    editId: ''
  })
  const searchTxt = useDebounce(search)

  const getData = async () => {
    const query = buildQuery(searchTxt, from, to)
    const data = await getComplianceData(params.page, params.limit, type, query)

    if (data.data.success) {
      const tableData = buildTableData(data.data.data, showBtn, handleEditClick)
      setLoading(false)
      setData(tableData)
      setParams((prevState) => ({
        ...prevState,
        total: data.data.total
      }))
    } else {
      toast.error('Ndodhi një gabim.')
    }
  }

  const downloadCsv = () => {
    const query = buildQuery(search, from, to)
    downloadComplianceCsv(type, query).then((response) => {
      if (response.headers['content-type'] == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        saveAs(response.data, 'Compliance.csv')
        toast.success('Te dhenat u shkarkuan!')
      } else {
        toast.error('Ndodhi një gabim.')
      }
    })
  }

  const handleEditClick = (documentId, partnerLogin, internLogin, badgeLogin) => {
    setModalOpen(true)
    setLoginDetails(() => ({
      partnerLogin,
      internLogin,
      badgeLogin,
      editId: documentId
    }))
  }

  useEffect(() => {
    getData()
  }, [params.page, params.limit, searchTxt, from, to])

  return (
    <Box>
      {isModalOpen && (
        <UpdateLoginModal
          getData={getData}
          loginDetails={loginDetails}
          setLoginDetails={setLoginDetails}
          handleModalClose={() => setModalOpen(false)}
        />
      )}
      <Card className={`shadow bg-white ${loading && 'whirl standard'}`}>
        <CardBody>
          <Typography variant='h6' sx={{ marginBottom: '5px' }}>
            {type === 'completed' ? 'Të perfunduara' : 'Të pa përfunduara'}
          </Typography>
          <Filters from={from} setFrom={setFrom} to={to} setTo={setTo} search={search} setSearch={setSearch} />
          <MUIDataTable columns={columns(showBtn)} options={options(params, setParams, downloadCsv)} data={data} />
        </CardBody>
      </Card>
    </Box>
  )
}
export default ComplianceTable
