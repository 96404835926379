import { Box, Button, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField } from '@mui/material'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { office_location } from '../../../shared/enums/office_location'

const BadgesFilters = ({ params, setParams }) => {
  const [projects, setProjects] = useState([])
  const [departments, setDepartments] = useState([])

  const getProjects = async () => {
    try {
      const data = await axios.get('/projects')
      setProjects(data.data.data)
    } catch (error) {
      console.error('Error fetching projects:', error)
    }
  }

  const fetchDepartments = async () => {
    if (params?.project) {
      try {
        const data = await axios.get('/departments', {
          params: {
            projectId: params?.project
          }
        })
        setDepartments(data.data.data)
      } catch (error) {
        console.error('Error fetching departments:', error)
      }
    }
  }

  useEffect(() => {
    getProjects()
  }, [])

  useEffect(() => {
    fetchDepartments()
  }, [params?.project])
  return (
    <Box sx={{ fieldset: { border: '1px solid' }, paddingY: '20px', paddingRight: '15px', display: 'flex', justifyContent: 'flex-end' }}>
      <TextField
        size='small'
        label='Kërko me emër, email ose numer personal'
        onChange={(e) => setParams((prev) => ({ ...prev, page: 0, search: e.target.value }))}
      />

      <FormControl sx={{ width: '200px', textAlign: 'left', marginX: '10px' }}>
        <InputLabel size='small' id='project'>
          Projekti
        </InputLabel>
        <Select
          size='small'
          labelId='project'
          value={params?.project}
          onChange={(e) => {
            setParams((prev) => ({ ...prev, page: 0, project: e.target.value, departments: null }))
          }}
          input={<OutlinedInput label='Projekti' />}
          renderValue={(selected) => projects.find((item) => item._id === selected)?.name}
        >
          {projects.map((item) => (
            <MenuItem key={item._id} value={item._id}>
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {params?.project && (
        <FormControl sx={{ width: '250px', textAlign: 'left', marginX: '10px' }}>
          <InputLabel size='small' id='department_label'>
            Departamenti
          </InputLabel>
          <Select
            size='small'
            multiple
            labelId='department_label'
            value={params?.departments ?? []}
            onChange={(e) => setParams((prev) => ({ ...prev, page: 0, departments: e.target.value }))}
            input={<OutlinedInput label='Departamenti' />}
            renderValue={(selected) =>
              departments
                .filter((item) => selected.includes(item._id))
                .map((item) => item.name)
                .join(', ')
            }
          >
            {departments.map((item) => (
              <MenuItem key={item._id} value={item._id}>
                <Checkbox checked={(params?.departments ?? []).indexOf(item._id) > -1} />
                <ListItemText primary={item.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <FormControl sx={{ width: '200px', textAlign: 'left', marginX: '10px' }}>
        <InputLabel size='small' id='office_location'>
          Lokacioni i punës
        </InputLabel>
        <Select
          size='small'
          multiple
          labelId='office_location'
          value={params?.office_locations ?? []}
          onChange={(e) => setParams((prev) => ({ ...prev, page: 0, office_locations: e.target.value }))}
          input={<OutlinedInput label='Lokacioni i punës' />}
          renderValue={(selected) =>
            office_location
              .filter((item) => selected.includes(item.value))
              .map((item) => item.name)
              .join(', ')
          }
        >
          {office_location.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              <Checkbox checked={(params?.office_locations ?? []).indexOf(item.value) > -1} />
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        variant='contained'
        size='small'
        disabled={!params?.search && !params?.project && params?.departments.length === 0 && params?.office_locations.length === 0}
        onClick={() => setParams((prev) => ({ page: 1, limit: 10, search: '', project: '', departments: [], office_locations: [] }))}
      >
        Largo filtrat
      </Button>
    </Box>
  )
}

export default BadgesFilters
