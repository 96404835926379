import { Grid, Chip, Box } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import Locale from 'date-fns/locale/sq'
import moment from 'moment'
import { useState } from 'react'
import { toast } from 'react-toastify'
import TotalHoursCard from './Cards/TotalHoursCard'
import DatePickerFilter from './Date_Picker/datePicker'
import LeavesCalendar from './My_Leaves_Calendar/LeavesCalendar'
import { Typography } from '@material-ui/core'

export default function MyWorkingHourse({ userId }) {
  const [valueDate, setValueDate] = useState(new Date())

  const fetchMyMonthlyHours = async (urlWithParams) => {
    try {
      const res = await axios.get(urlWithParams)
      if (res.data.success) {
        return res
      }
    } catch (error) {
      toast.error(error.res?.data?.message)
    }
  }
  let query = `target_year=${moment(valueDate).format('YYYY')}&target_month=${moment(valueDate).format('MM')}&userId=${userId}`

  let endpoint = `monthlyWorkingHours/myHours?${query}`

  const myMonthlyHours = useQuery({
    queryKey: ['myMonthlyHours', valueDate],
    queryFn: () => fetchMyMonthlyHours(endpoint),
    staleTime: 50000
  })
  const totalHours = myMonthlyHours?.data?.data?.totalLeaveHours + myMonthlyHours?.data?.data?.totalWorkHours
  const existData = myMonthlyHours?.data?.data?.data.length > 0 ? true : false

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={Locale}>
      <Grid container spacing={2} mt={3} pb={5} pl={2} pr={2}>
        <Grid item xs={4} sx={{ fieldset: { border: '1px solid #ddd' } }}>
          <DatePickerFilter date={valueDate} setDate={setValueDate} />
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <TotalHoursCard
            totalHours={totalHours}
            userId={userId}
            existData={existData}
            targetHours={myMonthlyHours?.data?.data?.targetHours}
            expectedHours={myMonthlyHours?.data?.data?.expectedHours}
            actualHours={myMonthlyHours?.data?.data?.actualHours}
            differenceHours={myMonthlyHours?.data?.data?.differenceHours}
            differenceBounusHours={myMonthlyHours?.data?.data?.differenceBounusHours}
            leavesHours={myMonthlyHours?.data?.data?.totalLeaveHours}
            worktimeHours={myMonthlyHours?.data?.data?.totalWorkHours}
            haveData={myMonthlyHours?.data?.data?.haveData}
            lastUpdatedDate={myMonthlyHours?.data?.data?.lastUpdatedDate}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display='flex' justifyContent='center' flexWrap='wrap' mb={-1} gap={2} mt={3}>
            <Typography>Legjenda:</Typography>
            <Chip size='small' label='Orët' sx={{ backgroundColor: '#A9ACB1', color: '#fff' }} />
            <Chip size='small' label='Nuk ka orë' sx={{ backgroundColor: '#FA4041', color: '#fff' }} />
            <Chip size='small' label='Pushim mjekësor' sx={{ backgroundColor: '#FFA500', color: '#fff' }} />
            <Chip size='small' label='Pushimet tjera' sx={{ backgroundColor: '#00BEA5', color: '#fff' }} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <LeavesCalendar valueDate={valueDate} setValueDate={setValueDate} myHours={myMonthlyHours?.data?.data?.data || []} />
        </Grid>
      </Grid>
    </LocalizationProvider>
  )
}
