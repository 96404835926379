import FontIconPicker from '@fonticonpicker/react-fonticonpicker'
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css'
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css'
import axios from 'axios'
import pubsub from 'pubsub-js'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import React from 'react'
import { SketchPicker } from 'react-color'
import { Button, Card, CardBody, CardFooter, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap'
import 'sweetalert2/src/sweetalert2.scss'
import { DeleteDialogBox } from '../../Common/'
import * as iconDefs from '../../Common/iconDefs'
import FormValidator from '../../Forms/FormValidator.js'
import ContentWrapper from '../../Layout/ContentWrapper'
class EditCategory extends React.Component {
  constructor() {
    super()
    this.state = {
      isEdit: false,
      formCategory: {
        nameCategory: '',
        descCategory: '',
        is_yearly_leave_deductible: false
      },
      leftColor: '',
      rightColor: '',
      icon: '',
      displayColorPicker: false,
      displayColorPickerInput: false,
      displayColorPickerInput2: false
    }
  }
  handleChange = (icon) => {
    this.setState({ icon: icon + ' p-2' })
  }

  validateOnChange = (event) => {
    const input = event.target
    const form = input.form
    const value = input.type === 'checkbox' ? input.checked : input.value
    const result = FormValidator.validate(input)
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    })
  }

  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    )
  }

  deleteCategory(id) {
    const goBack = () => this.props.history.goBack()
    const path = `leavecategories/${id}`
    const name = 'Kategorine e Pushimeve'
    DeleteDialogBox({ path, name, goBack })
  }

  colorpickerInputHandleClick = () => {
    this.setState({
      displayColorPickerInput: !this.state.displayColorPickerInput
    })
  }

  colorpickerInputHandleClose = () => {
    this.setState({ displayColorPickerInput: false })
  }

  colorpickerInputHandleClickSecond = () => {
    this.setState({
      displayColorPickerInput2: !this.state.displayColorPickerInput2
    })
  }

  colorpickerInputHandleCloseSecond = () => {
    this.setState({ displayColorPickerInput2: false })
  }

  messageCrop(text) {
    var limit = 15
    var trimmedString = text.length > limit ? text.slice(0, limit - 3) + '...' : text
    return trimmedString
  }

  onSubmit = (e) => {
    e.preventDefault()
    const ID = this.props.match.params.idCategory
    const form = e.target
    const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT'].includes(i.nodeName))

    const { errors, hasError } = FormValidator.bulkValidate(inputs)
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      }
    })

    if (!hasError) {
      var postData = {
        name: this.state.formCategory.nameCategory,
        description: this.state.formCategory.descCategory,
        is_yearly_leave_deductible: this.state.formCategory.is_yearly_leave_deductible,
        calculate_pay: this.state.sliderValue,
        icon: this.state.icon,
        icon_color: this.state.leftColor,
        color: this.state.rightColor
      }

      if (ID) {
        axios
          .put(`leavecategories/${ID}`, postData)
          .then((res) => {
            if (res.data.success) {
              this.props.history.push('/category')
              pubsub.publish('showNotification', {
                message: 'Të dhënat janë ndryshuar!',
                type: 'success'
              })
            } else {
              pubsub.publish('showNotification', {
                message: res.data.message,
                type: 'error'
              })
            }
          })
          .catch((err) => {
            pubsub.publish('showNotification', {
              message: 'Gabim gjatë ndryshimit të të dhënave',
              type: 'error'
            })
          })
      } else {
        axios
          .post('leavecategories', postData)
          .then((res) => {
            if (res.data.success) {
              pubsub.publish('showNotification', {
                message: 'Të dhënat janë ruajtur!',
                type: 'success'
              })
              this.props.history.push('/category')
            } else {
              pubsub.publish('showNotification', {
                message: res.data.message,
                type: 'error'
              })
            }
          })
          .catch((err) => {
            pubsub.publish('showNotification', {
              message: 'Gabim gjatë ruajtjes të të dhënave',
              type: 'error'
            })
          })
      }
    }

    e.preventDefault()
  }

  async componentDidMount() {
    const idCategory = this.props.match.params.idCategory
    if (idCategory) {
      try {
        const res = await axios.get('leavecategories/' + idCategory, {})
        await this.setState({
          isEdit: true,
          formCategory: {
            nameCategory: res.data.data.name,
            descCategory: res.data.data.description,
            is_yearly_leave_deductible: res.data.data.is_yearly_leave_deductible || false
          },
          rightColor: res.data.data.color,
          leftColor: res.data.data.icon_color,
          sliderValue: res.data.data.calculate_pay,
          icon: res.data.data.icon
        })
      } catch (error) {}
    }
  }

  render() {
    // used for color picker
    const popover = {
      position: 'absolute',
      zIndex: '302'
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px'
    }

    const props = {
      icons: { ...iconDefs.fontAwesome },
      renderUsing: 'class',
      fontSize: '8px',
      onChange: this.handleChange,
      value: this.state.icon
    }
    const id = this.props.match.params.idCategory
    return (
      <ContentWrapper>
        {this.state.isEdit ? (
          <div className='d-flex justify-content-between'>
            <p style={{ fontSize: 23, color: 'gray' }}>Modifiko kategorinë</p>
            <div>
              <Button onClick={() => this.props.history.goBack()} color='info' className='btn-labeled'>
                <span className='btn-label'>
                  <i className='fa fa-arrow-left' />
                </span>{' '}
                Prapa
              </Button>
            </div>
          </div>
        ) : (
          <div className='d-flex justify-content-between'>
            <p style={{ fontSize: 23, color: 'gray' }}>Shto kategorinë</p>
            <div>
              <Button onClick={() => this.props.history.goBack()} color='info' className='btn-labeled'>
                <span className='btn-label'>
                  <i className='fa fa-arrow-left' />
                </span>{' '}
                Prapa
              </Button>
            </div>
          </div>
        )}
        <Row>
          <div className='col-md-12'>
            <form onSubmit={this.onSubmit} name='formCategory'>
              {/* START card */}
              <Card className='card-default'>
                <CardBody>
                  <legend className='mb-4' />
                  <fieldset>
                    <div className='form-group row align-items-center'>
                      <label className='col-md-2 col-form-label'>Emri i kategorisë :</label>
                      <Col md={6}>
                        <Input
                          key='nameCategory'
                          type='text'
                          name='nameCategory'
                          invalid={this.hasError('formCategory', 'nameCategory', 'required')}
                          onChange={this.validateOnChange}
                          value={this.state.formCategory.nameCategory}
                          data-validate='["required"]'
                        />
                        <span className='invalid-feedback'>Fusha është e nevojshme</span>
                      </Col>
                    </div>
                  </fieldset>
                  <fieldset>
                    <div className='form-group row align-items-center'>
                      <label className='col-md-2 col-form-label'>Përshkrimi i kategorisë :</label>
                      <Col md={6}>
                        <Input
                          key='descCategory'
                          type='text'
                          name='descCategory'
                          onChange={this.validateOnChange}
                          value={this.state.formCategory.descCategory}
                        />
                        <span className='invalid-feedback'>Fusha është e nevojshme</span>
                      </Col>
                    </div>
                  </fieldset>
                  {/* left part bg */}
                  <fieldset style={{ borderBottom: 'none' }}>
                    <div className='form-group row align-items-center'>
                      <label className='col-md-2 col-form-label'>
                        Zgjedh ngjyrën :<br />
                        (prapavija ikona)
                      </label>
                      <Col md={6}>
                        <InputGroup className='colorpicker-component demo-colorpicker'>
                          <Input
                            key='colorLeft'
                            type='color'
                            name='colorLeft'
                            value={this.state.leftColor}
                            onChange={() => {}}
                            onFocus={this.colorpickerInputHandleClick}
                          />
                          <InputGroupAddon addonType='append'>
                            <InputGroupText>
                              <i className='icon-pin'></i>
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                        {this.state.displayColorPickerInput ? (
                          <div style={popover}>
                            <div style={cover} onClick={this.colorpickerInputHandleClose} />
                            <SketchPicker color={this.state.leftColor} onChange={(color) => this.setState({ leftColor: color.hex })} />
                          </div>
                        ) : null}
                        <span className='invalid-feedback'>Fusha është e nevojshme</span>
                      </Col>
                    </div>
                  </fieldset>
                  {/* right part bg */}
                  <fieldset style={{ borderBottom: 'none' }}>
                    <div className='form-group row align-items-center'>
                      <label className='col-md-2 col-form-label'>
                        Zgjedh ngjyrën :<br />
                        (prapavija teksti)
                      </label>
                      <Col md={6}>
                        <InputGroup className='colorpicker-component demo-colorpicker'>
                          <Input
                            key='colorRight'
                            type='color'
                            name='colorRight'
                            value={this.state.rightColor}
                            onChange={() => {}}
                            onFocus={this.colorpickerInputHandleClickSecond}
                          />
                          <InputGroupAddon addonType='append'>
                            <InputGroupText>
                              <i className='icon-pin'></i>
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                        {this.state.displayColorPickerInput2 ? (
                          <div style={popover}>
                            <div style={cover} onClick={this.colorpickerInputHandleCloseSecond} />
                            <SketchPicker color={this.state.rightColor} onChange={(color) => this.setState({ rightColor: color.hex })} />
                          </div>
                        ) : null}
                        <span className='invalid-feedback'>Fusha është e nevojshme</span>
                      </Col>
                    </div>
                  </fieldset>

                  {/* ikona */}
                  <fieldset style={{ borderBottom: 'none' }}>
                    <div className='form-group row align-items-center'>
                      <label className='col-md-2 col-form-label'>Zgjedh ikonën :</label>
                      <Col md={6}>
                        <FontIconPicker appendTo='body' closeOnSelect={true} {...props} />
                      </Col>
                    </div>
                  </fieldset>

                  <fieldset>
                    <div className='form-group row'>
                      <label className='col-md-2 col-form-label'></label>
                      <Col xl={6} lg={6} md={12}>
                        <div className='card flex-row align-items-center align-items-stretch border-0'>
                          <div
                            className='col-4 d-flex align-items-center justify-content-center rounded-left'
                            style={{
                              backgroundColor: this.state.leftColor,
                              color: '#fff'
                            }}
                          >
                            <div className='text-uppercase text-center'>
                              {' '}
                              <em className={this.state.icon + ' fa-3x'} aria-hidden></em> <br />
                              <div
                                style={{
                                  width: '78px',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis'
                                }}
                              >
                                {this.state.formCategory.nameCategory && this.messageCrop(this.state.formCategory.nameCategory)}
                              </div>
                            </div>
                          </div>
                          <div
                            className='col-8 py-3 rounded-right'
                            style={{
                              backgroundColor: this.state.rightColor,
                              color: '#fff'
                            }}
                          >
                            <div className='text-center'>
                              <div className='h1 m-0 text-bold'> 0 </div>
                              <div className='text-uppercase'> ditë të mbetura </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </div>
                  </fieldset>
                  <fieldset>
                    <div className='form-group row'>
                      <label className='col-md-2 col-form-label'>Llogaritet në ditë pushimi vjetorë?</label>
                      <div className='col-xl-10 col-md-9 col-8 d-flex'>
                        <label className='switch switch-lg'>
                          <input
                            name='is_yearly_leave_deductible'
                            onChange={this.validateOnChange}
                            value={this.state.formCategory.is_yearly_leave_deductible}
                            type='checkbox'
                            checked={this.state.formCategory.is_yearly_leave_deductible}
                          />
                          <span className='invalid-feedback' />
                        </label>
                        <span className='invalid-feedback'>Fusha eshte e nevojshme</span>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset>
                    <div className='form-group row align-items-center'>
                      <label className='col-md-2 col-form-label'>Pushimet e përdoruesve me mundësi kalkulimi si orë pune :</label>
                      <Col md={6}>
                        <Slider
                          defaultValue={this.state.sliderValue}
                          value={this.state.sliderValue}
                          handleStyle={{
                            height: 25,
                            width: 25,
                            marginLeft: -14,
                            marginTop: -10,
                            border: 'solid 2px rgb(75, 231, 234)'
                          }}
                          dotStyle={{
                            backgroundColor: '#5d9cec'
                          }}
                          onChange={(value) => this.setState({ sliderValue: value })}
                        />
                      </Col>
                      <label className='col-md-2 col-form-label' style={{ color: '#3a3f51', fontSize: 16 }}>
                        {this.state.sliderValue} %
                      </label>
                    </div>
                  </fieldset>
                </CardBody>
                {this.state.isEdit ? (
                  <CardFooter className='text-right'>
                    <button style={{ marginRight: 10 }} type='submit' className='btn btn-info'>
                      Ruaj / Modifiko
                    </button>
                    <Button
                      color='danger'
                      onClick={() => {
                        this.deleteCategory(id)
                      }}
                    >
                      Fshij
                    </Button>
                  </CardFooter>
                ) : (
                  <CardFooter className='text-right'>
                    <button type='submit' className='btn btn-info'>
                      Ruaj
                    </button>
                  </CardFooter>
                )}
              </Card>
              {/* END card */}
            </form>
          </div>
        </Row>
      </ContentWrapper>
    )
  }
}

export default EditCategory
