import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import DownloadIcon from '@material-ui/icons/CloudDownload'

export const getOptions = (
  total,
  count,
  setCount,
  languages,
  roles,
  projects,
  offices,
  departments,
  activities,
  workingStatus,
  exportUsers,
  renderFilters,
  setRenderFilters,
  selectedFilters,
  setSelectedFilters,
  showFilters,
  setShowFilters
) => {
  return {
    download: false,
    pagination: true,
    filter: true,
    filterType: 'dropdown',
    responsive: 'scroll',
    viewColumns: true,
    selectableRows: 'none',
    serverSide: true,
    pagination: true,
    count: 20,
    sort: false,
    print: false,
    count: total,
    search: false,
    rowsPerPage: 20,
    page: selectedFilters.page,
    rowsPerPageOptions: [20, 50, 100, 200],
    elevation: 0,
    textLabels: {
      body: {
        noMatch: 'Nuk ka të dhëna'
      },
      pagination: {
        next: 'Faqja tjeter',
        previous: 'Faqja e meparshme',
        rowsPerPage: 'Rreshta për faqe',
        displayRows: 'të'
      },
      toolbar: {
        search: 'Kërko',
        downloadCsv: 'Shkarko në CSV',
        print: 'Printo',
        viewColumns: 'Shiko kolonat',
        filterTable: 'Tabela e filtrimit'
      },
      filter: {
        all: 'TE GJITHA',
        title: 'FILTRIMET',
        reset: 'RIVENDOS'
      },
      viewColumns: {
        title: 'Shfaq kolonat',
        titleAria: 'Trego/fsheh kolonat e tabelave'
      }
    },
    onFilterChange: async (changedColumn, filterList) => {
      setSelectedFilters((prev) => ({ ...prev, page: 0 }))
      switch (changedColumn) {
        case 'Gjuhet':
          setRenderFilters((prev) => ({ ...prev, language: filterList[2] }))
          setSelectedFilters((prev) => ({
            ...prev,
            language: (() => {
              const selected = languages.find((element) => element.code === filterList[2][0])
              return selected ? selected._id : ''
            })()
          }))
          break

        case 'Pozita':
          setRenderFilters((prev) => ({ ...prev, function: filterList[3] }))
          setSelectedFilters((prev) => ({
            ...prev,
            function: (() => {
              const selected = roles.find((element) => element.name === filterList[3][0])
              return selected ? selected._id : ''
            })()
          }))
          break

        case 'Projekti':
          setRenderFilters((prev) => ({ ...prev, project: filterList[4] }))
          setSelectedFilters((prev) => ({
            ...prev,
            project: (() => {
              const selected = projects.find((element) => element.name === filterList[4][0])
              return selected ? selected._id : ''
            })()
          }))
          break

        case 'Lokacioni i punës':
          setRenderFilters((prev) => ({ ...prev, offices: filterList[12] }))
          setSelectedFilters((prev) => ({ ...prev, office: filterList[12][0] || '' }))
          break

        case 'Aktiviteti':
          setRenderFilters((prev) => ({ ...prev, activity: filterList[5] }))
          setSelectedFilters((prev) => ({
            ...prev,
            activity: (() => {
              const selected = activities.find((element) => element.name === filterList[5][0])
              return selected ? selected._id : ''
            })()
          }))
          break

        case 'Statusi i punës':
          setRenderFilters((prev) => ({ ...prev, workingStatus: filterList[6] }))
          setSelectedFilters((prev) => ({
            ...prev,
            workingStatus: (() => {
              const selected = workingStatus.find((element) => element.name === filterList[6][0])
              return selected ? selected._id : ''
            })()
          }))
          break

        case 'Departamenti':
          setRenderFilters((prev) => ({ ...prev, department: filterList[13] }))
          setSelectedFilters((prev) => ({
            ...prev,
            department: (() => {
              const selected = departments
                .filter((department) => filterList[13].includes(department.name))
                .map((department) => department._id)
              return selected.length > 0 ? selected : ''
            })()
          }))
          break
      }
    },

    customToolbar: () => (
      <Tooltip title={'Shkarko CSV'}>
        <IconButton aria-label={'Shkarko CSV'} onClick={() => exportUsers()}>
          <DownloadIcon />
        </IconButton>
      </Tooltip>
    ),
    onTableChange: async (action, tableState) => {
      switch (action) {
        case 'propsUpdate':
          if (tableState.page !== 0) {
            tableState.page = selectedFilters.page
          }
          break
        case 'changePage':
          setSelectedFilters((prev) => ({ ...prev, page: tableState.page }))
          break
        case 'resetFilters':
          setRenderFilters({
            function: '',
            project: '',
            activity: '',
            workingStatus: '',
            department: '',
            language: '',
            offices: ''
          })
          setSelectedFilters({
            page: 0,
            function: '',
            project: '',
            activity: '',
            workingStatus: '',
            department: '',
            language: '',
            office: ''
          })
          break
        case 'changeRowsPerPage':
          setCount(tableState.rowsPerPage)
          setSelectedFilters((prev) => ({ ...prev, page: 0 }))
          break
        case 'columnViewChange':
          setShowFilters((prev) => ({
            ...prev,
            language: tableState.columns[2].display === 'true',
            department: tableState.columns[13].display === 'true',
            activity: tableState.columns[5].display === 'true',
            workingStatus: tableState.columns[6].display === 'true',
            identityID: tableState.columns[7].display === 'true',
            createdDate: tableState.columns[8].display === 'true',
            city: tableState.columns[9].display === 'true',
            manager: tableState.columns[10].display === 'true',
            teamLeader: tableState.columns[11].display === 'true',
            offices: tableState.columns[12].display === 'true'
          }))
          break
      }
    }
  }
}
