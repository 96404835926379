import moment from 'moment'
import { Button } from '@mui/material'

export const buildTableData = (data, showBtn, handleEditClick) => {
  const activeOrDeactiveText = (login) =>
    login ? (
      <span style={{ color: 'red', fontWeight: 'bold' }}>Aktiv</span>
    ) : (
      <span style={{ color: 'green', fontWeight: 'bold' }}>Deaktiv</span>
    )


  return data.map((item) => [
    `${item?.user?.first_name} ${item?.user?.last_name}`,
    item?.user?.email,
    item?.user?.identity_card,
    item?.user?.department?.map((dep) => dep).join(','),
    item?.user?.office_location,
    moment(item?.created_at).format('DD/MM/YYYY'),
    activeOrDeactiveText(item?.partner_login),
    activeOrDeactiveText(item?.intern_login),
    activeOrDeactiveText(item?.badge),
    showBtn && (
      <Button size='small' variant='outlined' onClick={() => handleEditClick(item._id, item.partner_login, item.intern_login, item.badge)}>
        Modifiko
      </Button>
    )
  ])
}
