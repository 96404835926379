import axios from 'axios'
import { saveAs } from 'file-saver'
import { inject } from 'mobx-react'
import moment from 'moment'
import 'moment/locale/sq'
import pubsub from 'pubsub-js'
import { Component, Fragment } from 'react'
import Select from 'react-select'
import { Button, Card, CardBody, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap'
import { getAwsDocuments, getAwsImage } from '../../utils/awsPaths'
import DocumentInputModal from './DocumentInputModal'

@inject('userStore')
class Warnings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dropdownOpen: {},
      userWarnings: [],
      lastYearsUserWarnings: [],
      isModalOpen: false,
      modalData: {},
      modalLoading: false,
      warningsReason: [],
      warningsSelected: [],
      warningTypes: [{ name: 'Vërejtje verbale' }, { name: 'Vërejtje e shkruar' }]
    }
  }

  componentDidMount() {
    this._loadWarnings()
    this._loadWarningsReason()
  }

  async _loadWarningsReason() {
    try {
      const res = await axios.get('/warnings')

      if (res.data?.status === 1) {
        return this.setState({
          warningsReason: res.data.data.map((item) => {
            return { value: item._id, label: item.description }
          })
        })
      } else {
        return pubsub.publish('showNotification', {
          message: 'Gabim gjatë marrjës së të dhënave!',
          type: 'error'
        })
      }
    } catch (error) {
      return pubsub.publish('showNotification', {
        message: 'Ndodhi nje gabim',
        type: 'error'
      })
    }
  }

  async _loadWarnings() {
    try {
      const response = await axios.get(`userwarnings/${this.props.userId}`)
      if (response.data.success) {
        response.data.data.warnings.map((oneWarning) => {
          if (oneWarning.issue_date < moment().subtract(365, 'days').format()) {
            this.setState((prevState) => ({
              lastYearsUserWarnings: [...prevState.lastYearsUserWarnings?.filter((prev) => prev._id != oneWarning._id), oneWarning]
            }))
          } else {
            this.setState((prevState) => ({
              userWarnings: [...prevState.userWarnings?.filter((prev) => prev._id != oneWarning._id), oneWarning]
            }))
          }
        })

        this.setState({
          loading: false
        })
      } else {
        this.setState({
          error: response.data.message,
          loading: false
        })
        pubsub.publish('showNotification', {
          message: response.data.message,
          type: 'error'
        })
      }
    } catch (e) {
      this.setState({
        loading: false
      })
      pubsub.publish('showNotification', {
        message: 'Gabim gjatë marrjës së të dhënave!',
        type: 'error'
      })
    }
  }

  _toggleModal = () => {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen
    }))
  }

  _onModalComplete = (data) => {
    this._download(data.modalType, data.state)
  }

  _download = async (contractType, postData = {}) => {
    var filename = 'none.docx'
    const date = moment(new Date()).format('DD/MM/YYYY')

    if (postData.type === 'Vërejtje verbale') {
      postData.type = 'verbal'
      filename = `Verejtje_verbale_${date}.docx`
    } else {
      postData.type = 'written'
      filename = `Verejtje_me_shkrim_${date}.docx`
    }
    let warnings = this.state.warningsSelected.map((warning) => warning.value)
    this.setState({ modalLoading: true })
    switch (contractType) {
      case 'warning': {
        postData = { ...postData, document: contractType, warnings: warnings }
        break
      }
      default:
        break
    }
    try {
      const res = await axios.post(`userwarnings/${this.props.userId}`, postData)
      if (res.data.success) {
        const response = await axios.post(getAwsDocuments(this.props.userId, 'default'), postData)
        saveAs(getAwsImage(response.data.file), filename)
        res.data.data.map((oneWarning) => {
          if (oneWarning.issue_date < moment().subtract(365, 'days').format()) {
            this.setState((prevState) => ({
              lastYearsUserWarnings: [...prevState.lastYearsUserWarnings?.filter((prev) => prev._id != oneWarning._id), oneWarning]
            }))
          } else {
            this.setState((prevState) => ({
              userWarnings: [...prevState.userWarnings?.filter((prev) => prev._id != oneWarning._id), oneWarning]
            }))
          }
        })
      }

      this.setState({
        modalLoading: false,
        isModalOpen: false,
        warningsSelected: []
      })
    } catch (e) {
      this.setState({
        modalLoading: false
      })
      pubsub.publish('showNotification', {
        message: 'Gabim gjatë shkarkimit të file',
        type: 'error'
      })
    }
  }

  _downloadWarning = () => {
    this.setState({
      isModalOpen: true,
      modalData: {
        modalType: 'warning',
        title: 'Te dhënat mbi vertetimin e verejtjes',
        description: 'Ploteso te dhënat per te gjeneruar dokumentin e verejtjes.',
        fields: [
          {
            type: 'datepicker',
            name: 'issue_date',
            label: 'Data e lëshimit:'
          },
          {
            type: 'select',
            name: 'type',
            label: 'Tipi',
            options: this.state.warningTypes
          }
        ],
        Selected: (
          <>
            <p> Ju lutem zgjedhni vërejtjen/vërejtjet.</p>
            <Select
              isMulti
              options={this.state.warningsReason}
              onChange={(item) =>
                this.setState({
                  warningsSelected: item
                })
              }
              className='select'
              isClearable={true}
              isSearchable={true}
              isDisabled={false}
              isLoading={false}
              isRtl={false}
              closeMenuOnSelect={false}
              isOptionDisabled={() => this.state.warningsSelected.length >= 3}
            />
          </>
        )
      }
    })
  }

  dropdownToggle = (index) => {
    this.setState({
      dropdownOpen: { [index]: !this.state.dropdownOpen[index] }
    })
  }

  deleteWarning = async (warningId) => {
    try {
      const response = await axios.post(`userwarnings/${this.props.userId}/${warningId}`)
      if (response.data.success) {
        this.setState({
          loading: false,
          userWarnings: this.state.userWarnings.filter((note) => {
            return note._id != warningId
          })
        })
      } else {
        this.setState({
          error: response.data.message,
          loading: false
        })
        pubsub.publish('showNotification', {
          message: 'Nuk u fshie vërejtja!',
          type: 'error'
        })
      }
    } catch (e) {
      this.setState({
        loading: false
      })
      pubsub.publish('showNotification', {
        message: 'Nuk u fshie vërejtja!',
        type: 'error'
      })
    }
  }

  render() {
    return (
      <Fragment>
        <DocumentInputModal
          className={this.state.modalLoading ? 'whirl standard' : ''}
          onComplete={this._onModalComplete}
          isOpen={this.state.isModalOpen}
          modalData={this.state.modalData}
          toggleModal={() => this._toggleModal()}
        />
        {[1, 5, 6].includes(this.props.userStore.userLevel) && (
          <Card outline color='secondary' className='mb-3'>
            <CardBody>
              <Row>
                <Col md={12} style={{ marginBottom: 10 }}>
                  <div className='list-group mb0'>
                    <div className='list-group-item bt0'>
                      <Button onClick={() => this._downloadWarning()} size='xs' color='info' className='btn-labeled float-right'>
                        <span className='btn-label'>
                          <i className='fa fa-download' />
                        </span>{' '}
                        Lësho
                      </Button>
                      <em className='fa-fw fas fa-exclamation-triangle mr-2' />
                      Lësho vërejtje të re
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}

        {this.state.userWarnings?.length > 0 ? (
          <div style={{ marginTop: '20px' }}>
            <h4 style={{ marginBottom: '20px' }}>Vërejtjet aktive</h4>
            {this.state.userWarnings.map((warning, index) => {
              return (
                <div key={index} className='media mb-2'>
                  <img className='mr-2 rounded-circle thumb32' src={'/img/blank_profile.png'} />
                  <div className='media-body'>
                    <span>
                      <p className='m-0' style={{ padding: '2px 6px 4px 0px' }}>
                        Lëshuar nga <strong>{`${warning.created_by.first_name} ${warning.created_by.last_name}`}</strong> me datë{' '}
                        {moment(warning.created_date).format('DD/MM/YYYY')} për datën {moment(warning.issue_date).format('DD/MM/YYYY')}
                        {warning.type && (
                          <Button
                            style={{
                              pointerEvents: 'none',
                              color: 'white',
                              backgroundColor: warning.type === 'verbal' ? '#FF902B' : '#5D9CEC'
                            }}
                            size='xs'
                            className='btn-labeled float-right'
                          >
                            {warning.type === 'verbal' ? 'Verbale' : 'Shkruar'}
                          </Button>
                        )}
                      </p>
                      {warning.warnings.map((w, index) => {
                        return (
                          <div key={index} className='list-group mb0'>
                            <div className='list-group-item bt0'>
                              <em className='fa-fw fas fa-exclamation-triangle mr-2' />
                              {w.description}
                            </div>
                          </div>
                        )
                      })}
                    </span>
                  </div>
                  {(warning.created_by._id == this.props.userStore.user_id ||
                    this.props.userStore.userLevel === 1 ||
                    this.props.userStore.userLevel === 5 ||
                    this.props.userStore.userLevel === 6) && (
                    <div className='float-right'>
                      <Dropdown
                        isOpen={this.state.dropdownOpen[index] ? this.state.dropdownOpen[index] : false}
                        toggle={() => {
                          this.dropdownToggle(index)
                        }}
                      >
                        <DropdownToggle className='btn-xs' color='link'>
                          <em className='fa fa-ellipsis-v fa-lg text-muted' />
                        </DropdownToggle>
                        <DropdownMenu className='dropdown-menu-right-forced animated fadeInLeft'>
                          <DropdownItem
                            className='cursor-pointer'
                            onClick={() => {
                              this.deleteWarning(warning._id)
                            }}
                          >
                            <span>Fshij Vërejtjen</span>
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        ) : (
          <h5 style={{ margin: '20px' }}>Përdoruesi nuk ka vërejtje</h5>
        )}
        {this.state.lastYearsUserWarnings?.length > 0 && (
          <div style={{ marginTop: '40px', borderTop: '2px solid #000' }}>
            <h4 style={{ marginTop: '15px', marginBottom: '30px' }}>Vërejtjet nga vitet e kaluara</h4>
            {this.state.lastYearsUserWarnings.map((warning, index) => {
              return (
                <div key={index + 100} className='media mb-2'>
                  <img className='mr-2 rounded-circle thumb32' src={'/img/blank_profile.png'} />
                  <div className='media-body'>
                    <span>
                      <p className='m-0' style={{ padding: '2px 6px 4px 0px' }}>
                        Lëshuar nga <strong>{`${warning.created_by.first_name} ${warning.created_by.last_name}`}</strong> me datë{' '}
                        {moment(warning.created_date).format('DD/MM/YYYY')} për datën {moment(warning.issue_date).format('DD/MM/YYYY')}
                        {warning.type && (
                          <Button
                            style={{
                              pointerEvents: 'none',
                              color: 'white',
                              backgroundColor: warning.type === 'verbal' ? '#FF902B' : '#5D9CEC'
                            }}
                            size='xs'
                            className='btn-labeled float-right'
                          >
                            {warning.type === 'verbal' ? 'Verbale' : 'Shkruar'}
                          </Button>
                        )}
                      </p>
                      {warning.warnings.map((w, index) => {
                        return (
                          <div key={index} className='list-group mb0'>
                            <div className='list-group-item bt0'>
                              <em className='fa-fw fas fa-exclamation-triangle mr-2' />
                              {w.description}
                            </div>
                          </div>
                        )
                      })}
                    </span>
                  </div>
                  {(warning.created_by._id == this.props.userStore.user_id ||
                    this.props.userStore.userLevel === 1 ||
                    this.props.userStore.userLevel === 5 ||
                    this.props.userStore.userLevel === 6) && (
                    <div className='float-right'>
                      <Dropdown
                        isOpen={this.state.dropdownOpen[index + 100] ? this.state.dropdownOpen[index + 100] : false}
                        toggle={() => {
                          this.dropdownToggle(index + 100)
                        }}
                      >
                        <DropdownToggle className='btn-xs' color='link'>
                          <em className='fa fa-ellipsis-v fa-lg text-muted' />
                        </DropdownToggle>
                        <DropdownMenu className='dropdown-menu-right-forced animated fadeInLeft'>
                          <DropdownItem
                            className='cursor-pointer'
                            onClick={() => {
                              this.deleteWarning(warning._id)
                            }}
                          >
                            <span>Fshij Vërejtjen</span>
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        )}
      </Fragment>
    )
  }
}

export default Warnings
