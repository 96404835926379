import { Box, Button, Grid, TextField, LinearProgress, Card, Tooltip, Typography } from '@mui/material'
import axios from 'axios'
import moment from 'moment'
import { useState } from 'react'
import { toast } from 'react-toastify'
import StaffingHistory from '../Staffing_Request_History/Staffing_Request_History'

const StaffingRequestDetails = ({ item, hasAccessToRecruit, completed = false, getStaffingRequests }) => {
  const [showEdit, setShowEdit] = useState('')
  const [inputValue, setInputValue] = useState(1)
  const [editRequest, setEditRequest] = useState(false)
  const [comment, setComment] = useState('')
  const [commentError, setCommentError] = useState(false)

  const updateStaffingRequest = async () => {
    let postData = {}
    if (editRequest) {
      if (comment.length === 0) {
        setCommentError(true)
      }
      postData = {
        requested: +inputValue,
        comment: comment,
        type: 'requested'
      }
    } else {
      postData = {
        recruited: +inputValue,
        type: 'recruited'
      }
    }
    axios
      .patch(`staffingRequest/${showEdit}`, postData)
      .then((res) => {
        if (res.data.success) {
          toast.success('Kërkesa u përditësua me sukses!')
          getStaffingRequests()
          setShowEdit('')
          setInputValue(1)
          setEditRequest(false)
          setComment('')
        } else {
          toast.error(res.data.message)
        }
      })
      .catch((err) => {
        toast.error('Ndodhi një gabim!')
        console.log('error', err)
      })
  }

  const detailsArray = [
    { title: 'Pozicioni:', value: item.position },
    { title: 'Gjuha:', value: item.language.name },
    { title: 'Departamenti:', value: item.department.name },
    { title: 'Lokacioni:', value: item?.location },
    { title: 'Lloji kerkeses:', value: item?.request_type },
    { title: 'Te kerkuar:', value: item.requested },
    { title: 'Rekrutuar:', value: item.recruited },
    { title: 'Data fundit:', value: moment(item.due_date).format('DD/MM/YYYY') },
    { title: 'Data kerkeses:', value: moment(item.created_at).format('DD/MM/YYYY') },
    { title: 'Krijuar nga:', value: `${item.created_by.first_name} ${item.created_by.last_name}` },
    { title: 'Komenti:', value: item?.creationComment }
  ]

  const historyRequested = Array.isArray(item?.requested_history)
    ? item.requested_history.map((item) => [
        { title: 'Ndryshuar:', value: `Nga  ${item.requestedBefore} në ${item.requestedAfter}` },
        { title: 'Ndryshuar më:', value: moment(item.date).format('DD/MM/YYYY HH:mm') },
        { title: 'Ndryshuar nga:', value: `${item.recruiter.first_name} ${item.recruiter.last_name}` },
        { title: 'Komenti:', value: item.comment }
      ])
    : []

  const histortRecruited = Array.isArray(item?.recruitment_history)
    ? item.recruitment_history.map((item) => [
        { title: 'Ndryshuar:', value: `Nga  ${item.recruitedBefore} në ${item.recruitedAfter}` },
        { title: 'Ndryshuar më:', value: moment(item.date).format('DD/MM/YYYY HH:mm') },
        { title: 'Ndryshuar nga:', value: `${item.recruiter.first_name} ${item.recruiter.last_name}` }
      ])
    : []

  const percentage = item.requested !== 0 ? (item.recruited / item.requested) * 100 : 100
  const percentageCompleted = percentage.toFixed(percentage % 1 === 0 ? 0 : 2)

  const updateInputValue = (newValue) => {
    const maxValue = editRequest ? item.recruited - item.requested : item.requested - item.recruited

    if ((editRequest && newValue >= maxValue) || (!editRequest && newValue <= maxValue)) {
      setInputValue(newValue)
    }
  }
  return (
    <Grid item xs={12} md={6} lg={3}>
      <Card
        style={{
          padding: '20px 30px',
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        {detailsArray.map((detail, index) => {
          return (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant='subtitle2' sx={{ fontWeight: 'bold', mr: 1 }}>
                {detail?.title}
              </Typography>

              <Tooltip title={detail.value} placement='top' arrow>
                <Typography variant='body2' noWrap>
                  {detail?.value}
                </Typography>
              </Tooltip>
            </Box>
          )
        })}

        <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px', width: '100%' }}>
          <Box sx={{ width: '100%' }}>
            <LinearProgress variant='determinate' value={parseFloat(percentageCompleted)} />
          </Box>
          <strong>{percentageCompleted}%</strong>
        </Box>
        <Box sx={{ display: 'flex', gap: '10px', width: '100%' }}>
          {!completed &&
            hasAccessToRecruit &&
            (showEdit === '' || showEdit !== item._id ? (
              <>
                <Button
                  variant='outlined'
                  sx={{ alignSelf: 'self-end', marginTop: '10px' }}
                  size='small'
                  onClick={() => {
                    setShowEdit(item._id)
                    setInputValue(1)
                  }}
                >
                  Rekruto
                </Button>
                <Button
                  variant='outlined'
                  sx={{ alignSelf: 'self-end', marginTop: '10px' }}
                  size='small'
                  onClick={() => {
                    setShowEdit(item._id)
                    setInputValue(1)
                    setEditRequest(true)
                  }}
                >
                  Ndrysho
                </Button>
              </>
            ) : (
              <Box sx={{ width: '100%', alignItems: 'center', marginTop: 1 }}>
                <TextField
                  label={editRequest ? 'Shto apo zbrit kërkesen' : 'Shto apo zbrit të rekrutuar'}
                  size='small'
                  variant='outlined'
                  type='number'
                  value={inputValue}
                  fullWidth
                  onChange={(e) => updateInputValue(e.target.value)}
                  InputProps={{
                    inputProps: {
                      min: editRequest ? item.recruited - item.requested : item.recruited * -1,
                      ...(editRequest ? {} : { max: item.requested - item.recruited })
                    }
                  }}
                  sx={{ marginBottom: '15px', fieldset: { border: '1px solid' }, marginRight: '10px' }}
                />
                {editRequest ? (
                  <TextField
                    multiline={true}
                    minRows={2}
                    fullWidth
                    value={comment}
                    onChange={(e) => {
                      setCommentError(e.target.value.length === 0)
                      setComment(e.target.value)
                    }}
                    sx={{
                      boxSizing: 'border-box',
                      fieldset: { border: '1px solid ' },
                      marginRight: '10px',
                      marginBottom: '15px'
                    }}
                    placeholder='Komenti'
                    defaultValue={''}
                    error={commentError}
                    helperText={commentError ? 'Komenti eshte i nevojshem' : ''}
                  />
                ) : null}

                <Box>
                  <Button size='small' variant='contained' onClick={updateStaffingRequest} disabled={inputValue == 0 || commentError}>
                    Ruaj
                  </Button>
                  <Button
                    size='small'
                    variant='contained'
                    onClick={() => {
                      setShowEdit('')
                      setEditRequest(false)
                      setComment('')
                      setCommentError(false)
                    }}
                    color='error'
                    sx={{ marginLeft: '5px' }}
                  >
                    Mbrapa
                  </Button>
                </Box>
              </Box>
            ))}
        </Box>
        <Box sx={{ marginTop: '20px' }}>
          <StaffingHistory history={histortRecruited} title={'Historiku i rekrutimit'} />
          <StaffingHistory history={historyRequested} title={'Historiku i kërkesës'} />
        </Box>
      </Card>
    </Grid>
  )
}

export default StaffingRequestDetails
