import { Button } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import columns from './column'
import options from './options'
import moment from 'moment'
import { Link } from 'react-router-dom'

const MozoOrder_Table = ({ data, queryParams, setQueryParams, total }) => {
  const handleEditClick = () => {}
  const mozodata = data?.map((item) => [
    `${item?.user_id?.first_name} ${item?.user_id?.last_name}`,
    item?.department?.name,
    item?.order_id,
    item?.product_name,
    item?.additional_product,
    moment(item?.created_date).format('DD/MM/YYYY'),
    moment(item?.activation_date).format('DD/MM/YYYY'),
    <Link to={`/editMozoOrder/${item._id}`}>
      <Button
        size='small'
        variant='outlined'
        onClick={() => handleEditClick()}
        sx={{
          padding: '3px 9px !important',
          color: '#1976d2 !important',
          borderRadius: '4px !important',
          border: '1px solid #1976d2 !important',
          transition: 'all .1s ease',
          '&:hover': {
            boxShadow: '2px 2px 2px  #ccc !important'
          }
        }}
      >
        Modifiko
      </Button>
    </Link>
  ])

  return (
    <div>
      <MUIDataTable
        title={'Mobilezone Orders'}
        data={mozodata} // Replace with your actual data
        columns={columns}
        options={options(queryParams, setQueryParams, total)}
      />
    </div>
  )
}

export default MozoOrder_Table
