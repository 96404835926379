import axios from 'axios'
import Swal from 'sweetalert2'

export const exportLeavesCSV = async (thisObject) => {
  try {
    Swal.fire({
      title: 'Ju lutem prisni...',
      text: 'Duke gjeneruar dokumentin',
      didOpen: () => {
        Swal.showLoading()
      }
    })

    const params = {
      ...thisObject?.state?.filters,
      fromDateValue: thisObject?.state?.fromDateValue,
      toDateValue: thisObject?.state?.toDateValue,
      search: thisObject?.state?.searchQuery || undefined,
      userID: thisObject?.state?.selectedUserID || undefined
    }
    const response = await axios.get(`/leaves/exportLeavesByDate`, {
      responseType: 'blob',
      params
    })

    if (response?.data) {
      if (response.headers['content-type'] == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        saveAs(response.data, 'Leaves.csv')
        Swal.close()
        Swal.fire({
          title: 'Te dhenat u shkarkuan me sukses.',
          text: 'Dokumenti eshte gjeneruar me sukses!',
          type: 'success'
        })
      }
    }
  } catch (error) {
    console.log('error', error)
    Swal.fire({
      title: 'Gabim gjatë shkarkimit të dokumentit',
      text: 'Gabim gjatë shkarkimit të dokumentit',
      type: 'warning'
    })
  }
}
