export const calculateContractEnd = (signDate) => {
  const today = new Date()
  const contract = {}
  if (isNaN(signDate) || signDate.toString() === 'Invalid Date') return false

  // Function to add a specified number of years to a date
  function addYearsToDate(date, yearsToAdd) {
    const newDate = new Date(date)
    // Calculate the new year
    newDate.setFullYear(newDate.getFullYear() + yearsToAdd)
    // If the original date is on February 29 of a leap year, and the new year is not a leap year, adjust the day to February 28
    if (
      newDate.getMonth() === 1 &&
      newDate.getDate() === 29 &&
      !((newDate.getFullYear() % 4 === 0 && newDate.getFullYear() % 100 !== 0) || newDate.getFullYear() % 400 === 0)
    ) {
      newDate.setDate(28)
    }
    newDate.setDate(newDate.getDate())
    return newDate
  }

  // First case: signDate equal or before 31/12/2023
  const startOf2024 = new Date('2024-01-01')
  const endOf2024 = new Date('2024-12-31')

  const todayYear = today.getFullYear()
  if (signDate < startOf2024 || (signDate.getMonth() === 0 && signDate.getDay() === 1)) {
    contract.startOfContract = addYearsToDate(startOf2024, todayYear - 2024)
    contract.endOfContract = addYearsToDate(endOf2024, todayYear - 2024)
  }
  // // Second Case: for every contract starting after the start of 2024
  else if (signDate >= startOf2024) {
    const signDateThisYear = addYearsToDate(signDate, todayYear - signDate.getFullYear())
    if (signDateThisYear > today) {
      contract.startOfContract = addYearsToDate(signDate, todayYear - 2024 - 1)
      contract.endOfContract = addYearsToDate(signDate, todayYear - 2024)
    } else {
      contract.startOfContract = addYearsToDate(signDate, todayYear - 2024)
      contract.endOfContract = addYearsToDate(signDate, todayYear - 2024 + 1)
    }
  }
  return contract.endOfContract
}
