import Header from './Header'
import Sidebar from './Sidebar'
import HikoFestQuestion from '../Dashboard/HikoFestQuestion'

const Base = (props) => {
  const year = new Date().getFullYear()
  const shouldShowHikoFestQuestion = () => {
    const today = new Date()
    const endDate = new Date('2024-09-07')
    return today <= endDate
  }

  return (
    <div className='wrapper'>
      <Header />
      <Sidebar />
      <section className='section-container'>{props.children}</section>
      {shouldShowHikoFestQuestion() && <HikoFestQuestion />}
      <footer className='footer-container'>
        <span>&copy; {year} - Speeex</span>
      </footer>
    </div>
  )
}

export default Base
