import React, { useState, useEffect } from 'react'
import axios from 'axios'
import pubsub from 'pubsub-js'
import { Button, Paper, Typography, FormControlLabel, FormLabel, Radio, RadioGroup, Modal, TextField } from '@material-ui/core'
import { questions } from './interviewQuestion'
import speeex_logo_modal from '../../../assets/images/logon.svg'

const ResignIntervieModal = () => {
  const [open, setOpen] = useState(false)
  const [selectedAnswers, setSelectedAnswers] = useState(new Array(10).fill(''))
  const [selectedAnswersError, setSelectedAnswersError] = useState(new Array(10).fill(false))
  const [inputAnswers, setInputAnswers] = useState(new Array(6).fill(''))

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    const updatedSelectedAnswersError = [...selectedAnswersError]
    selectedAnswers.forEach((answer, key) => {
      updatedSelectedAnswersError[key] = answer == '' ? true : false
    })
    setSelectedAnswersError(updatedSelectedAnswersError)
    let body
    body = {
      q0: selectedAnswers[0],
      q1: selectedAnswers[1],
      q2: selectedAnswers[2],
      q3: selectedAnswers[3],
      q4: selectedAnswers[4],
      q5: selectedAnswers[5],
      q6: selectedAnswers[6],
      q7: selectedAnswers[7],
      q8: selectedAnswers[8],
      q9: selectedAnswers[9],
      q10: inputAnswers[0],
      q11: inputAnswers[1],
      q12: inputAnswers[2],
      q13: inputAnswers[3],
      q14: inputAnswers[4],
      q15: inputAnswers[5]
    }
    axios
      .post('resignInterview', body)
      .then((res) => {
        if (res.data.status === 'success') {
          pubsub.publish('showNotification', {
            message: 'Të dhënat janë ruajtur!',
            type: 'success'
          })
          setOpen(false)
          setSelectedAnswers(new Array(10).fill(''))
          setInputAnswers(new Array(6).fill(''))
        } else {
          pubsub.publish('showNotification', {
            message: res.data.message,
            type: 'error'
          })
        }
      })
      .catch((err) => {
        console.log('error', err)
      })
  }
  const handleRadioChange = (event, index) => {
    const newSelectedAnswers = [...selectedAnswers]
    newSelectedAnswers[index] = event.target.value
    setSelectedAnswers(newSelectedAnswers)

    const newSelectedAnswersError = [...selectedAnswersError]
    newSelectedAnswersError[index] = event.target.value === '' ? true : false
    setSelectedAnswersError(newSelectedAnswersError)
  }

  const handleInputChange = (event, index) => {
    const newInputAnswers = [...inputAnswers]
    newInputAnswers[index] = event.target.value
    setInputAnswers(newInputAnswers)
  }
  const modalStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignItems: 'flex-start',
    marginTop: '20px',
    overflow: 'scroll'
  }

  const [width, setWidth] = useState(window.innerWidth)
  const paperStyle = {
    width: `${width > 900 ? '50%' : '95%'}` // You can adjust the width as needed
  }

  // Function to check and log the width
  function measureWidth() {
    if (width > 900) {
    } else {
    }
  }

  useEffect(() => {
    // Function to handle window resize and update width
    function handleResize() {
      setWidth(window.innerWidth)
    }

    // Add event listener for window resize
    window.addEventListener('resize', handleResize)

    // Call measureWidth on component mount
    measureWidth()

    // Cleanup function to remove event listener
    return () => window.removeEventListener('resize', handleResize)
  }, [width])

  return (
    <div>
      <Button
        variant='contained'
        size='small'
        color='secondary'
        style={{ background: '#5d9cec', textTransform: 'none' }}
        onClick={handleOpen}
      >
        Intervista e daljes
      </Button>
      <Modal open={open} onClose={handleClose} style={modalStyle}>
        <Paper style={paperStyle}>
          <div id='allresign'>
            <div className='firstImage'>
              <img src={speeex_logo_modal} alt='Your SVG' style={{ width: '100%', height: '230px', objectFit: 'contain' }}></img>
            </div>
            <div className='alltext' style={{ padding: '20px', marginTop: '90px' }}>
              <Typography variant='subtitle1' align='center'>
                Intervista e daljes
              </Typography>
              <Typography variant='subtitle1' align='center' style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '40px' }}>
                Informata mbi pyetësorin
              </Typography>
              <Typography variant='subtitle2' style={{ marginTop: '10px' }} gutterBottom>
                Ju lutemi na ndihmoni ta bëjmë SPEEEX një vend më të mirë për të punuar duke plotësuar pyetësorin e mëposhtëm. Përgjigjet
                tuaja të sinqerta janë shumë të rëndësishme për ne. Informacionet nga pyetësori do të shqyrtohen në mënyrë konfidenciale nga
                Burimet Njerëzore dhe nuk do të u komunikohen menaxhmentit. Përgjigjet tuaja nuk do të ndikojnë në referencat tona ndaj juve
                ose perspektivën për ripunësim. Pyetësori është i hartuar në dy lloje të pyetjeve: pyetje të mbyllura, në të cilat ju keni
                mundësi të zgjedhni opsionet e paraqitura dhe pyetje te hapura, në të cilat duhet të shkruani mendimin e juaj rreth pyetjes
                së parashtruar. Në anën e djathtë të secilës pyetje të mbyllur ju duhet të zgjedhni se sa ka luajtur rol në vendimin tuaj
                për tu larguar nga kompania.
              </Typography>
              <form autoComplete='off' onSubmit={handleSubmit}>
                <div className='mt-5'>
                  {questions
                    .filter((question) => question.type === 'radio')
                    .map((question, key) => (
                      <div className='mb-4 titles'>
                        <FormLabel component='legend' style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px' }}>
                          {question.name}
                        </FormLabel>
                        <RadioGroup
                          className='buttons'
                          aria-label={question.name}
                          name={question.inputName}
                          value={selectedAnswers[key]}
                          onChange={(event) => handleRadioChange(event, key)}
                          row
                        >
                          {question.options.map((option) => (
                            <>
                              <FormControlLabel value={option.value} control={<Radio />} label={option.text} />
                            </>
                          ))}
                        </RadioGroup>
                        {selectedAnswersError[key] && <div style={{ color: 'red', marginTop: '-10px' }}>Please select an option.</div>}
                      </div>
                    ))}
                  {questions
                    .filter((question) => question.type === 'text')
                    .map((question, key) => (
                      <>
                        <Typography
                          variant='subtitle1'
                          style={{ fontWeight: 'bold', marginTop: '20px', color: 'rgba(0, 0, 0, 0.54)', fontSize: '18px' }}
                        >
                          {question.name}
                        </Typography>
                        <TextField
                          className='inputs'
                          label={question.name}
                          variant='outlined'
                          value={inputAnswers[key]}
                          onChange={(event) => handleInputChange(event, key)}
                          style={{ margin: '10px 0' }}
                          fullWidth
                          required={question.required}
                        />
                      </>
                    ))}
                  <div className='d-flex justify-content-end ' style={{ marginTop: '20px' }}>
                    <Button
                      type='submit'
                      variant='contained'
                      style={{ background: 'rgb(102, 187, 106)', marginRight: '20px', color: '#fff' }}
                    >
                      Dërgo
                    </Button>
                    <Button variant='contained' onClick={handleClose} color='danger'>
                      Cancel
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Paper>
      </Modal>
    </div>
  )
}

export default ResignIntervieModal
